import { VacancyRelation, VacancyRelationState } from "../../../../types/recruiter/relations";
import { Vacancy } from "../../../../types/recruiter/vacancy";
import { ColumnProps, Table } from "../Table";
import { CommentEditorInput, RelationStatusButton } from "../..";
import { Box, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { recruiterRoutes } from "../../../../router/paths";
import { MatchScore } from "../../../../types/recruiter/matchScore";
import { ScoreSummaryButton } from "../../ScoreSummaryButton";

type TableDataMap = {
    vacancy: Vacancy;
    relation?: VacancyRelation;
    score?: MatchScore;
};

export function ProfessionalVacanciesTable(props: {
    vacancies: Vacancy[];
    states: VacancyRelationState[];
    scores: MatchScore[];
    relations: VacancyRelation[];
    onRelationChange: (relation: VacancyRelation, newState: VacancyRelationState) => void;
    onCommentChange: (relation: VacancyRelation, comment: string) => void;
}) {
    const nav = useNavigate();
    const data: TableDataMap[] = props.vacancies.map(vacancy => ({
        vacancy,
        relation: props.relations.find(r => r.vacancyId === vacancy._id),
        score: props.scores.find(s => s.vacancyId === vacancy._id)
    }));

    const colDefs: ColumnProps<TableDataMap>[] = [
        {
            width: 270,
            valueGetter: (row) => row.data.relation?.status.index,
            cellRenderer: (data) => <RelationStatusButton states={props.states} onChange={(value) => props.onRelationChange(data.data.relation!, value)} state={data.data.relation.status} />,
            headerName: "Status"
        },
        {
            width: 120,
            field: "score",
            headerName: "Score",
            sort: 'desc',
            valueGetter: (row) => row.data.score !== undefined ? row.data.score.score : 0,
            cellRenderer: (row) => <ScoreSummaryButton matchScore={row.data.score} />
        },
        {
            field: "vacancy.company",
            headerName: "Klant",
        },
        {
            field: "vacancy.title",
            headerName: "Vacature",
            cellRenderer: (row) => <Link onClick={() => nav(recruiterRoutes.vacatures.details(row.data.vacancy._id))}>{row.data.vacancy.title}</Link>,
        },
        {
            field: "relation.comment",
            headerName: "Opmerking",
            cellRenderer: (data) => <Box mt={1}>
                <CommentEditorInput initialValue={data.data.relation?.comment ?? ""} onSubmit={
                    (value) => props.onCommentChange(data.data.relation!, value)
                } />
            </Box>,
            flex: 1
        }
    ];

    return <Table id="ProfVacs" columns={colDefs} data={data} paging />;
}

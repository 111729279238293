import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { MatchScore, MatchScoreStatus } from "../../types/recruiter";
import { Row, RowCentered } from "./Row";
import { CopyAllTwoTone, InfoTwoTone } from "@mui/icons-material";
import { useState } from "react";
import { CardDialog } from "./Dialog";

export const ScoreSummaryButton = ({ matchScore }: { matchScore?: MatchScore }) => {
    const [showDialog, setShowDialog] = useState(false);

    if (!matchScore) return <Typography sx={{ mt: 1.5 }} variant="body2" color="textSecondary">Niet berekend</Typography>

    if (matchScore.status === MatchScoreStatus.Pending)
        return <CircularProgress sx={{ ml: 2 }} size={12} />

    const { score, scoreImprovements, scoreSummary } = matchScore;
    const copyClip = () => {
        navigator.clipboard.writeText(`Samenvatting:\n\n${scoreSummary}\n\nVerbeteringen voor de kandidaat:\n${scoreImprovements}`);
    }

    return <>
        <CardDialog title="Score samenvatting" visible={showDialog} onDismiss={() => setShowDialog(false)}>
            <RowCentered>
                <Button onClick={copyClip} startIcon={<CopyAllTwoTone />}>
                    Kopieer samenvatting
                </Button>
            </RowCentered>
            <Box sx={{ maxWidth: 600 }}>
                <Box sx={{ background: '#eee', p: 2, borderRadius: 1 }}>
                    <RowCentered sx={{ mb: 2 }}>
                        <Typography variant="h6">Samenvatting</Typography>
                    </RowCentered>
                    <Typography variant="body1" color="textSecondary">{scoreSummary}</Typography>
                </Box>
                <Box sx={{ p: 2, borderRadius: 1 }}>
                    <RowCentered sx={{ mb: 2 }}>
                        <Typography variant="h6">Verbeteringen</Typography>
                    </RowCentered>
                    <Typography variant="body1" whiteSpace={"pre-line"} color="textSecondary">{scoreImprovements.trimStart().replaceAll("\n\n", "\n")}</Typography>
                </Box>
            </Box>
        </CardDialog>

        <Row sx={{ mt: .5 }}>
            <Button variant="text" onClick={() => setShowDialog(true)}>
                <Typography sx={{ mr: 1 }} variant="body2" color="textSecondary">{score}%</Typography>
                <InfoTwoTone />
            </Button>
        </Row>
    </>
}
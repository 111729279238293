import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { VacancyRelation, VacancyRelationState } from "../../../../types/recruiter/relations";
import { DotsLoader, ProfessionalVacanciesTable } from "../../../molecules";
import { MatchScore } from "../../../../types/recruiter/matchScore";
import { useCallback, useEffect, useState } from "react";
import api from "../../../../api/recruiter/relations";
import { vacanciesSelector } from "../../../../store/slices/recruiter/vacanciesSlice";
import { relationsSelector, updateRelationComment, updateRelationStatus } from "../../../../store/slices/recruiter/relationsSlice";
import { relationStatesSelector } from "../../../../store/slices/recruiter/relationStatesSlice";
import { useMissingScoresHook } from "../../../hooks/fetchMissingScores";

export function RelatedVacanciesOrganism() {
    const params = useParams();
    const dispatch = useAppDispatch();

    const vacanciesState = useAppSelector(vacanciesSelector);
    const relationsState = useAppSelector(relationsSelector);
    const relationStatesState = useAppSelector(relationStatesSelector);

    const relations = relationsState.relations.filter(x => x.professionalId === params.id);
    const vacancies = vacanciesState.vacancies.filter(vacancy => relations.some(y => y.vacancyId === vacancy._id));
    const fetching = vacanciesState.fetchingList === "fetching" || relationsState.fetchingRelations === "fetching";

    const [scores, setScores] = useState<MatchScore[]>([]);

    const fetchProfessionalScores = useCallback(async () => {
        const _relations = relationsState.relations.filter(x => x.professionalId === params.id);
        if (_relations.length === 0) return;

        try {
            const results = await api.getScores(_relations);
            setScores(results);
        } catch (error) {
            console.error("Error fetching professional scores:", error);
        }
    }, [relationsState.relations, params.id]);

    const fetchMissingScores = useMissingScoresHook(scores, setScores);

    useEffect(() => {
        fetchProfessionalScores()
    }, [fetchProfessionalScores]);

    useEffect(() => {
        fetchMissingScores()
    }, [fetchMissingScores]);

    const onChangeRelation = (relation: VacancyRelation, status: VacancyRelationState) => dispatch(updateRelationStatus({
        id: relation._id,
        status: status
    }));

    const onChangeComment = (relation: VacancyRelation, comment: string) => dispatch(updateRelationComment({
        id: relation._id,
        comment
    }));

    return <>
        {
            fetching && <DotsLoader message={"Vacatures worden opgehaald"} />
        }
        {
            !fetching && <ProfessionalVacanciesTable
                states={relationStatesState.relationStates}
                scores={scores}
                onCommentChange={onChangeComment}
                onRelationChange={onChangeRelation} vacancies={vacancies} relations={relations} />
        }
    </>
}
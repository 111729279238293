import { settingRoutes } from "../../../../router/paths";
import { CompanyGroup, CompanyGroupType } from "../../../../types/company/companyGroup";
import { ContextMenuMore } from "../../ContextMenu";
import { Link } from "../../Link";
import { ColumnProps, Table } from "../Table";

export function CompanyGroupsTable(props: {
    groups: CompanyGroup[];
    onDelete: (group: CompanyGroup) => void;
}) {
    const colDefs: ColumnProps<CompanyGroup>[] = [
        {
            width: 70,
            cellRenderer: (row) => <ContextMenuMore buttons={[
                {
                    label: "Verwijderen",
                    color: "error",
                    onClick: () => props.onDelete(row.data)
                }
            ]} />
        },
        {
            headerName: "Titel",
            flex: 1,
            cellRenderer: (row) => <Link path={settingRoutes.category(row.data._id)}>{row.data.name}</Link>
        },
        {
            headerName: "Soort",
            valueGetter: (row) => row.data.type === CompanyGroupType.Smart ? "Tags" : "Statisch",
            flex: 2
        }
    ];

    return <Table id="CompanyGroupsTable" columns={colDefs} data={props.groups} />
}
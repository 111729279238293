import { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CAO, CompanyFinancial, Compensations, ContractFinancial, defaultRate, Periods, Rate } from "../../../types/contracts";
import { AsyncAutocomplete, CheckboxInput, CompensationInput, ConfirmDialog, ContainedButton, DateInput, FeeReceiverInput, IconLabel, NumericSelection, SimpleRateInput, TextArea, TextInput } from "../../molecules";
import { AddCardTwoTone, AirplaneTicketTwoTone, BarChartTwoTone, BedTwoTone, CalendarMonthTwoTone, CrisisAlertTwoTone, CurrencyExchangeTwoTone, DirectionsBusTwoTone, DirectionsCarTwoTone, ElderlyTwoTone, EmojiPeopleTwoTone, EuroTwoTone, GavelTwoTone, HandshakeTwoTone, HandymanTwoTone, HealthAndSafetyTwoTone, HistoryEduTwoTone, HomeTwoTone, InfoTwoTone, Looks3TwoTone, Looks4TwoTone, SavingsTwoTone } from "@mui/icons-material";
import { primaryLighter } from "../../theme";
import { Row } from "../../molecules/Row";
import { SelectMolecule, TimesheetPeriodPicker } from "../../molecules/MultiSelect";
import { FincialTypes, TaoContractFase, TAOFinancial, TaoFinancialTypes, TaoPaymentTerm } from "../../../types/contracts/ContractFinancial";
import { compensationTypeString } from "../../../utils/compensations";
import { Checkbox } from "../../molecules/inputs/CheckboxInput";

export const ContractFinancialEditForm: FC<{
    financial: ContractFinancial,
    onChange: (value: ContractFinancial) => void,
    caoList: CAO[],
    organizations: { label: string, id: string }[]
}> = ({ onChange, financial, organizations, caoList }) => {
    return <Box>
        <Box display={'flex'} flexWrap={'wrap'}>
            <HirerFinancial financial={financial} onChange={onChange} />
            <Box width={16} />

            {financial.type !== FincialTypes.TAO && <SupplierFinancial financial={financial as CompanyFinancial} onChange={onChange} />}
            {financial.type === FincialTypes.TAO && <TaoFinancial caoList={caoList} financial={financial as TAOFinancial} onChange={onChange} />}
            <Box width={16} />
        </Box>

        <Box display={'flex'} flexWrap={'wrap'}>
            <FinesFinancialForm financial={financial} onChange={onChange} />
            <Box width={16} />

            <CompensationsFinancialForm organizations={organizations} financial={financial} onChange={onChange} />
            <Box width={16} />
        </Box>

        <Box display={'flex'} flexWrap={'wrap'}>
            <FeeReceiversFinancialForm organizations={organizations} financial={financial} onChange={onChange} />
            <Box width={16} />

            <Box flex={1} />
            <Box width={16} />
        </Box>

    </Box>
}


const HirerFinancial: FC<{
    financial: ContractFinancial,
    onChange: (value: ContractFinancial) => void,
}> = ({ onChange, financial }) => {
    return <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: 350, mb: 2 }}>
        <Row>
            <EmojiPeopleTwoTone color="primary" sx={{ mr: 1 }} />
            <Typography variant="overline">Inlener</Typography>
        </Row>

        <Box mb={2} />
        <IconLabel icon={EuroTwoTone} label="Tarief" />
        <SimpleRateInput value={financial.rateCustomer} onChange={(rateCustomer) => onChange({ ...financial, rateCustomer })} />
        <Box mb={2} />

        <NumericSelection
            onChange={(value) => onChange({ ...financial, paymentTermCustomer: value })}
            value={financial.paymentTermCustomer}
            icon={CurrencyExchangeTwoTone}
            values={[7, 14, 30, 60, 90]}
            label="Betaaltermijn"
        />

        <CheckboxInput
            type="double"
            title="Budget"
            sx={{ mt: 2 }}
            checked={financial.budget > 0}
            onEnabledChanged={() => onChange({ ...financial, budget: financial.budget > 0 ? 0 : 1000 })}
            value={financial.budget > 0 ? financial.budget.toString() : ''}
            onChange={(budget: any) => onChange({ ...financial, budget })}
            icon={SavingsTwoTone}
        />

        <TimesheetPeriodPicker
            sx={{ mt: 2, width: '100%' }}
            label="Timesheet Periodiciteit"
            value={financial.timesheetPeriod}
            onChange={(timesheetPeriod) => onChange({ ...financial, timesheetPeriod })}
        />

        <CheckboxInput
            type="double"
            title="Afkoopbedrag incl BTW"
            sx={{ mt: 2 }}
            checked={financial.redemtionAmount > 0}
            onEnabledChanged={() => onChange({ ...financial, redemtionAmount: financial.redemtionAmount > 0 ? 0 : 1000 })}
            value={financial.redemtionAmount > 0 ? financial.redemtionAmount.toString() : ''}
            onChange={(value: any) => onChange({ ...financial, redemtionAmount: value })}
            icon={HandshakeTwoTone}
        />

        <TextArea
            icon={InfoTwoTone}
            sx={{ mt: 2 }}
            inputSx={{ minHeight: 60 }}
            label="Aanvullende bepalingen"
            value={financial.additionalProvisions}
            onChange={(additionalProvisions) => onChange({ ...financial, additionalProvisions })}
        />
    </Box>
}

const SupplierFinancial: FC<{
    financial: CompanyFinancial,
    onChange: (value: CompanyFinancial) => void,
}> = ({ onChange, financial }) => {
    return <Box flex={1} p={2} sx={{ background: primaryLighter, minWidth: 350, mb: 2 }}>
        <Row>
            <HandymanTwoTone color="primary" sx={{ mr: 1 }} />
            <Typography variant="overline">Leverancier (Professional)</Typography>
        </Row>
        <Box mb={2} />
        <IconLabel icon={EuroTwoTone} label="Tarief" />
        <SimpleRateInput value={financial.rateProfessional} onChange={(rateProfessional) => onChange({ ...financial, rateProfessional })} />

        <Box mt={2} />
        <NumericSelection
            onChange={(value) => onChange({ ...financial, paymentTermSupplier: value })}
            value={financial.paymentTermSupplier}
            icon={CurrencyExchangeTwoTone}
            values={[7, 14, 30, 60, 90]}
            label="Betaaltermijn"
        />

        <CheckboxInput
            type="double"
            title="Max. aansprakelijkheid"
            sx={{ mt: 2 }}
            checked={(financial as CompanyFinancial).maximumSum > 0}
            onEnabledChanged={() => onChange({ ...financial, maximumSum: (financial as CompanyFinancial).maximumSum > 0 ? 0 : 1000 } as CompanyFinancial)}
            value={(financial as CompanyFinancial).maximumSum > 0 ? (financial as CompanyFinancial).maximumSum.toString() : ''}
            onChange={(maximumSum: any) => onChange({ ...financial, maximumSum } as CompanyFinancial)}
            icon={CrisisAlertTwoTone}
        />

        <CheckboxInput
            type="double"
            title="Max. dekking verzekering"
            sx={{ mt: 2 }}
            checked={(financial as CompanyFinancial).maximumCoverage > 0}
            onEnabledChanged={() => onChange({ ...financial, maximumCoverage: (financial as CompanyFinancial).maximumCoverage > 0 ? 0 : 1000 } as CompanyFinancial)}
            value={(financial as CompanyFinancial).maximumCoverage > 0 ? (financial as CompanyFinancial).maximumCoverage.toString() : ''}
            onChange={(maximumCoverage: any) => onChange({ ...financial, maximumCoverage } as CompanyFinancial)}
            icon={HealthAndSafetyTwoTone}
        />
    </Box>
}

const TaoFinancial: FC<{
    financial: TAOFinancial,
    onChange: (value: TAOFinancial) => void,
    caoList: CAO[]
}> = ({ onChange, financial, caoList }) => {
    return <Box flex={1} p={2} sx={{ background: primaryLighter, minWidth: 350, mb: 2 }}>
        <Row sx={{ mb: 2 }}>
            <HandymanTwoTone color="primary" sx={{ mr: 1 }} />
            <Typography variant="overline">TAO (Professional)</Typography>
        </Row>

        <Box sx={{ width: '100%' }}>
            <IconLabel label="CAO" icon={HistoryEduTwoTone} />
            <AsyncAutocomplete
                onSelect={(cao) => {
                    console.log(cao);
                    onChange({ ...financial, cao: `${cao?.caoNumber} ${cao?.name}`.trim() })
                }}
                onChange={(value) => {
                    console.log(value);
                    onChange({ ...financial, cao: value })
                }}
                sx={{ flex: 1 }}
                options={caoList}
                key={"CAO"}
                selected={caoList.find(cao => financial.cao === `${cao?.caoNumber} ${cao?.name}`)}
                getTitleFromOption={cao => `${cao?.caoNumber}: ${cao?.name}`}
            />
        </Box>

        <Row sx={{ mt: 2 }}>
            <TextInput
                label="CAO Schaal"
                icon={Looks3TwoTone}
                sx={{ flex: 1 }}
                type="number"
                value={financial.caoScale}
                onChange={(caoScale) => onChange({ ...financial, caoScale })}
            />

            <TextInput
                label="CAO Trede"
                icon={Looks4TwoTone}
                sx={{ flex: 1, pl: 1 }}
                type="number"
                value={financial.caoScaleStep}
                onChange={(caoScaleStep) => onChange({ ...financial, caoScaleStep })}
            />

        </Row>


        <SelectMolecule
            sx={{ mt: 2, width: '100%' }}
            onClick={(taoType) => onChange({ ...financial, taoType })}
            selected={[financial.taoType]}
            items={[
                { label: 'All-In', value: TaoFinancialTypes.AllIn, flex: 1 },
                { label: 'Maandloon', value: TaoFinancialTypes.Montly, flex: 1 }
            ]}
        />

        {
            financial.taoType === TaoFinancialTypes.Montly &&
            <Row sx={{ mt: 2 }}>
                <TextInput
                    sx={{ flex: 1, pr: 1 }}
                    icon={EuroTwoTone}
                    type="double"
                    label="Kostprijs per uur"
                    value={financial.hourlyRate}
                    onChange={(brutoHourlyRate) => onChange({ ...financial, hourlyRate: brutoHourlyRate })}
                />
                <TextInput
                    sx={{ flex: 1 }}
                    icon={EuroTwoTone}
                    type="double"
                    label="Bruto maandsalaris"
                    value={financial.brutoMonthlySalary}
                    onChange={(brutoMonthlySalary) => onChange({ ...financial, brutoMonthlySalary })}
                />
            </Row>
        }
        {
            financial.taoType === TaoFinancialTypes.AllIn && <Row sx={{ mt: 2 }}>
                <TextInput
                    sx={{ flex: 1, pr: 1 }}
                    icon={EuroTwoTone}
                    type="double"
                    label="Bruto uurloon"
                    value={financial.hourlyRate}
                    onChange={(brutoHourlyRate) => onChange({ ...financial, hourlyRate: brutoHourlyRate })}
                />

                <TextInput
                    sx={{ flex: 1 }}
                    icon={EuroTwoTone}
                    type="double"
                    label="AllIn tarief minus verloningskosten"
                    value={financial.allInHourlyRate}
                    onChange={(allInHourlyRate) => onChange({ ...financial, allInHourlyRate })}
                />
            </Row>
        }

        <IconLabel
            sx={{ mt: 2 }}
            icon={CurrencyExchangeTwoTone}
            label="Betaaldag"
        />

        <Box sx={{ mb: 2 }}>
            <SelectMolecule
                sx={{ width: '100%' }}
                onClick={(salaryDateType) => onChange({ ...financial, salaryDate: { ...financial.salaryDate, type: salaryDateType } })}
                selected={[financial.salaryDate.type]}
                items={[{
                    label: 'Specifieke dag na afloop vd maand',
                    value: TaoPaymentTerm.SpecifiedDay,
                    flex: 1
                }, {
                    label: '25e van huidige maand',
                    flex: 1,
                    value: TaoPaymentTerm.TwentyFifthOfMonth,
                }
                ]}
            />

            {financial.salaryDate.type === TaoPaymentTerm.SpecifiedDay && <NumericSelection
                sx={{ mt: 2 }}
                onChange={(day) => onChange({ ...financial, salaryDate: { ...financial.salaryDate, day } })}
                value={financial.salaryDate.day}
                values={[1, 14, 28]}
            />}
        </Box>

        <IconLabel label="Contractfase" icon={BarChartTwoTone} />
        <SelectMolecule
            sx={{ width: '100%' }}
            selected={[financial.contractFase]}
            onClick={(contractFase) => onChange({ ...financial, contractFase })}
            items={[
                { label: 'A', value: TaoContractFase.A, flex: 1 },
                { label: 'B', value: TaoContractFase.B, flex: 1 },
                { label: 'C', value: TaoContractFase.C, flex: 1 }
            ]}
        />

        <TextInput
            sx={{ mt: 2 }}
            icon={EuroTwoTone}
            type="double"
            label="Netto onkostenvergoeding"
            value={financial.netExpenseReimbursment}
            onChange={(netExpenseReimbursment) => onChange({ ...financial, netExpenseReimbursment })}
        />

        <Row sx={{ mt: 2 }}>
            <TextInput
                sx={{ flex: 1, pr: 1 }}
                icon={EuroTwoTone}
                type="double"
                label="Premies werkgever"
                value={financial.premiumsEmployer}
                onChange={(premiumsEmployer) => onChange({ ...financial, premiumsEmployer })}
            />

            <TextInput
                sx={{ flex: 1 }}
                icon={EuroTwoTone}
                type="double"
                label="Premies werknemer"
                value={financial.premiumsEmployee}
                onChange={(premiumsEmployee) => onChange({ ...financial, premiumsEmployee })}
            />
        </Row>

        <Checkbox
            title="Loonheffing toepassen"
            checked={financial.applyPayrollTax}
            onChange={() => onChange({ ...financial, applyPayrollTax: !financial.applyPayrollTax })}
        />

        <Row sx={{ mt: 2 }}>
            <TextInput
                sx={{ flex: 1, pr: 1 }}
                icon={AddCardTwoTone}
                type="double"
                label="Bijdrage overhead"
                value={financial.contributionOverhead}
                onChange={(contributionOverhead) => onChange({ ...financial, contributionOverhead })}
            />

            <TextInput
                sx={{ flex: 1 }}
                icon={HealthAndSafetyTwoTone}
                type="double"
                label="Bijdrage ZW"
                value={financial.contributionZW}
                onChange={(contributionZW) => onChange({ ...financial, contributionZW })}
            />
        </Row>

        <TextInput
            sx={{ mt: 2 }}
            icon={ElderlyTwoTone}
            label="Pensioen"
            value={financial.pension}
            onChange={(pension) => onChange({ ...financial, pension })}
        />

        <IconLabel
            sx={{ mt: 2 }}
            label="13e maand"
            icon={EuroTwoTone}
        />
        <SelectMolecule
            selected={[financial.percentage13thMonth]}
            onClick={(percentage13thMonth) => onChange({ ...financial, percentage13thMonth })}
            items={[
                { label: 'Geen', value: 0, flex: 1 },
                { label: '6,75%', value: 6.75, flex: 1 },
                { label: '8,33%', value: 8.33, flex: 1 },
                { label: '8,00%', value: 8.00, flex: 1 }
            ]}
        />

        <Row sx={{ mt: 2 }}>
            <TextInput
                sx={{ flex: 1, pr: 1 }}
                icon={CalendarMonthTwoTone}
                label="Vakantiedagen"
                type="double"
                value={financial.vacationDays}
                onChange={(vacationDays) => onChange({ ...financial, vacationDays })}
            />


            <TextInput
                sx={{ flex: 1 }}
                icon={BedTwoTone}
                label="ADV/ATV Dagen per jaar"
                type="double"
                value={financial.advDays}
                onChange={(advDays) => onChange({ ...financial, advDays })}
            />
        </Row>

        <Row>
            <Box sx={{ flex: 1 }}>
                <IconLabel
                    sx={{ mt: 2 }}
                    label="Vakantiegeld"
                    icon={AirplaneTicketTwoTone}
                />
                <Checkbox
                    title="Vakantiegeld"
                    checked={financial.hasHolidayPay}
                    onChange={() => onChange({ ...financial, hasHolidayPay: !financial.hasHolidayPay })}
                />
            </Box>

            <Box sx={{ flex: 1 }}>
                <IconLabel
                    sx={{ mt: 2 }}
                    label="Vervoer"
                    icon={DirectionsCarTwoTone}
                />
                <Checkbox
                    title="Bedrijfsauto"
                    checked={financial.companyVehicle}
                    onChange={() => onChange({ ...financial, companyVehicle: !financial.companyVehicle })}
                />
            </Box>

        </Row>
    </Box>
}

const FinesFinancialForm: FC<{ financial: ContractFinancial, onChange: (value: ContractFinancial) => void }> = ({ onChange, financial }) => {
    const onFineEnabledChanged = (title: string, period: Periods) => () => {
        const enabled = financial.fines.some(f => f.name === title);
        if (!enabled)
            onChange({
                ...financial,
                fines: [
                    ...financial.fines,
                    {
                        name: title,
                        period: period,
                        sum: 0
                    }
                ]
            });
        else
            onChange({
                ...financial,
                fines: financial.fines.filter(f => f.name !== title)
            });
    }

    const onFineValueChanged = (title: string) => (value: string) => {
        const fine = financial.fines.find(f => f.name === title);
        if (!fine) return;

        fine.sum = value as any;
        onChange({
            ...financial,
            fines: financial.fines.map(f => f.name === title ? fine : f)
        });
    }

    const getFineValue = (title: string) => {
        const fine = financial.fines.find(f => f.name === title);
        return fine ? fine.sum.toString() : '';
    }

    return <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: 350, mb: 2 }}>
        <Row sx={{ mb: 2 }}>
            <InfoTwoTone color="primary" sx={{ mr: 1 }} />
            <Typography variant="overline">Boetes</Typography>
        </Row>

        <CheckboxInput
            title="Boete 1: Direct opeisbaar"
            type="double"
            checked={financial.fines.some(f => f.name === "Boete 1: Direct opeisbaar")}
            onEnabledChanged={onFineEnabledChanged("Boete 1: Direct opeisbaar", Periods.Once)}
            value={getFineValue("Boete 1: Direct opeisbaar")}
            onChange={onFineValueChanged("Boete 1: Direct opeisbaar")}
            icon={GavelTwoTone}
        />

        <CheckboxInput
            sx={{ mt: 2 }}
            title="Boete 2: Per dag"
            type="double"
            checked={financial.fines.some(f => f.name === "Boete 2: Per dag")}
            onEnabledChanged={onFineEnabledChanged("Boete 2: Per dag", Periods.Day)}
            value={getFineValue("Boete 2: Per dag")}
            onChange={onFineValueChanged("Boete 2: Per dag")}
            icon={HandshakeTwoTone}
        />

        <CheckboxInput
            sx={{ mt: 2 }}
            title="Boete 3: Direct opeisbaar"
            type="double"
            checked={financial.fines.some(f => f.name === "Boete 3: Direct opeisbaar")}
            onEnabledChanged={onFineEnabledChanged("Boete 3: Direct opeisbaar", Periods.Once)}
            value={getFineValue("Boete 3: Direct opeisbaar")}
            onChange={onFineValueChanged("Boete 3: Direct opeisbaar")}
            icon={GavelTwoTone}
        />

        <CheckboxInput
            sx={{ mt: 2 }}
            title="Boete 4: Per dag"
            type="double"
            checked={financial.fines.some(f => f.name === "Boete 4: Per dag")}
            onEnabledChanged={onFineEnabledChanged("Boete 4: Per dag", Periods.Day)}
            value={getFineValue("Boete 4: Per dag")}
            onChange={onFineValueChanged("Boete 4: Per dag")}
            icon={HandshakeTwoTone}
        />

    </Box>
}

const CompensationsFinancialForm: FC<{
    financial: ContractFinancial,
    organizations: { label: string, id: string }[],
    onChange: (value: ContractFinancial) => void
}> = ({ onChange, financial, organizations
}) => {
        const findVergoedingByType = (type: Compensations) => financial.compensations.find(c => c.type === type);
        const handleCompensationEnabledChanged = (type: Compensations) => () => {
            const shouldDisable = findVergoedingByType(type) !== undefined;

            if (shouldDisable) onChange({
                ...financial,
                compensations: financial.compensations.filter(c => c.type !== type)
            });
            else onChange({
                ...financial,
                compensations: [
                    ...financial.compensations,
                    {
                        type: type,
                        purchasePrice: "0.0"
                    }
                ]
            });
        }

        const onPurchaseValueChanged = (type: Compensations) => (value: string) => {
            const compensation = findVergoedingByType(type);
            if (!compensation) return;

            compensation.purchasePrice = value;
            onChange({
                ...financial,
                compensations: financial.compensations.map(c => c.type === type ? compensation : c)
            });
        }

        const onSaleValueChanged = (type: Compensations) => (value: string) => {
            const compensation = findVergoedingByType(type);
            if (!compensation) return;

            compensation.salePrice = value;
            onChange({
                ...financial,
                compensations: financial.compensations.map(c => c.type === type ? compensation : c)
            });
        }

        const handleWithSalePriceChanged = (type: Compensations) => () => {
            const compensation = findVergoedingByType(type);
            if (!compensation) return;

            compensation.salePrice = Boolean(compensation.salePrice) ? undefined : "0.0";
            onChange({
                ...financial,
                compensations: financial.compensations.map(c => c.type === type ? compensation : c)
            });
        }

        return <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: 350, mb: 2 }}>
            <Row sx={{ mb: 2 }}>
                <InfoTwoTone color="primary" sx={{ mr: 1 }} />
                <Typography variant="overline">Vergoedingen</Typography>
            </Row>

            <DateInput
                sx={{ mb: 2 }}
                label="Einddatum overige declaraties"
                icon="default"
                value={new Date(financial.endDateDeclarations)}
                onChange={(value) => onChange({ ...financial, endDateDeclarations: value.getTime() })}
            />

            {
                [Compensations.KM, Compensations.OV, Compensations.THUISWERK].map(c => {
                    const vergoeding = findVergoedingByType(c);
                    const iconMap = {
                        [Compensations.KM]: DirectionsCarTwoTone,
                        [Compensations.OV]: DirectionsBusTwoTone,
                        [Compensations.THUISWERK]: HomeTwoTone
                    }

                    return <CompensationInput
                        key={c}
                        enabled={!!vergoeding}
                        title={compensationTypeString(c)}
                        purchaseValue={vergoeding?.purchasePrice || ''}
                        saleValue={vergoeding?.salePrice || ''}
                        icon={iconMap[c]}
                        withSaleValue={vergoeding?.salePrice !== undefined}
                        onPurchaseValueChanged={onPurchaseValueChanged(c)}
                        onEnabledChanged={handleCompensationEnabledChanged(c)}
                        onWithSalePriceChanged={handleWithSalePriceChanged(c)}
                        onSaleValueChanged={onSaleValueChanged(c)}
                    />
                })
            }
        </Box>
    }

const FeeReceiversFinancialForm: FC<{
    financial: ContractFinancial,
    organizations: { label: string, id: string }[],
    onChange: (value: ContractFinancial) => void
}> = ({ onChange, financial, organizations
}) => {
        const [showAddDialog, setShowAddDialog] = useState(false);

        const onFeeChanged = (rate: Rate) => {
            onChange({
                ...financial,
                contractFees: financial.contractFees.map(f => f.name === rate.name ? rate : f)
            });
        }

        const onDeletFee = (rate: Rate) => {
            onChange({
                ...financial,
                contractFees: financial.contractFees.filter(f => f.name !== rate.name)
            });
        }

        const onAddFeeReceiver = (name?: string) => {
            if (!name) return;

            onChange({
                ...financial,
                contractFees: financial.contractFees.concat({ ...defaultRate(), name })
            });

            setShowAddDialog(false);
        }

        return <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: 350, mb: 2 }}>
            <ConfirmDialog
                onConfirm={onAddFeeReceiver}
                message="Soort Fee"
                title="Nieuwe fee ontvanger"
                open={showAddDialog}
                withTextInput

            />

            <Row sx={{ mb: 2 }}>
                <InfoTwoTone color="primary" sx={{ mr: 1 }} />
                <Typography variant="overline">Fee Ontvangers</Typography>
            </Row>

            {
                financial.contractFees.map(f => {
                    return <FeeReceiverInput
                        onDelete={() => onDeletFee(f)}
                        sx={{ mb: 2 }}
                        key={f.name}
                        title={f.name}
                        options={organizations}
                        value={f}
                        onChange={onFeeChanged}
                    />
                })
            }

            <ContainedButton fullWidth sx={{ mt: 2 }} onClick={() => setShowAddDialog(true)}>
                Extra Fee Ontvanger toevoegen
            </ContainedButton>

        </Box>
    }
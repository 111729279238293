import { useState } from "react";
import Lottie from "react-lottie-player";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AddTwoTone, SearchTwoTone } from "@mui/icons-material";
import animation from "../../../../animations/swipe.json";
import { filterBySearch } from "../../../../filters/vacatureFilters";
import { recruiterRoutes } from "../../../../router/paths";
import { refreshRecruiterModuleData } from "../../../../store/combinedActions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { DotsLoader, TextInput, Toolbar, VacanciesTable } from "../../../molecules";
import { vacanciesSelector } from "../../../../store/slices/recruiter/vacanciesSlice";
import { relationsSelector } from "../../../../store/slices/recruiter/relationsSlice";

export function VacanciesListOrganism() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { vacancies, fetchingList, publishSettings } = useAppSelector(vacanciesSelector);
    const relations = useAppSelector(relationsSelector).relations;
    const [search, setSearch] = useState<string>('');

    return <Box>
        <Toolbar>
            <Button onClick={() => navigate(recruiterRoutes.vacatures.create)} variant="text">
                <AddTwoTone fontSize="small" sx={{ mr: 1 }} />
                <Typography variant="caption">Vacature Toevoegen</Typography>
            </Button>
        </Toolbar>
        {fetchingList === 'fetching' && <DotsLoader message="- Vacatures worden opgehaald -" />}
        {fetchingList === 'completed' && vacancies.length > 0 && <>
            <Box display={'flex'} alignItems={'flex-end'} mb={-1}>
                <Box>
                    <TextInput icon={SearchTwoTone} label="Zoeken" sx={{ my: 2, minWidth: 300 }} value={search} onChange={v => setSearch(v as string)} />
                </Box>
                <Box>
                    <Button onClick={() => refreshRecruiterModuleData(dispatch)} variant="text" sx={{ mb: 2, ml: 1 }}>
                        <Typography variant="caption">Vernieuwen</Typography>
                    </Button>
                </Box>
            </Box>
            <VacanciesTable statusses={publishSettings} vacancies={filterBySearch(vacancies, search)} relations={relations} />
        </>
        }
        {fetchingList === 'completed' && vacancies.length === 0 && <Box display={'flex'} flexDirection={'column'} alignItems='center' width="100%">
            <Box sx={{ maxWidth: 300, mb: -8 }}>
                <Lottie loop play
                    rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                    animationData={animation}
                />
            </Box>
            <Box sx={{ zIndex: 10 }} flexDirection={'column'} display={'flex'}>
                <Typography sx={{ mt: 2, textAlign: 'center' }} variant="overline">- Geen Vacatures -</Typography>
                <Button sx={{ mt: 2 }} onClick={() => navigate(recruiterRoutes.vacatures.create)} variant="text">Vacature Plaatsen</Button>
            </Box>
        </Box>}

    </Box>
}
import { ShareVacancyEmail } from "../../types/recruiter/ShareEmail";
import { PublishSettings, Vacancy } from "../../types/recruiter/vacancy";
import { retrieveToken } from "../../utils/cache";
import { BaseApi } from "../base";
import { apiRoutes } from "../routes";

class VacancyApi extends BaseApi {
    constructor() { super(apiRoutes.recruiter + "/vacancies"); }

    public validateZzpDignity = (vacancy: Vacancy) => this.fetch("validate-zzp", "POST", vacancy).then(r => r.text());
    public improveZzpDignity = (vacancy: Vacancy) => this.fetch("improve-for-zzp", "POST", vacancy).then(r => r.text());

    public improve = (title: string, text: string) => this.fetch("improve", "POST", { title, text }).then(r => r.text());
    public list = () => this.fetchJson<Vacancy[]>();
    public get = (id: string) => this.fetchJson<Vacancy[]>(id);
    public subset = (ids: string[]) => this.fetchJson<Vacancy[]>("subset?ids=" + ids.join("&ids="))
    public create = (vacancy: Vacancy) => this.fetchJson<Vacancy>(undefined, "POST", vacancy);
    public update = (vacancy: Vacancy) => this.fetch(vacancy._id, "PUT", vacancy);
    public delete = (id: string) => this.fetch(id, "DELETE");

    public getPublishSettings = (id: string) => this.fetchJson<PublishSettings[]>(id + "/publish-settings");
    public listPublishSettings = (ids: string[]) => this.fetchJson<PublishSettings[]>("publish-settings", "POST", ids);
    public publish = (id: string) => this.fetchJson<string>(id + "/publish", "POST");
    public unpublish = (id: string) => this.fetch(id + "/unpublish", "POST");

    public shareByEmail = (id: string, email: ShareVacancyEmail) => this.fetch(id + "/share", "POST", email);

    public convertFromPdf = async (file: File): Promise<Vacancy> => {
        const formData = new FormData();
        formData.append("file", file);
        const vacancy: Vacancy = await fetch(apiRoutes.recruiter + "/vacancies/import-pdf", {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + retrieveToken(),
            },
        }).then((res) => res.json());

        if (vacancy.startDate.length === 0)
            vacancy.startDate = new Date(Date.now() + (1000 * 60 * 60 * 24)).toISOString();

        if (vacancy.endDate.length === 0)
            vacancy.endDate = new Date(Date.now() + (1000 * 60 * 60 * 24 * 365)).toISOString();

        if (vacancy.closureDateTime.length === 0)
            vacancy.closureDateTime = new Date(Date.now() + (1000 * 60 * 60 * 24 * 180)).toISOString();

        return vacancy;
    }
}

const api = new VacancyApi();
export default api;
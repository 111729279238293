import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { RequestedDocumentsTable } from '../../molecules/tables/documents/RequestedDocuments';
import { useEffect, useState } from 'react';
import { RequestedDocument } from '../../../types/documents/Documents';

import onboardingApi from '../../../api/documents/requested';

import { previewDocumentInBrowser } from '../../../utils/downloadFile';
import { useAppDispatch } from '../../../store/hooks';
import { showToast } from '../../../store/slices/toastSlice';
import { DocumentState } from '../../../types/documents';

export function CompanyDocumentsOrganism() {
    const { id } = useParams();
    const [items, setItems] = useState<RequestedDocument[]>([]);
    const [fetching, setFetching] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const [itemsInProgress, setItemsInProgess] = useState<string[]>([]);

    const onApprove = async (item: RequestedDocument) => {
        setItemsInProgess([...itemsInProgress, item._id]);
        await onboardingApi.approve(item._id)
            .then(() => {
                setItems(items.map(i => i._id === item._id ? { ...item, state: DocumentState.Completed } : i));
                setItemsInProgess(itemsInProgress.filter(i => i !== item._id));
            })
            .catch((e) => {
                dispatch(showToast({
                    message: e.message,
                    type: 'error'
                }))
                setItemsInProgess(itemsInProgress.filter(i => i !== item._id));
            });
    }

    const onDeny = async (item: RequestedDocument) => {
        setItemsInProgess([...itemsInProgress, item._id]);
        await onboardingApi.reject(item._id)
            .then(() => {
                setItems(items.map(i => i._id === item._id ? { ...item, state: DocumentState.Rejected } : i));
                setItemsInProgess(itemsInProgress.filter(i => i !== item._id));
            })
            .catch((e) => {
                dispatch(showToast({
                    message: e.message,
                    type: 'error'
                }))
                setItemsInProgess(itemsInProgress.filter(i => i !== item._id));
            });
    }

    useEffect(() => {
        if (!id)
            return;

        setFetching(true);

        onboardingApi.listByLinkedTo(id)
            .then(setItems)
            .catch(() => setItems([]))
            .finally(() => setFetching(false));

    }, [id]);

    if (fetching)
        return <CircularProgress />

    const onDownload = async (item: RequestedDocument) => {
        var blob = await onboardingApi.download(item._id);
        previewDocumentInBrowser(blob, item.title);
    };

    return <RequestedDocumentsTable itemsInProgress={itemsInProgress} onApprove={onApprove} onDeny={onDeny} items={items} onDownload={onDownload} />
}

import { ApprovalState, TimeRegistrationState, TimesheetApproversMode } from "../types/contracts";

export const calculateApprovalState = (mode: TimesheetApproversMode, states: ApprovalState[]): TimeRegistrationState => {
    const isRejected = states.some(s => s.state === TimeRegistrationState.Rejected);
    if (isRejected) return TimeRegistrationState.Rejected;

    const isApproved = !isRejected && (((mode === TimesheetApproversMode.One) && states.some(s => s.state === TimeRegistrationState.Approved)) ||
        (states.every(s => s.state === TimeRegistrationState.Approved))
    );
    if (isApproved) return TimeRegistrationState.Approved;

    return TimeRegistrationState.Pending;
}
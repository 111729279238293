export enum MatchScoreStatus {
    Pending = 1,
    Completed = 2
}

export interface MatchScore {
    _id: string;
    status: MatchScoreStatus;
    professionalId: string;
    vacancyId: string;
    score: number;
    scoreSummary: string;
    scoreImprovements: string;
}
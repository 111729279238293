import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../../api/recruiter/professionals';
import { showToast } from '../toastSlice';
import { RootState } from '../..';
import { FetchingState } from '../../../types/fetchingState';
import { Professional } from '../../../types/recruiter';

export interface ProfessionalsState { professionals: Professional[]; fetchingUpdate: FetchingState, fetchingDelete: FetchingState, fetchingList: FetchingState; fetchingCreate: FetchingState }
const intialState: ProfessionalsState = {
    professionals: [],
    fetchingList: "init",
    fetchingCreate: "init",
    fetchingDelete: "init",
    fetchingUpdate: "init"
};

export const listProfessionals = createAsyncThunk(
    'professionals/list',
    async (_, thunkApi) => {
        try {
            const professionals = await api.list();
            return professionals;
        } catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const createProfessional = createAsyncThunk(
    'professionals/create',
    async (v: Professional & { resume?: File }, thunkApi) => {
        try {
            const professional = await api.create(v);
            thunkApi.dispatch(showToast({ message: "Professional is aangemaakt", type: "success" }));

            if (v.resume)
                api
                    .uploadDocument(professional._id, v.resume)
                    .catch(() => { });

            return professional;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const updateProfessional = createAsyncThunk(
    'professionals/update',
    async (v: Professional, thunkApi) => {
        try {
            await api.update(v);
            thunkApi.dispatch(showToast({ message: "Professional is bijgewerkt", type: "success" }));
            return v;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const deleteProfessional = createAsyncThunk(
    'professionals/delete',
    async (id: string, thunkApi) => {
        try {
            await api.delete(id);
            thunkApi.dispatch(showToast({ message: "Professional is verwijderd", type: "success" }));
            return id;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const professionalSlice = createSlice({
    name: 'auth',
    initialState: intialState,
    reducers: {},
    extraReducers: (builder) => {

        // List
        builder
            .addCase(listProfessionals.pending, (state) => {
                state.fetchingList = "fetching";
            })
            .addCase(listProfessionals.rejected, (state) => {
                state.fetchingList = "error";
            })
            .addCase(listProfessionals.fulfilled, (state, action: PayloadAction<Professional[]>) => {
                state.fetchingList = "completed";
                state.professionals = action.payload;
            })

        // Create
        builder.addCase(createProfessional.pending, (state) => {
            state.fetchingCreate = "fetching";
        })
            .addCase(createProfessional.rejected, (state) => {
                state.fetchingCreate = "error";
            })
            .addCase(createProfessional.fulfilled, (state, action: PayloadAction<Professional>) => {
                state.fetchingCreate = "completed";
                state.professionals = [action.payload, ...state.professionals];
            })

        // Update
        builder
            .addCase(updateProfessional.pending, (state) => { state.fetchingUpdate = "fetching"; })
            .addCase(updateProfessional.rejected, (state) => { state.fetchingUpdate = "error"; })
            .addCase(updateProfessional.fulfilled, (state, action: PayloadAction<Professional>) => {
                state.fetchingUpdate = "completed";
                state.professionals = state.professionals.map(p => p._id === action.payload._id ? action.payload : p);
            })

        //Delete
        builder
            .addCase(deleteProfessional.pending, (state) => { state.fetchingDelete = "fetching"; })
            .addCase(deleteProfessional.rejected, (state) => { state.fetchingDelete = "error"; })
            .addCase(deleteProfessional.fulfilled, (state, action: PayloadAction<string>) => {
                state.professionals = state.professionals.filter(p => p._id !== action.payload);
                state.fetchingDelete = "completed";
            })
    }
})

export const professionalsSelector = (state: RootState) => state.recruiter.professionals;
export default professionalSlice.reducer
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { BusinessTwoTone, SearchTwoTone } from "@mui/icons-material";
import { filterBySearch } from "../../../filters/companiesFilter";
import { organizationRoutes } from "../../../router/paths";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { DotsLoader, TextInput, Toolbar } from "../../molecules";
import { companiesSelector } from "../../../store/slices/companiesSlice";
import { CompaniesTable } from "../../molecules/tables/company/Companies";
import { hydrateOrgModule } from "../../../store/combinedActions";
import { SendInviteOrganism } from "../persons";
import { StageProgress } from "../../../types/documents/Stages";
import documentsApi from "../../../api/documents/requested";

export function CompaniesListOrganism() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { companies, fetchingList } = useAppSelector(companiesSelector);
    const [search, setSearch] = useState<string>('');
    const [stages, setStages] = useState<StageProgress[]>([]);

    const filteredCompanies = filterBySearch(companies, search);

    useEffect(() => {
        if (companies.length === 0)
            return;

        documentsApi
            .getStages(companies.map(c => c._id))
            .then(setStages);
    }, [companies]);

    return <Box>
        <Toolbar>
            <SendInviteOrganism />
            <Button onClick={() => navigate(organizationRoutes.create)} variant="text">
                <BusinessTwoTone sx={{ mr: 1 }} fontSize='small' />
                <Typography variant="caption">Bedrijf Toevoegen</Typography>
            </Button>
        </Toolbar>
        {fetchingList === 'fetching' && <DotsLoader message="- Bedrijven worden opgehaald -" />}
        {fetchingList === 'completed' && <>
            <Box display={'flex'} alignItems={'flex-end'}>
                <Box>
                    <TextInput icon={SearchTwoTone} label="Zoeken" sx={{ my: 2, minWidth: 300 }} value={search} onChange={v => setSearch(v as string)} />
                </Box>
                <Box>
                    <Button onClick={() => hydrateOrgModule(dispatch)} variant="text" sx={{ mb: 2, ml: 1 }}>
                        <Typography variant="caption" >Vernieuwen</Typography>
                    </Button>
                </Box>
            </Box>
            <CompaniesTable stages={stages} companies={filteredCompanies} />
        </>
        }
    </Box>
}
import { useState, useMemo } from "react";
import {
    ContractTimeRegistrations,
    Periods,
    TimeRegistration,
    TimeRegistrationState,
} from "../../../../types/contracts";
import {
    getMonthFromWeek,
    getWeekNumber,
    monthToFriendlyString,
} from "../../../../utils/dateUtils";
import { ApprovalStateRow } from "../../StatusLabels";
import { ColumnProps, Table } from "../Table";
import { Typography } from "@mui/material";
import { SelectMolecule } from "../../MultiSelect";

import moment from "moment";
import { calculateApprovalState } from "../../../../utils/timesheets";

interface RowData {
    _id: string;
    dateTime: number;
    createdAt: number;
    amount: number;

    rejectedRegistrations: TimeRegistration[];
    approvedRegistrations: TimeRegistration[];
    pendingRegistrations: TimeRegistration[];

    week?: number;
    month?: number;
    year?: number;
}

export function ReviewedTimeRegistrationsTable(props: {
    details: ContractTimeRegistrations;
}) {
    const [viewMode, setViewMode] = useState<Periods>(Periods.Week);

    const groupData = useMemo(() => {
        const keyGetter = (cur: TimeRegistration): number => {
            switch (viewMode) {
                case Periods.Day:
                    return cur.dateTime;
                case Periods.Week:
                    return getWeekNumber(cur.dateTime);
                case Periods.Month:
                    return getMonthFromWeek(getWeekNumber(cur.dateTime));
                default:
                    return cur.dateTime;
            }
        };

        const grouped = props.details.timeRegistrations.reduce(
            (acc, cur) => {
                const key = keyGetter(cur);
                if (!acc[key]) {
                    acc[key] = {
                        _id: cur._id,
                        dateTime: cur.dateTime,
                        createdAt: cur.createdAt,
                        amount: 0,
                        approvedRegistrations: [],
                        rejectedRegistrations: [],
                        pendingRegistrations: [],

                        year: new Date(cur.dateTime).getFullYear()
                    };
                    if (viewMode !== Periods.Month) {
                        acc[key].week = getWeekNumber(cur.dateTime);
                    }
                    if (viewMode !== Periods.Day) {
                        acc[key].month = getMonthFromWeek(getWeekNumber(cur.dateTime));
                    }
                }

                acc[key].amount += cur.amount;

                const state = calculateApprovalState(props.details.mode, cur.approvalStates || []);
                switch (state) {
                    case TimeRegistrationState.Approved:
                        acc[key].approvedRegistrations.push(cur);
                        break;
                    case TimeRegistrationState.Rejected:
                        acc[key].rejectedRegistrations.push(cur);
                        break;
                    default:
                        acc[key].pendingRegistrations.push(cur);
                        break;
                }

                return acc;
            },
            {} as { [key: number]: RowData }
        );

        return Object.values(grouped);
    }, [viewMode, props.details.timeRegistrations, props.details.mode]);

    const columns = useMemo(() => {
        const commonColumns: ColumnProps<RowData>[] = [
            {
                headerName: "Status",
                width: viewMode === Periods.Day ? 130 : 250,
                cellRenderer: (row) => <ApprovalStateRow
                    approvedRegistrations={row.data.approvedRegistrations}
                    mode={props.details.mode}
                    pendingRegistrations={row.data.pendingRegistrations}
                    rejectedRegistrations={row.data.rejectedRegistrations}
                    viewMode={viewMode}
                />
            },
            {
                width: 80,
                field: "amount",
                headerName: "Uren",
                valueGetter: (params) => params.data.amount,
            },
        ];

        let periodSpecificColumns: ColumnProps<RowData>[];

        switch (viewMode) {
            case Periods.Day:
                periodSpecificColumns = [
                    {
                        headerName: "Week",
                        width: 90,
                        valueGetter: (params) => params.data.week,
                    },
                    {
                        headerName: "Gewerkt op",
                        sort: "asc",
                        width: 170,
                        valueGetter: (params) => params.data.dateTime,
                        cellRenderer: (row) =>
                            moment(new Date(row.data.dateTime)).format("ddd DD MMM YYYY"),
                    },
                    {
                        field: "createdAt",
                        headerName: "Geregistreerd op",
                        width: 170,
                        valueGetter: (params) => params.data.createdAt,
                        cellRenderer: (row) =>
                            moment(new Date(row.data.createdAt)).format("DD MMM YYYY"),
                    },
                ];
                break;
            case Periods.Week:
                periodSpecificColumns = [{
                    headerName: "Jaar",
                    width: 90,
                    valueGetter: (params) => params.data.year,
                },
                {
                    headerName: "Week",
                    width: 90,
                    valueGetter: (params) => params.data.week,
                },
                {
                    headerName: "Maand",
                    width: 150,
                    valueGetter: (params) => params.data.month,
                    cellRenderer: (params) =>
                        monthToFriendlyString(params.data.month!),
                },
                ];
                break;
            case Periods.Month:
                periodSpecificColumns = [{
                    headerName: "Jaar",
                    width: 90,
                    valueGetter: (params) => params.data.year,
                },
                {
                    headerName: "Maand",
                    width: 150,
                    valueGetter: (params) => params.data.month,
                    cellRenderer: (params) =>
                        monthToFriendlyString(params.data.month!),
                },
                ];
                break;
            default:
                periodSpecificColumns = [];
        }

        const rejectedReasonColumn: ColumnProps<RowData> = {
            field: "rejectedReason",
            headerName: "Reden afwijzing",
            valueGetter: (params) => params.data.rejectedRegistrations
                .map(x => x.approvalStates.filter(x => x.state === TimeRegistrationState.Rejected)[0].rejectedReason)
                .filter((value, index, self) => self.indexOf(value) === index)
                .join(", ")
        };

        return [...periodSpecificColumns, ...commonColumns, rejectedReasonColumn];
    }, [viewMode, props.details.mode]);

    return (
        <>
            <Typography variant="caption" color={'primary'} sx={{ mt: 2, mr: 1 }}>Weergave: </Typography>
            <SelectMolecule
                outlined
                selected={[viewMode]}
                onClick={setViewMode}
                items={[
                    { label: "Dag", value: Periods.Day },
                    { label: "Week", value: Periods.Week },
                    { label: "Maand", value: Periods.Month },
                ]}
            />
            <Table
                id="ProfessionalApprovedRegistrationsTable"
                hideFilters
                columns={columns}
                data={groupData}
                paging
            />
        </>
    );
}
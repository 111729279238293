export const getScoreMappings = (vacancyIds: string[], professionalIds: string[]): {
    vacancyId: string;
    professionalId: string;
}[] => {

    const mappings: { vacancyId: string, professionalId: string }[] = [];

    vacancyIds.forEach(vacancyId => {
        professionalIds.forEach(professionalId => {
            mappings.push({ vacancyId, professionalId });
        });
    });

    return mappings;
}
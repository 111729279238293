
import { ColumnProps, Table } from "../Table";
import { BankAccount, BankAccountType } from "../../../../types/company";
import { ContextMenuMore } from "../../ContextMenu";
import { useRef, useState } from "react";
import { ConfirmDialog } from "../../ConfirmDialog";

export function BankAccountsTable({
    data, onRemove
}: {
    data: BankAccount[];
    onRemove: (id: string) => void;
}) {
    const [showConfirm, setShowConfirm] = useState(false);
    const selectedBankAccount = useRef<BankAccount | null>(null);

    const columns: ColumnProps<BankAccount>[] = [
        {
            width: 70,
            cellRenderer: (row) => <ContextMenuMore buttons={[
                {
                    label: "Verwijderen",
                    color: "error",
                    onClick: () => {
                        selectedBankAccount.current = row.data;
                        setShowConfirm(true);
                    }
                }
            ]} />
        },
        {
            headerName: "iBAN",
            valueGetter: (params) => params.data.iban
        },
        {
            headerName: "BIC",
            valueGetter: (params) => params.data.bic
        },
        {
            headerName: "Type",
            valueGetter: (params) => params.data.type === BankAccountType.Company ? "Bedrijfsrekening" : "G-rekening"
        },
        {
            headerName: "Verdeling",
            valueGetter: (params) => params.data.type === BankAccountType.Company ? "" : params.data.division
        }
    ]

    return <>
        <ConfirmDialog
            message={`Weet je zeker dat je ${selectedBankAccount.current?.iban} wilt verwijderen?`}
            open={showConfirm}
            onCancel={() => setShowConfirm(false)}
            title={`Bankrekening verwijderen`}
            onConfirm={() => {
                setShowConfirm(false);
                onRemove(selectedBankAccount.current!._id);
            }}
        />
        <Table id="BankAccountsTable" data={data} columns={columns} />
    </>
}
import { useCallback } from "react";
import { MatchScore, MatchScoreStatus } from "../../types/recruiter";
import { delay } from "../../utils/delay";
import api from "../../api/recruiter/relations";

export const useMissingScoresHook = (scores: MatchScore[], onChange: (scores: MatchScore[]) => void) => useCallback(async () => {
    await delay(3000);
    const pendingScores = scores.filter(x => x.status === MatchScoreStatus.Pending);
    if (pendingScores.length === 0) return;

    try {
        const newScores = await api.getScores(pendingScores);
        const updatedScores = scores.map(score => {
            const newScore = newScores.find(x => x._id === score._id);
            if (newScore) return newScore;
            return score;
        });

        onChange(updatedScores);

    } catch (error) {
        console.error("Error fetching professional scores:", error);
    }
}, [scores, onChange]);

import { Link } from "@mui/material";
import { Professional } from "../../../../types/recruiter/professional";
import { VacancyRelation } from "../../../../types/recruiter/relations";
import { SkillsRenderer } from "./Professionals";
import { ColumnProps, Table } from "../Table";
import { RelationButton } from "../../Buttons";
import { MatchScore } from "../../../../types/recruiter/matchScore";
import { ScoreSummaryButton } from "../../ScoreSummaryButton";
import { RowCentered } from "../../Row";
import { MemoTooltip } from "../../MemoTooltip";
import { professionalPriceColumn } from "./Columns";

type RowData = {
    professional: Professional;
    relation?: VacancyRelation;
    score?: MatchScore;
}

export function ApplyProfessionalTable({
    professionals, relations, processing,
    onApply, onRemove, onProfessionalClick, scores
}: {
    professionals: Professional[];
    scores: MatchScore[];
    relations: VacancyRelation[];
    processing: string[];

    onApply: (professional: Professional) => void;
    onRemove: (relation: VacancyRelation) => void;
    onProfessionalClick: (professional: Professional) => void;
}) {

    const data: RowData[] = professionals.map(professional => ({
        professional,
        relation: relations.find(relation => relation.professionalId === professional._id),
        score: scores.find(score => score.professionalId === professional._id)
    }))

    const columns: ColumnProps<RowData>[] = [
        {
            valueGetter: (params) => params.data.relation ? 1 : 0,
            headerName: "Koppelen",
            width: 200,
            cellRenderer: (row: { data: RowData }) => <RelationButton
                loading={processing.some(x => x === row.data.professional._id)}
                onAdd={() => onApply(row.data.professional)}
                onRemove={() => onRemove(row.data.relation!)}
                relation={row.data.relation !== undefined}
            />
        },
        {
            width: 120,
            field: "score",
            headerName: "Score",
            sort: 'desc',
            valueGetter: (row) => row.data.score !== undefined ? row.data.score.score : 0,
            cellRenderer: (row) => <ScoreSummaryButton matchScore={row.data.score} />
        },
        professionalPriceColumn(row => row.data.professional),
        {
            width: 200,
            headerName: "Kandidaat",
            field: "professional.name",
            valueGetter: (row) => row.data.professional?.name,
            cellRenderer: (row: { data: RowData }) => <RowCentered>
                <Link color={row.data.professional?.isAvailable ? 'primary' : 'error'} onClick={() => onProfessionalClick(row.data.professional)}>{row.data.professional?.name}</Link>
                {
                    row.data.professional?.memo && <MemoTooltip memo={row.data.professional.memo} />
                }
            </RowCentered>
        },
        { headerName: "Functie", field: "professional.title", width: 200 },
        { headerName: "Plaats", field: "professional.city", width: 150 },
        { headerName: "Vaardigheden", field: "professional.skills", cellRenderer: SkillsRenderer, flex: 1 },
    ]

    return <Table id="ApplyProf" data={data} columns={columns} paging />
}
import { Professional } from "../types/recruiter/professional";
import { containsIgnoreCase } from "../utils/strings";

export const filterBySkills = (professionals: Professional[], skills: string[]): Professional[] => {
    return professionals.filter(professional => professional.skills.some(skill => skills.some(s => containsIgnoreCase(s, skill))));
}

export const filterByFunctie = (professionals: Professional[], title: string): Professional[] => {
    return professionals.filter(professional => containsIgnoreCase(professional.title, title));
}

export const filterByName = (professionals: Professional[], name: string): Professional[] => {
    return professionals.filter(professional => containsIgnoreCase(professional.name, name));
}

export const filterBySearch = (professionals: Professional[], search: string, excludeNotAvailable?: boolean): Professional[] => {
    const terms = search.split(",").map(term => term.trim());

    return professionals
        .filter(professional => {
            if (!professional.isAvailable && excludeNotAvailable)
                return false;

            return terms.every(term => {
                return (
                    containsIgnoreCase(professional.name, term) ||
                    containsIgnoreCase(professional.title, term) ||
                    containsIgnoreCase(professional.city, term) ||
                    containsIgnoreCase(professional.address, term) ||
                    professional.skills.some(skill => containsIgnoreCase(skill, term)) ||
                    professional.experiences.some(experience =>
                        [experience.title, experience.company, experience.location, experience.description]
                            .some(field => containsIgnoreCase(field || "", term))
                    ) ||
                    professional.educations.some(education =>
                        [education.title, education.institution, education.kind]
                            .some(field => containsIgnoreCase(field || "", term))
                    )
                );
            });
        });
}

export const filterByZipCode = (professionals: Professional[], zipCode: string): Professional[] => {
    return professionals.filter(professional => containsIgnoreCase(professional.zipCode.replaceAll(' ', ''), zipCode.replaceAll(' ', '')));
}
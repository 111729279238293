import { StickyNote2TwoTone } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";

export const MemoTooltip = (props: { memo: string }) => <Tooltip
    slotProps={{
        tooltip: {
            sx: {
                maxWidth: 700
            }
        }
    }}
    title={
        <Typography variant="body2" sx={{ whiteSpace: 'pre-line', fontSize: '0.875rem' }}>
            {props.memo}
        </Typography>
    }>
    <StickyNote2TwoTone sx={{ ml: 1 }} fontSize="small" color="warning" />
</Tooltip>
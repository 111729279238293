import { CircularProgress } from "@mui/material";
import { ColumnProps, Table } from "../Table";
import { ApproveDenyButtons, PreviewButton } from "../../Buttons";
import { documentTypeIcon, documentTypeString, documentModuleIcon, documentModuleString } from "../../../../utils/documents";
import { RowCentered } from "../../Row";
import { DocuSignStatusLabel, FileUploadStatusLabel } from "../../StatusLabels";
import { RequestedDocument } from "../../../../types/documents";
import { DocumentState, DocumentType } from "../../../../types/documents/BaseDocument";

export function RequestedDocumentsTable(props: {
    items: RequestedDocument[];
    itemsInProgress: string[];
    onDownload?: (item: RequestedDocument) => void;
    onApprove: (item: RequestedDocument) => void;
    onDeny: (item: RequestedDocument) => void;
}) {

    const colDefs: ColumnProps<RequestedDocument>[] = [
        {
            headerName: "Bekijk",
            width: 150,
            cellRenderer: (row) => <PreviewButton disabled={
                row.data.state === DocumentState.Requested ||
                (row.data.type === DocumentType.DocuSign && row.data.state === DocumentState.Pending) ||
                (row.data.type === DocumentType.DocuSign && row.data.state === DocumentState.Rejected)
            } onClick={() => props.onDownload?.(row.data)} />
        },
        {
            headerName: "Soort",
            width: 170,
            cellRenderer: (row) => {
                const Icon = documentTypeIcon(row.data.type);
                return <RowCentered>
                    <Icon color="primary" sx={{ mr: 2 }} />
                    {documentTypeString(row.data.type)}
                </RowCentered>
            }
        },
        {
            headerName: "Aanleveraar",
            width: 170,
            cellRenderer: (row) => {
                const Icon = documentModuleIcon(row.data.managedBy.module);
                return <RowCentered>
                    <Icon color="primary" sx={{ mr: 2 }} />
                    {documentModuleString(row.data.managedBy.module)}
                </RowCentered>
            }
        },
        {
            field: "title",
            headerName: "Titel"
        },
        {
            field: "description",
            headerName: "Omschrijving"
        },
        {
            headerName: "Status",
            flex: 1,
            cellRenderer: (row) => {
                if (props.itemsInProgress.includes(row.data._id))
                    return <CircularProgress size={20} />

                if (row.data.type === DocumentType.DocuSign)
                    return <DocuSignStatusLabel item={row.data} />

                return row.data.state === DocumentState.Pending
                    ? <ApproveDenyButtons onApprove={() => props.onApprove(row.data)} onDeny={() => props.onDeny(row.data)} />
                    : <FileUploadStatusLabel item={row.data} />
            }
        }
    ];

    return <Table id="DocListItems" columns={colDefs} data={props.items} paging />
}

import { ColumnProps, Table } from "../Table";

export function SmartCategoryMembersTable({
    companies
}: {
    companies: {
        _id: string;
        naam: string;
        genericAddress: string;
        vestigingsNummer: string;
    }[];
}) {
    const columns: ColumnProps<{
        _id: string;
        naam: string;
        genericAddress: string;
        vestigingsNummer: string;
    }>[] = [
            {
                headerName: "Naam",
                valueGetter: (params) => params.data.naam,
                sort: 'desc'
            },
            { headerName: "Vestigingsnummer", field: "vestigingsNummer" },
            { headerName: "Contact", field: "genericAddress" }
        ]

    return <Table id="SmartCategoryMembersTable" data={companies} columns={columns} />
}
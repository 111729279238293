import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { StageProgress } from "../../types/documents/Stages";
import { RequestSignaturesPayload } from "../../types/documents/RequestSignaturesPayload";
import { FileUploadRequest, FormFilloutRequest, RequestedDocument, SignatureRequest } from "../../types/documents";

class DocumentsApi extends BaseApi {
    constructor() { super(apiRoutes.documents + "/documents"); }

    public getStages = (ids: string[]) => this.fetchJson<StageProgress[]>("stages", "POST", ids);

    public requestSignatures = (payload: RequestSignaturesPayload) => this.fetchJson<SignatureRequest>('/request-signatures', 'POST', payload);
    public requestFileUpload = (payload: FileUploadRequest) => this.fetchJson<FileUploadRequest>('/request-file-upload', 'POST', payload);
    public requestFormFillout = (payload: FormFilloutRequest) => this.fetchJson<FormFilloutRequest>('/request-form-fillout', 'POST', payload);

    public listByInviteCode = (env: string, code: string) => this.fetchJson<RequestedDocument[]>(`${env}/invite-code/${code}`);
    public listByLinkedTo = (id: string) => this.fetchJson<RequestedDocument[]>(`?linkedTo=${id}`);

    public reject = (id: string) => this.fetch(`${id}/reject`, 'PUT');
    public approve = (id: string) => this.fetch(`${id}/approve`, 'PUT');
    public download = (id: string) => this.fetchBlob(`${id}/download`);
}

const api = new DocumentsApi();
export default api;
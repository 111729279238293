import { Box, Button, Checkbox, Container, Dialog, DialogContent, Fade, FormControlLabel, FormGroup, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import DepublishedPage from "../../miscellaneous/DepublishedPage";
import { useAppDispatch } from "../../../../store/hooks";
import { showToast } from "../../../../store/slices/toastSlice";
import { FetchingState } from "../../../../types/fetchingState";
import { Professional, emptyProfessional } from "../../../../types/recruiter/professional";
import { Vacancy } from "../../../../types/recruiter/vacancy";
import { retrieveAppliedVacancies } from "../../../../utils/cache";
import { Page, DotsLoader, ProgressBar, VacatureViewer, VacatureAppliance, HeroPage, ContainedButton } from "../../../molecules";
import { fullNameValidation } from "../../../validations";

import publicApi from "../../../../api/recruiter/public";

const animation = require('../../../../animations/hero-stats.json');
const Divider = styled(Box)({ marginTop: '2rem' })

export default function VacancyViewerPage() {
    const params = useParams();
    const dispatch = useAppDispatch();

    const [fetching, setFetching] = useState<FetchingState>('init');
    const [showApplianceForm, setShowApplianceForm] = useState(false);

    const [vacancy, setVacancy] = useState<Vacancy>();
    const [alreadyApplied, setAlreadyApplied] = useState(false);
    const [professionalDetails, setProfessionalDetails] = useState<Professional>(emptyProfessional);

    const [agreedContact, setAgreedContact] = useState(false);
    const [agreedTruth, setAgreedTruth] = useState(false);
    const [fetchingImportPdf, setFetchingImportPdf] = useState(false);

    const { code } = params;

    useEffect(() => {
        if (code && fetching === "init" && !vacancy) {

            const applied = retrieveAppliedVacancies().includes(code);
            setAlreadyApplied(applied);

            setFetching('fetching');
            publicApi.fetchVacature(code)
                .then(vacancy => {
                    setVacancy(vacancy);
                    setProfessionalDetails(p => ({ ...p, title: vacancy.title }));
                    setFetching('completed');
                })
                .catch(_ => setFetching('error'));
        }
    }, [code, fetching, vacancy]);


    const onImportPdf = async (file: File) => {
        setFetchingImportPdf(true);

        try {

            const pdf = await publicApi.convertProfessionalFromFile(file);

            setProfessionalDetails(p => ({
                ...p,
                educations: pdf.educations.length > 0 ? pdf.educations : p.educations,
                experiences: pdf.experiences.length > 0 ? pdf.experiences : p.experiences,
                name: pdf.name || p.name,
                title: pdf.title || p.title,
                email: pdf.email || p.email,
                phone: pdf.phone || p.phone,
                address: pdf.address || p.address,
                city: pdf.city || p.city,
                zipCode: pdf.zipCode || p.zipCode,
                houseNumber: pdf.houseNumber || p.houseNumber,
                gender: pdf.gender || p.gender,
                skills: pdf.skills || p.skills,
                contractingTypes: p.contractingTypes,
                linkedIn: pdf.linkedIn || p.linkedIn
            }));
        }
        catch (e) {

            dispatch(showToast({ message: 'Er is een fout opgetreden bij het importeren van het bestand', type: 'error' }))
        }

        setFetchingImportPdf(false);
    }

    const onApply = async () => {
        setFetching('fetching');
        try {
            await publicApi.apply(code!, professionalDetails);

            setAlreadyApplied(true);

            setShowApplianceForm(false);

            dispatch(showToast({
                message: 'Sollicitatie verzonden!',
                type: 'success'
            }))
        }
        catch (err: any) {
            dispatch(showToast({
                message: 'Fout opgetreden: ' + err.message,
                type: 'error'
            }))
        }
        finally { setFetching('completed') }
    };

    if (!vacancy && fetching === "fetching") return <Page title="Vacature">
        <DotsLoader message="Vacature word geladen." />
    </Page>

    if (!vacancy && fetching === "error")
        return <DepublishedPage />

    const validateRequiredFields = (): boolean => Boolean(professionalDetails.name)
        && Boolean(professionalDetails.title)
        && Boolean(professionalDetails.email)
        && agreedContact
        && agreedTruth
        && fullNameValidation(professionalDetails.name) === undefined;

    return <HeroPage animation={animation} animationMaxWidth={600} animationMaxHeight={400} top>
        {
            fetchingImportPdf && <Dialog open={true}>
                <DialogContent>
                    <DotsLoader message="PDF word geimporteerd" subtitle="Ons AI systeem heeft ongever 20-40 seconden nodig om uw CV uit te lezen." />
                    <ProgressBar duration={20000} />
                </DialogContent>
            </Dialog>
        }
        <Fade in key={showApplianceForm ? '1' : '2'}>
            <Container maxWidth={'md'} sx={{ p: 1, pb: 5 }}>
                <Divider />

                {!showApplianceForm && vacancy && <VacatureViewer vacancy={vacancy} />}
                {showApplianceForm && <VacatureAppliance
                    hideContract
                    onUpload={onImportPdf}
                    onChange={setProfessionalDetails}
                    value={professionalDetails} />}

                <Divider />
                {
                    !showApplianceForm && fetching === 'completed' && <ContainedButton sx={{ mb: 15 }} disabled={alreadyApplied} onClick={() => {
                        setShowApplianceForm(!showApplianceForm);
                        window.scrollTo(0, 0);
                    }} color="primary">
                        {alreadyApplied ? 'Gesolliciteerd' : 'Solliciteer'}
                    </ContainedButton>
                }
                {
                    showApplianceForm &&
                    <>
                        <Divider />
                        <Typography variant="body2" color={'secondary'}>U zult per email en/of telefoon op de hoogte gehouden worden.</Typography>
                        <Divider />

                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={agreedContact} onChange={(_, value) => setAgreedContact(value)} />} label={<Typography variant="body2">Ik geef hierbij toestemming om contact op te nemen</Typography>} />
                            <FormControlLabel control={<Checkbox checked={agreedTruth} onChange={(_, value) => setAgreedTruth(value)} />} label={<Typography variant="body2">Het formulier is naar waarheid ingevuld</Typography>} />
                        </FormGroup>

                        <Divider />

                        <LoadingButton fullWidth loading={fetching === 'fetching'} onClick={onApply} variant="text" color="primary" disabled={!validateRequiredFields()}>
                            Verzenden
                        </LoadingButton>
                        <Divider />
                        <Button sx={{ mb: 15 }} fullWidth onClick={() => setShowApplianceForm(false)} variant="text" color="error">
                            Terug
                        </Button>
                    </>
                }
                <Divider />
            </Container>
        </Fade>
    </HeroPage>
}
import { AccountTreeTwoTone } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { TimesheetApproversMode } from "../../types/contracts";
import { approverModeString } from "../../utils/contractEnumStrings";
import { IconLabel } from "./IconLabel";
import { SelectMolecule } from "./MultiSelect";

export const ApproverModeInput: FC<{
    selected: TimesheetApproversMode,
    onChange?: (value: TimesheetApproversMode) => void,

}> = ({ selected, onChange }) => {
    return <Box>
        <IconLabel icon={AccountTreeTwoTone} label="Goedkeuringsmodus" />
        <SelectMolecule
            selected={[selected]}
            onClick={onChange}
            disabled={!onChange}
            sx={{ width: '100%' }}
            items={[
                TimesheetApproversMode.One,
                TimesheetApproversMode.Parallel,
                TimesheetApproversMode.Sequential
            ].map(mode => ({ value: mode, label: approverModeString(mode), flex: 1 }))}
        />
        {
            selected === TimesheetApproversMode.Sequential &&
            <Typography variant="caption">
                Goedkeuring word gedaan door meerdere personen in de opgegeven volgorde
            </Typography>
        }
        {
            selected === TimesheetApproversMode.Parallel &&
            <Typography variant="caption">
                Goedkeuring word gedaan door meerdere personen tegelijkertijd
            </Typography>
        }
        {
            selected === TimesheetApproversMode.One &&
            <Typography variant="caption">
                Goedkeuring door één persoon is voldoende
            </Typography>
        }
    </Box>
}
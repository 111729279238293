import { ColumnProps, Table } from "../Table";
import { BaseContract } from "../../../../types/contracts";
import { Link } from "../../Link";
import { contractenRoutes, organizationRoutes, personRoutes } from "../../../../router/paths";
import { StageProgress } from "../../../../types/documents/Stages";
import { StageProgressTiles } from "../../StageProgress";
import { Box } from "@mui/material";
import { stageValueGetter } from "../../../../utils/stage";

export function ContractsTable(props: {
    contracts: BaseContract[];
    stages: StageProgress[];
}) {
    const getStageProgress = (contractId: string) => props.stages.find(s => s.id === contractId);

    const colDefs: ColumnProps<BaseContract>[] = [
        {
            headerName: "Voortgang",
            valueGetter: row => stageValueGetter(getStageProgress(row.data._id)),
            cellRenderer: (props) => {
                const stageProgress = getStageProgress(props.data._id);
                return <Box sx={{ mt: 1 }}>
                    <StageProgressTiles stageProgress={stageProgress} />
                </Box>
            },
        },
        {
            headerName: "Contractnummer",
            field: "contractnumber",
            cellRenderer: (props) => <Link path={contractenRoutes.details(props.data.contractnumber)}>
                {props.data.contractnumber}
            </Link>,
        },
        {
            headerName: "Professional",
            cellRenderer: (props) => <Link path={personRoutes.details(props.data.professional._id)}>
                {props.data.professional.name}
            </Link>,
        },
        {
            headerName: "1e Tekenbevoegde Klant",
            width: 300,
            cellRenderer: (props) => <Link path={personRoutes.details(props.data.authorizedSignatoriesHirer[0]._id)}>
                {props.data.authorizedSignatoriesHirer[0].name}
            </Link>,
        },
        {
            headerName: "Leverancier",
            flex: 1,
            cellRenderer: (props) => <Link path={organizationRoutes.details(props.data.supplier._id)}>
                {props.data.supplier.traderName}
            </Link>,
        }
    ];

    return <Table id="contracts-table" slim columns={colDefs} data={props.contracts} paging />;
}


import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import api from "../../../api/organization/company";
import { BankAccount, EmptyBankAccount } from "../../../types/company";
import { CardDialog, ContainedButton, DotsLoader } from "../../molecules";
import { BankAccountsTable } from "../../molecules/tables/company";
import { Box } from "@mui/material";
import { OrganizationBankingForm } from "../../forms/company/CompanyBankingForm";
import { useAppDispatch } from "../../../store/hooks";
import { showToast } from "../../../store/slices/toastSlice";

export const BankAccountsOrganism: FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
    const [fetching, setFetching] = useState(false);
    const [showBankDialog, setShowBankDialog] = useState(false);
    const [newBankAccount, setNewBankAccount] = useState<BankAccount>(new EmptyBankAccount());

    useEffect(() => {
        if (!id)
            return;

        setFetching(true);
        api
            .listBankAccounts(id)
            .then(setBankAccounts)
            .finally(() => setFetching(false));
    }, [id]);

    const onAddBankAccount = () => {
        api
            .addBankAccount(id!, newBankAccount)
            .then(b => {
                setBankAccounts([...bankAccounts, b]);
                setNewBankAccount(new EmptyBankAccount());
                setShowBankDialog(false);
            })
            .catch(e => {
                dispatch(showToast({
                    message: e.message,
                    type: "error"
                }))
            })
    }

    const onDeleteBankAccount = (bankAccountId: string) => {
        api
            .removeBankAccount(id!, bankAccountId)
            .then(() => {
                setBankAccounts(bankAccounts.filter(b => b._id !== bankAccountId));
            })
            .catch(e => {
                dispatch(showToast({
                    message: e.message,
                    type: "error"
                }))
            })
    }

    if (fetching)
        return <DotsLoader message="Bankrekeningen worden opgehaald" />

    return <Box>
        <CardDialog visible={showBankDialog} onDismiss={() => setShowBankDialog(false)} title="Nieuwe Rekening">
            <Box minWidth={400}>
                <OrganizationBankingForm bankAccount={newBankAccount}
                    onChange={setNewBankAccount}
                    hideG={false}
                />
                <ContainedButton fullWidth sx={{ my: 2 }} onClick={onAddBankAccount}>Toevoegen</ContainedButton>
            </Box>
        </CardDialog>

        <Box sx={{ mt: 2 }}>
            <ContainedButton onClick={() => setShowBankDialog(true)}>
                Nieuwe bankrekening toevoegen
            </ContainedButton>
        </Box>

        <BankAccountsTable data={bankAccounts} onRemove={onDeleteBankAccount} />
    </Box>
};
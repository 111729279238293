import { FC } from "react";
import { Stage, StageProgress } from "../../types/documents/Stages";
import { RowCentered } from "./Row";
import { getStageColor, getStageIcon, getStageTooltip } from "../../utils/stage";
import { Skeleton, Tooltip, Typography } from "@mui/material";
import { DocumentState } from "../../types/documents";
import { successColor } from "../theme";

export const StageProgressTiles: FC<{ stageProgress?: StageProgress }> = ({
    stageProgress
}) => {
    if (!stageProgress)
        return <RowCentered>
            <Skeleton variant="rectangular" sx={{ height: 30, width: 40, borderRadius: 1, mr: 1 }} />
            <Skeleton variant="rectangular" sx={{ height: 30, width: 40, borderRadius: 1, mr: 1 }} />
            <Skeleton variant="rectangular" sx={{ height: 30, width: 40, borderRadius: 1, mr: 1 }} />
        </RowCentered>

    if (stageProgress.stages.length === 0 || stageProgress.stages.every(s => s.state === DocumentState.Completed))
        return <RowCentered sx={{ height: 30, background: successColor(0.1), justifyContent: 'center', borderRadius: 1 }}>
            <Typography color={successColor(1)} variant="caption">Compleet</Typography>
        </RowCentered>

    return <RowCentered>{
        stageProgress.stages.filter(x => x.state !== DocumentState.Completed).map((stage, i) => <StageTile key={i} {...stage} />)
    }</RowCentered>
}

const StageTile: FC<Stage> = (stage) => {
    const Icon = getStageIcon(stage);
    return <Tooltip title={getStageTooltip(stage)}>
        <RowCentered sx={{ height: 30, width: 40, background: getStageColor(stage), justifyContent: 'center', borderRadius: 1, mr: 1 }}>
            <Icon fontSize="small" htmlColor={getStageColor(stage, 1)} />
        </RowCentered>
    </Tooltip>
}
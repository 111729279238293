import { useAppSelector } from "../../store/hooks";
import { PlatformUser } from "../../types/user";
import { SideMenuLayout } from "../layouts/SideMenu";
import { DashboardDrawer, MenuNavButton } from "../molecules";
import { Outlet } from "react-router-dom";
import { ToastOrganism } from "./Toaster";
import { SettingsTwoTone } from "@mui/icons-material";

import { settingRoutes } from "../../router/paths";
import { companyModule, contractenModule, dashboardModule, personModule, recruiterModule, selfInvoicesModule, selfProfileModule, selfStatsModule, selfTimesheetsModule } from "../../router/modules";
import { createMenuAuthorizer, createWhitelabelPermissionValidator, getPathPermissions, isProfessionalUser } from "../../utils/permissions";
import { Box } from "@mui/material";

export function SideMenuOrganism() {
    const auth = useAppSelector(state => state.auth);
    const authorizedMenuFilter = createMenuAuthorizer(auth.user);
    const hasWhitelabelPermissions = createWhitelabelPermissionValidator(auth.user)([
        ...getPathPermissions(settingRoutes.owner),
        ...getPathPermissions(settingRoutes.users),
        ...getPathPermissions(settingRoutes.documentLists)
    ]);

    const modules = isProfessionalUser(auth.user)
        ? authorizedMenuFilter([
            selfProfileModule,
            selfInvoicesModule,
            selfTimesheetsModule,
            selfStatsModule
        ])
        : authorizedMenuFilter([
            dashboardModule,
            recruiterModule,
            contractenModule,
            companyModule,
            personModule
        ]);

    return <SideMenuLayout
        sideMenu={<DashboardDrawer
            user={auth.user as PlatformUser}
            modules={modules}
            footer={
                hasWhitelabelPermissions &&
                <MenuNavButton link={settingRoutes.root} icon={<SettingsTwoTone color="primary" />} title={'Beheer'}
                    items={[
                        {
                            title: 'Co-Eigenaar',
                            link: settingRoutes.owner
                        },
                        {
                            title: 'Beheerders',
                            link: settingRoutes.users
                        },
                        {
                            title: 'Email Berichten',
                            link: settingRoutes.emailTemplates
                        },
                        {
                            title: 'Contracten',
                            link: settingRoutes.contracts
                        },
                        {
                            title: 'Documenten',
                            link: settingRoutes.documents
                        },
                    ]}
                />
            }
        />}
    >
        <Box position={'relative'}>
            <ToastOrganism paddingLeft={34.5} />
            <Outlet />
        </Box>
    </SideMenuLayout>
}

import { Box, Checkbox, Typography } from "@mui/material";
import { Permissions } from "../../types/user";
import { Table } from "./tables/Table";
import { RowCentered } from "./Row";

export interface PermissionsDescription {
    module: string,
    description: string,
    readPermission?: Permissions,
    writePermission?: Permissions
}

function PermissionsTableList(props: {
    permissions: PermissionsDescription[],
    selected: Permissions[],
    onChanged: (permissions: Permissions[]) => void
}) {
    //group permissions by module
    const groupedPermissions = props.permissions.reduce((acc, permission) => {
        if (!acc[permission.module]) {
            acc[permission.module] = [];
        }
        acc[permission.module].push(permission);
        return acc;
    }, {} as Record<string, PermissionsDescription[]>);

    return <Box> {
        Object.entries(groupedPermissions).map(([module, permissions]) => <Box key={module} sx={{ marginBottom: 4 }}>
            <RowCentered>
                <Typography color={'primary'} variant="h6" fontWeight={'bold'}>{module}</Typography>
            </RowCentered>
            <PermissionsTable {...props} permissions={permissions} />
        </Box>
        )}
    </Box>
}

function PermissionsTable(props: {
    permissions: PermissionsDescription[],
    selected: Permissions[],
    onChanged: (permissions: Permissions[]) => void
}) {
    const hasPermission = (permission: Permissions) => props.selected.includes(permission);
    const onSelect = (permission: Permissions) => {
        if (hasPermission(permission)) {
            props.onChanged(props.selected.filter(p => p !== permission));
        } else {
            props.onChanged([...props.selected, permission]);
        }
    }

    return <Table
        hideFilters
        id="Permissions"
        shrink
        slim
        columns={[{
            headerName: 'Permissie',
            cellRenderer: (row) => <Box sx={{ display: 'flex', alignItems: 'center', height: 45 }}>
                <Typography variant="overline">{row.data.description}</Typography>
            </Box>
        }, {
            headerName: 'Lezen',
            width: 200,
            cellRenderer: (row) => row.data.readPermission && <Checkbox
                checked={hasPermission(row.data.readPermission)}
                onClick={() => onSelect(row.data.readPermission)}
            />
        }, {
            headerName: 'Bewerken',
            width: 200,
            flex: 1,
            cellRenderer: (row) => row.data.writePermission && <Checkbox
                checked={hasPermission(row.data.writePermission)}
                onClick={() => onSelect(row.data.writePermission)}
            />
        }]}
        data={props.permissions}
    />
}

export function OrganizationPermissionsTable(props: {
    selected: Permissions[],
    onChanged: (permissions: Permissions[]) => void
}) {
    return <PermissionsTableList {...props}
        permissions={[
            { module: 'Bedrijven', description: 'Bedrijf', readPermission: Permissions.CompanyReader, writePermission: Permissions.CompanyWriter },
            { module: 'Bedrijven', description: 'Lidmaatschap Rechten', writePermission: Permissions.PermissionEditor },
            { module: 'Personen', description: 'Personen', readPermission: Permissions.PersonReader, writePermission: Permissions.PersonWriter },
            { module: 'Contracten', description: 'Contracten', readPermission: Permissions.ContractReader, writePermission: Permissions.ContractWriter },
            { module: 'Contracten', description: 'Alle Geregistreerde Uren', readPermission: Permissions.TimesheetReader },
            { module: 'Contracten', description: 'Uren Goedkeuren', writePermission: Permissions.TimesheetApprover },
        ]}
    />
}

export function WhitelabelPermissionsTable(props: {
    selected: Permissions[],
    onChanged: (permissions: Permissions[]) => void
}) {
    return <PermissionsTableList {...props}
        permissions={[
            { module: 'Recruiter', description: 'Kandidaten', readPermission: Permissions.KandidaatReader, writePermission: Permissions.KandidaatWriter },
            { module: 'Recruiter', description: 'Vacatures', readPermission: Permissions.VacatureReader, writePermission: Permissions.VacatureWriter },
            { module: 'Recruiter', description: 'Vacatures Publiceren', writePermission: Permissions.VacaturePublisher },
            { module: 'Bedrijven', description: 'Lidmaatschap Rechten', writePermission: Permissions.PermissionEditor },
            { module: 'Bedrijven', description: 'Alle Bedrijven', readPermission: Permissions.CompanyReader, writePermission: Permissions.CompanyWriter },
            { module: 'Personen', description: 'Alle Personen', readPermission: Permissions.PersonReader, writePermission: Permissions.PersonWriter },
            { module: 'Contracten', description: 'Contracten', readPermission: Permissions.ContractReader, writePermission: Permissions.ContractWriter },
            { module: 'Contracten', description: 'Alle Geregistreerde Uren', readPermission: Permissions.TimesheetReader },
            { module: 'Contracten', description: 'Uren Goedkeuren', writePermission: Permissions.TimesheetApprover },

            //Settings
            { module: 'Beheer', description: 'Co-Eigenaar', writePermission: Permissions.WhitelabelOwnerSettingsAdmin },
            { module: 'Beheer', description: 'Beheerders', writePermission: Permissions.WhitelabelPermissionsAdmin },
            { module: 'Beheer', description: 'Email Berichten', writePermission: Permissions.EmailTemplatesAdmin },
            { module: 'Beheer', description: 'Contracten', writePermission: Permissions.ContractSettingsAdmin },
            { module: 'Beheer', description: 'Documenten', writePermission: Permissions.DocumentManagementAdmin },
        ]}
    />
}
import { Box, Fade } from "@mui/material";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { CardDialog, ContainedButton, FormStepper, PrevNextButtons, WelcomeSection, YesNoToggle } from "../../molecules";
import { PersonDetailsForm } from "../person/PersonDetails";
import { EmptyPerson } from "../../../types/company/person";
import { OrganizationBankingForm } from "../company/CompanyBankingForm";
import { OrganizationDetailsForm } from "../company/CompanyDetailsForm";
import { BankAccount, EmptyBankAccount } from "../../../types/company/bankAccount";
import { OnboardingDocumentsForm } from "../person/OnboardingDocuments";
import { RequestedDocument } from "../../../types/documents/Documents";
import { InhuurWithCompanyInvitePayload } from "../../../types/company/invitePayloads";
import { AddCompanyPayload, EmptyAddCompanyPayload } from "../../../types/company/payloads";
import { ContactPersonsTable } from "../../molecules/tables/person/Persons";
import { MembershipRole } from "../../../types/company/membership";
import { AddContactPersonForm } from "../person/ContactPerson";
import { useAppDispatch } from "../../../store/hooks";
import { showToast } from "../../../store/slices/toastSlice";
import { ContactPersonProps } from "../../../types/company/contactPerson";
import { setTutorial } from "../../../store/slices/heroTutorialSlice";
import { tutorialDescriptions } from "../../../utils/tutorials";


export function InhuurWithCompanyInviteForm(props: {
    loading: boolean;
    onCompleted: (payload: InhuurWithCompanyInvitePayload, files: (File | undefined)[]) => void;
    onboardingItems: RequestedDocument[];
    initialEmail?: string;
}) {
    const dispatch = useAppDispatch();
    const [showWelcome, setShowWelcome] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [company, setCompany] = useState<AddCompanyPayload>(EmptyAddCompanyPayload());
    const [contacts, setContacts] = useState<ContactPersonProps[]>([]);
    const [files, setFiles] = useState<(File | undefined)[]>([]);
    const [kvkValid, setKvkValid] = useState<boolean>(false);

    const [professional, setProfessional] = useState(new EmptyPerson());
    const [withProfessional, setWithProfessional] = useState(false);
    const [withProfessionalCredentials, setWithProfessionalCredentials] = useState(false);

    const [showContactDialog, setShowContactDialog] = useState(false);
    const authorizedSignatory = contacts.find(c => c.roles.includes(MembershipRole.AuthorizedSignatory));

    const getDescription = (index: number) => {
        switch (index) {
            case 0:
                return tutorialDescriptions.companyDetailsForm;
            case 1:
                return tutorialDescriptions.fincialForm;
            case 2:
                return tutorialDescriptions.contactPersonForm;
            case 3:
                return tutorialDescriptions.professionalForm;
            case 4:
                return tutorialDescriptions.documentsForm;

            default:
                return tutorialDescriptions.companyDetailsForm;
        }
    }

    useEffect(() => {
        setFiles(props.onboardingItems.map(() => undefined));
    }, [props.onboardingItems]);

    const handleChangeStep = (value: number) => {
        if (value > 2) {
            if (!authorizedSignatory) {
                alert("Voeg minimaal één contactpersoon toe met de rol Tekenbevoegde");
                setActiveStep(2);
                dispatch(setTutorial(getDescription(2)));
                return;
            }
        }

        setActiveStep(value);
        dispatch(setTutorial(getDescription(value)));
    }

    const setBankAccount = (bankAccount: BankAccount) => {
        setCompany({
            ...company,
            bankAccount
        });
    }

    const onRemovePerson = (person: ContactPersonProps) => setContacts(contacts.filter(p => p !== person));

    const onCompleted = () => {
        props.onCompleted({
            allowProfessionalLogin: withProfessionalCredentials,
            company,
            contacts,
            professional: withProfessional ? professional : undefined,
            files: []
        }, files)
    }

    const steps = [
        {
            label: "Bedrijfsgegevens",
            content: <OrganizationDetailsForm onKvkValidated={setKvkValid} company={company} onChange={setCompany} />
        },
        {
            label: "Financiële gegevens",
            content: <OrganizationBankingForm bankAccount={company.bankAccount ?? new EmptyBankAccount()} onChange={setBankAccount} />
        },
        {
            label: "Contactpersonen",
            content: <Box>
                <ContainedButton onClick={() => setShowContactDialog(true)}>Contact Toevoegen</ContainedButton>
                <ContactPersonsTable persons={contacts} onRemove={onRemovePerson} />
            </Box>
        },
        {
            label: "Professional",
            content: <Box>
                <YesNoToggle value={withProfessional} onChange={setWithProfessional} title="Direct een Professional toevoegen?" />
                <Box mt={2} />
                {withProfessional && <YesNoToggle value={withProfessionalCredentials} onChange={setWithProfessionalCredentials} title="Mag deze professional inloggen?" />}
                {withProfessional && <PersonDetailsForm
                    showDateOfBirth
                    showNationality
                    person={professional} onChange={setProfessional} />}
            </Box>
        }
    ];

    if (props.onboardingItems.length > 0)
        steps.push({
            label: "Documenten",
            content: <OnboardingDocumentsForm
                hidePersonDocs={!withProfessional}
                files={files}
                onChange={setFiles}
                items={props.onboardingItems} />
        })

    if (showWelcome)
        return <WelcomeSection
            sectionFor="Organisatie"
            onCompleted={() => {
                setShowWelcome(false);
                dispatch(setTutorial(tutorialDescriptions.companyDetailsForm));
            }}
        />

    return (<Fade in timeout={200} appear={true}>
        <Box>
            <CardDialog visible={showContactDialog} closeText="Annuleren" onDismiss={() => setShowContactDialog(false)}>
                <AddContactPersonForm
                    onComplete={(p) => {
                        if (contacts.find(c => c.details.email === p.details.email)) {
                            dispatch(showToast({ message: "Contactpersoon met dit email staat al in de lijst", type: "error" }));
                            return;
                        }

                        setContacts([...contacts, p]);
                        setShowContactDialog(false);
                    }}
                />
            </CardDialog>
            <FormStepper
                maxWidth={activeStep === 2 ? 'lg' : undefined}
                onStepClicked={handleChangeStep}
                activeStep={activeStep}
                steps={steps}
            >
                <PrevNextButtons
                    variant="text"
                    showNext
                    showPrev
                    prevDisabled={activeStep === 0}
                    nextDisabled={activeStep === steps.length - 1}
                    onPrev={() => handleChangeStep(activeStep - 1)}
                    onNext={() => handleChangeStep(activeStep + 1)}
                />
            </FormStepper>
            <LoadingButton disabled={!kvkValid} loading={props.loading} sx={{ my: 2 }} variant="text" onClick={onCompleted}>Versturen</LoadingButton>
        </Box>
    </Fade>
    );
}
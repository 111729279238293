import { lazy } from "react";
import { PlatformUser } from "../../types/user";
import { ProtectedRoutes } from "../ProtectedRoutes";
import { contractenRoutes, timeRegistrationRoutes } from "../paths";

const ContractsListPage = lazy(() => import("../../ui/pages/contracts/List"));
const ContractCreatePage = lazy(() => import("../../ui/pages/contracts/Create"));
const ContractDetailsPage = lazy(() => import("../../ui/pages/contracts/Details"));
const TimeRegistrationsListPage = lazy(() => import("../../ui/pages/contracts/TimeRegistrations"));

export function ContractRoutes(user: PlatformUser) {
    const pathsAndComponentsMap = {
        [contractenRoutes.list]: ContractsListPage,
        [contractenRoutes.create]: ContractCreatePage,
        [contractenRoutes.details(":id")]: ContractDetailsPage,
        [timeRegistrationRoutes.list]: TimeRegistrationsListPage
    };

    return <>
        {ProtectedRoutes({
            user,
            pathsAndComponentsMap: pathsAndComponentsMap
        })}
    </>;
}

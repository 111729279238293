import { useNavigate } from "react-router-dom";
import { CommentEditorInput, MemoTooltip, RelationStatusButton, RowCentered } from "../..";
import { Professional } from "../../../../types/recruiter/professional";
import { VacancyRelation, VacancyRelationState } from "../../../../types/recruiter/relations";
import { ColumnProps, Table } from "../Table";
import { Box, Link } from "@mui/material";
import { recruiterRoutes } from "../../../../router/paths";
import { MatchScore } from "../../../../types/recruiter/matchScore";
import { ScoreSummaryButton } from "../../ScoreSummaryButton";
import { professionalPriceColumn } from "./Columns";

type RowData = {
    professional: Professional;
    relation?: VacancyRelation;
    score?: MatchScore;
};

export function VacancyProfessionalsTable(props: {
    professionals: Professional[];
    scores: MatchScore[];
    states: VacancyRelationState[];
    relations: VacancyRelation[];
    onRelationChange: (relation: VacancyRelation, newState: VacancyRelationState) => void;
    onCommentChange: (relation: VacancyRelation, comment: string) => void;
}) {
    const nav = useNavigate();

    const data: RowData[] = props.professionals.map(professional => ({
        professional,
        relation: props.relations.find(r => r.professionalId === professional._id),
        score: props.scores.find(s => s.professionalId === professional._id)
    }));

    const colDefs: ColumnProps<RowData>[] = [
        {
            field: 'relation.state',
            width: 270,
            valueGetter: (row) => row.data.relation?.status.index,
            cellRenderer: (row: { data: RowData }) => <RelationStatusButton states={props.states} onChange={(value) => props.onRelationChange(row.data.relation!, value)} state={row.data.relation?.status!} />,
            headerName: "Status"
        },
        {
            width: 120,
            field: "score",
            headerName: "Score",
            sort: 'desc',
            valueGetter: (row) => row.data.score !== undefined ? row.data.score.score : 0,
            cellRenderer: (row) => <ScoreSummaryButton matchScore={row.data.score} />
        },
        professionalPriceColumn(row => row.data.professional),
        {
            width: 200,
            headerName: "Kandidaat",
            field: "professional.name",
            valueGetter: (row) => row.data.professional?.name,
            cellRenderer: (row: { data: RowData }) => <RowCentered>
                <Link color={row.data.professional?.isAvailable ? 'primary' : 'error'} onClick={() => nav(recruiterRoutes.kandidaten.details(row.data.professional?._id!))}>{row.data.professional?.name}</Link>
                {
                    row.data.professional?.memo && <MemoTooltip memo={row.data.professional.memo} />
                }
            </RowCentered>
        },
        {
            flex: 1,
            field: "relation.comment",
            headerName: "Opmerking",
            cellRenderer: (row: { data: RowData }) =>
                <Box mt={1}>
                    <CommentEditorInput initialValue={row.data.relation?.comment ?? ""} onSubmit={
                        (value) => props.onCommentChange(row.data.relation!, value)
                    } />
                </Box>
        }
    ];

    return <Table id="VacProf" columns={colDefs} data={data} paging />;
}


import { MaterieleRegistratie } from "../types/company/kvk";
import dayjs from "dayjs";

// eslint-disable-next-line
import weekOfYear from "dayjs/plugin/weekOfYear"
import moment from "moment";

export const daysFromNow = (days: number) => {
    const weekFromNow = new Date();
    weekFromNow.setDate(weekFromNow.getDate() + days);

    return weekFromNow
};

export const humanReadableDate = (registratie: MaterieleRegistratie) => {
    const year = registratie.datumAanvang.substring(0, 4);
    const month = registratie.datumAanvang.substring(4, 6);
    const day = registratie.datumAanvang.substring(6, 8);

    return `${day}/${month}/${year}`;
}

export const getWeekNumber = (_date: number) => {
    return dayjs(_date).week();
}

export const getMillisecondsFromWeek = (week: number): {
    from: number,
    to: number
} => {
    const from = dayjs().week(week).startOf('week').valueOf();
    const to = dayjs().week(week).endOf('week').valueOf();

    return {
        from,
        to
    }
}

export const getMillisecondsFromMonth = (month: number): {
    from: number,
    to: number
} => {
    const from = dayjs().month(month).startOf('month').valueOf();
    const to = dayjs().month(month).endOf('month').valueOf();

    return {
        from,
        to
    }
}

export const getMonthFromWeek = (week: number) => {
    return dayjs().week(week).month();
}

export const monthToFriendlyString = (month: number) => {
    return dayjs().month(month).format("MMMM");
}

export const millisToFriendlyString = (millis: number, exludeTime?: boolean) => {
    const format = exludeTime ? "DD MMM YYYY" : "DD MMM YYYY HH:mm";
    return moment(new Date(millis)).format(format);
}
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { CootjeLogo, FormStepper, PrevNextButtons } from "../../molecules";
import { PersonDetailsForm } from "../person/PersonDetails";
import { Person, EmptyPerson } from "../../../types/company/person";
import { OrganizationBankingForm } from "../company/CompanyBankingForm";
import { BankAccount, EmptyBankAccount } from "../../../types/company/bankAccount";
import { OnboardingDocumentsForm } from "../person/OnboardingDocuments";
import { IdentificationDetails } from "../../../types/company/invite";

import { useParams } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { AddressForm } from "../person/AddressForm";
import { Address, EmptyAddress } from "../../../types/company/address";
import { TAOInvitePayload } from "../../../types/company/invitePayloads";
import { CredentialsForm } from "../person/CredentialsForm";

import inviteApi from "../../../api/organization/invite";
import { generateGuid } from "../../../utils/strings";
import { DocumentType, FileUploadRequest, DocumentModule, DocumentState, RequestedDocument } from "../../../types/documents";

const identificationOnboardingItems: FileUploadRequest[] = [{
    _id: generateGuid(),
    title: "ID Voorkant",
    description: "Scan of foto van de voorkant van uw identiteitsbewijs",
    linkedTo: {
        module: DocumentModule.Person,
        targetId: ""
    },
    managedBy: {
        module: DocumentModule.Person,
        targetId: ""
    },
    state: DocumentState.Requested,
    type: DocumentType.FileUpload,
    updatedAt: Date.now()
}, {
    _id: generateGuid(),
    title: "ID Achterkant",
    description: "Scan of foto van de achterkant van uw identiteitsbewijs",
    linkedTo: {
        module: DocumentModule.Person,
        targetId: ""
    },
    managedBy: {
        module: DocumentModule.Person,
        targetId: ""
    },
    state: DocumentState.Requested,
    type: DocumentType.FileUpload,
    updatedAt: Date.now()
}]

export function TAOInviteForm(props: {
    loading: boolean,
    onCompleted: (payload: TAOInvitePayload, files: (File | undefined)[]) => void,
    onboardingItems: RequestedDocument[],
    initialEmail?: string
}) {
    const params = useParams();
    const [readingDocuments, setReadingDocuments] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [readingDocumentsError, setReadingDocumentsErrors] = useState<string[]>([]);
    const [activeStep, setActiveStep] = useState(0);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [person, setPerson] = useState<Person>({
        ...new EmptyPerson(),
        address: EmptyAddress()
    });
    const [bank, setBankAccount] = useState<BankAccount>(new EmptyBankAccount());
    const [identificationFiles, setIdentificationFiles] = useState<(File | undefined)[]>([undefined, undefined]);
    const [files, setFiles] = useState<(File | undefined)[]>(props.onboardingItems.map(() => undefined));

    const onCompleted = () => props.onCompleted({
        professional: person,
        iban: bank.iban,
        credentials: { email, password },
        files: []
    }, files);

    const onIdentificationUploaded = async (documents: (File | undefined)[]) => {
        let bsn: string | undefined = undefined;
        let details: IdentificationDetails | undefined = {
            dateOfBirth: "",
            firstName: "",
            lastName: "",
            documentKind: "",
            validUntil: "",
        };

        if (!documents[0] || !documents[1])
            return;

        setReadingDocuments(true);
        setShowDialog(true);

        await Promise.all([
            inviteApi.readIdentificationFront(params.environment!, params.code!, documents[0]).then((response) => details = response).catch(),
            inviteApi.readIdentificationBack(params.environment!, params.code!, documents[1]).then((response) => bsn = response).catch()
        ])
            .catch();

        setReadingDocuments(false);

        if (!details || (!details.dateOfBirth && !details.firstName && !details.lastName))
            details = undefined;

        if (!details || !bsn) {
            let errors = [];

            if (!details) errors.push("Identificatie Voorkant kon niet worden gelezen.");
            if (!bsn) errors.push("Identificatie Achterkant kont niet worden gelezen.");

            setReadingDocumentsErrors(errors);
        }

        if (details !== undefined) {
            setPerson({
                ...person,
                firstName: (details as IdentificationDetails).firstName,
                lastName: (details as IdentificationDetails).lastName,
                dateOfBirth: new Date((details as IdentificationDetails).dateOfBirth).toJSON(),
                initials: (details as IdentificationDetails).firstName[0] + (details as IdentificationDetails).lastName[0],
                bsn: bsn ?? ""
            });
        }
    };

    useEffect(() => {

        if (!props.initialEmail)
            return;

        setEmail(props.initialEmail);
        setPerson(p => ({ ...p, email: props.initialEmail ?? '' }));

    }, [props.initialEmail]);

    const steps = [
        {
            label: "Inloggegevens",
            content: <CredentialsForm email={email} password={password} onChange={({ email, password: passsword }) => {
                setEmail(email);
                setPassword(passsword);
            }} />
        },
        {
            label: "Identificatie Documenten",
            content: <OnboardingDocumentsForm
                allowedTypes={["png", "jpg", "jpeg"]}
                files={identificationFiles}
                onChange={f => {
                    setIdentificationFiles(f);
                    onIdentificationUploaded(f);
                }}
                items={identificationOnboardingItems} />
        },
        {
            label: "Persoonsgegevens",
            content: <PersonDetailsForm showBsn showDateOfBirth showNationality person={person} onChange={setPerson} />
        },
        {
            label: "Adres gegevens",
            content: <AddressForm
                address={person.address as Address}
                onChange={(a) => setPerson({ ...person, address: a })}
            />
        },
        {
            label: "Fincanciële gegevens",
            content: <OrganizationBankingForm hideG={true} bankAccount={bank ?? new EmptyBankAccount()} onChange={setBankAccount} />
        }
    ];

    if (props.onboardingItems.length > 0)
        steps.push({
            label: "Bestanden",
            content: <OnboardingDocumentsForm
                files={files}
                onChange={setFiles}
                items={props.onboardingItems} />
        });

    return (<>
        <FormStepper
            onStepClicked={setActiveStep}
            activeStep={activeStep}
            steps={steps}
        >
            <Dialog open={showDialog}>
                <DialogContent>
                    <CootjeLogo />
                    <Typography sx={{ p: 2 }} color={'secondary'}>
                        {readingDocuments ? "Documenten valideren..." : readingDocumentsError.join("\n")}
                        {!readingDocuments && readingDocumentsError.length === 0 && "Documenten zijn met succes gevalideerd."}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={readingDocuments} onClick={() => {
                        setShowDialog(false);

                        if (readingDocumentsError.length === 0)
                            setActiveStep(activeStep + 1);

                        setReadingDocumentsErrors([]);
                    }}>Sluiten</LoadingButton>
                </DialogActions>
            </Dialog>

            <PrevNextButtons
                showNext
                showPrev
                prevDisabled={activeStep === 0}
                nextDisabled={activeStep === steps.length - 1}
                onPrev={() => setActiveStep(activeStep - 1)}
                onNext={() => setActiveStep(activeStep + 1)}
            />
        </FormStepper>
        <LoadingButton loading={props.loading} sx={{ my: 2 }} variant="text" onClick={onCompleted}>Versturen</LoadingButton>
    </>
    );
}
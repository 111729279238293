import { DocumentModule, IBaseDocument } from "./BaseDocument";

export enum ListUsedFor {
    Onboarding = 1,
    Contract = 2
}

export enum ListItemUsedFor {
    Person = 1,
    Supplier = 2,
    Hirer = 3
}

export interface DocumentListItem extends IBaseDocument {
    documentReferenceId: string;
    managedBy: DocumentModule;
    linkedTo: DocumentModule;
    usedFor: ListItemUsedFor;
}

export interface DocumentList {
    _id: string;
    title: string;
    description: string;
    items: DocumentListItem[];
    target: ListUsedFor;
}
import { useNavigate } from "react-router-dom";
import { ContractPerson, ContractTimeRegistrations, Organization, TimeRegistration, TimeRegistrationState, TimesheetApproversMode } from "../../../../types/contracts";
import { ColumnProps, Table } from "../Table";
import { contractenRoutes } from "../../../../router/paths";
import { calculateApprovalState } from "../../../../utils/timesheets";

interface RowData {
    _id: string;
    professional: ContractPerson;
    role: string;
    hirer: Organization;
    contractnumber: string;
    startDate: number;
    endDate: number;
    mode: TimesheetApproversMode;
    timeRegistration: TimeRegistration;
    hoursRegistered: number;
    hoursReviewed: number;
    [key: string]: any;
}

export function TimeRegistrationsOverviewTable(props: {
    details: ContractTimeRegistrations[];
}) {
    const nav = useNavigate();

    const tableData: RowData[] = props.details.flatMap(c => c.timeRegistrations.map((x) => {
        let data = {
            _id: c._id,
            professional: c.professional,
            role: c.role,
            hirer: c.hirer,
            contractnumber: c.contractnumber,
            startDate: c.startDate,
            endDate: c.endDate,
            mode: c.mode,
            hoursRegistered: 0,
            hoursReviewed: 0,
            timeRegistration: x
        } as RowData;

        c.references.forEach((ref) => data[ref.ref] = ref.value);

        return data;
    }));

    const groupedData: RowData[] = Object.values(tableData.reduce((acc, cur) => {
        const key = cur.contractnumber;
        if (!acc[key])
            acc[key] = { ...cur, timeRegistration: { ...cur.timeRegistration, amount: 0 } };

        acc[key].hoursRegistered += cur.timeRegistration.amount;
        if (calculateApprovalState(cur.mode, cur.timeRegistration.approvalStates) !== TimeRegistrationState.Pending)
            acc[key].hoursReviewed += cur.timeRegistration.amount;

        return acc;
    }, {} as { [key: string]: RowData }));

    const allReferences = props.details.flatMap(c => c.references);
    const uniqueReferences = allReferences.filter((ref, index, self) =>
        index === self.findIndex((t) => (t.ref === ref.ref))
    );

    const colDefs: ColumnProps<RowData>[] = [
        {
            field: "contractnumber",
            headerName: "Contractnummer",
            valueGetter: (params) => params.data.contractnumber,
        },
        {
            field: "hoursApproved",
            headerName: "Uren beoordelen",
            valueGetter: (params) => params.data.hoursRegistered - params.data.hoursReviewed,
        },
        {
            field: "professional.name",
            headerName: "Professional",
            valueGetter: (params) => params.data.professional.name,
        },
        {
            field: "role",
            headerName: "Functie",
            valueGetter: (params) => params.data.role,
        },
        ...uniqueReferences.map((ref) => ({
            field: ref.ref,
            headerName: ref.name,
            valueGetter: (params: any) => params.data[ref.ref],
        }))
    ];

    return <Table id="ContractTimeregistrations"
        autoSizeColumns
        onRowClicked={(params) => nav(contractenRoutes.details(params.contractnumber) + '?tab=Timesheets')}
        columns={colDefs} data={groupedData}
        paging />
}
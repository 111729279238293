import { defaultRevision, Revision } from "./Revision";
import { defaultPerson, ContractPerson } from "./Person";
import { defaultOrganisation, Organization } from "./Organisation";
import { defaultInvoiceDetails, InvoiceDetails } from "./InvoiceDetails";
import { ContractFinancial, defaultCompanyFinancial, defaultTAOFinancial } from "./ContractFinancial";
import { defaultDivision, Division } from "./Division";
import { Assignment, defaultAssignment } from "./Assignment";

export enum Contracts {
    Zzp = 1,
    Tao = 2,
    Inhuur = 3
}

export enum TimesheetApproversMode {
    One = 1,
    Parallel = 2,
    Sequential = 3
}

export interface BaseContract {
    _id: string;
    contractnumber: string;
    contractKind: Contracts;
    signedDate: number;
    creationDate: number;
    isArchived: boolean;
    hirer: Organization;
    contactHirer: ContractPerson;
    supplier: Organization;
    endCustomer: Organization;
    professional: ContractPerson;
    authorizedSignatoriesSupplier: ContractPerson[];
    authorizedSignatoriesHirer: ContractPerson[];
    timesheetApprovers: ContractPerson[];
    contractManager: string;
    mode: TimesheetApproversMode;
    recruiter: string;
    revision: Revision;
    invoiceDetails: InvoiceDetails;
    financial: ContractFinancial;
    assignment: Assignment;
    documents: Document[];
    division: Division;
}

export interface ZzpContract extends BaseContract {
    contractKind: Contracts.Zzp;
}

export interface TaoContract extends BaseContract {
    contractKind: Contracts.Tao;
}

export interface InhuurContract extends BaseContract {
    contractKind: Contracts.Inhuur;
}

export const defaultZzpContract = (): ZzpContract => ({
    _id: '',
    documents: [],
    recruiter: '',
    contractnumber: '',
    contractKind: Contracts.Zzp,
    signedDate: 0,
    creationDate: 0,
    isArchived: false,
    hirer: defaultOrganisation(),
    supplier: defaultOrganisation(),
    endCustomer: defaultOrganisation(),
    professional: defaultPerson(),
    authorizedSignatoriesSupplier: [],
    authorizedSignatoriesHirer: [],
    assignment: defaultAssignment(),
    contractManager: '',
    revision: defaultRevision(),
    division: defaultDivision(),
    contactHirer: defaultPerson(),
    invoiceDetails: defaultInvoiceDetails(),
    financial: defaultCompanyFinancial(),
    timesheetApprovers: [],
    mode: TimesheetApproversMode.One
});

export const defaultTaoContract = (): TaoContract => ({
    _id: '',
    contractnumber: '',
    contractKind: Contracts.Tao,
    signedDate: 0,
    creationDate: 0,
    isArchived: false,
    hirer: defaultOrganisation(),
    supplier: defaultOrganisation(),
    endCustomer: defaultOrganisation(),
    professional: defaultPerson(),
    contactHirer: defaultPerson(),
    timesheetApprovers: [],
    authorizedSignatoriesSupplier: [],
    authorizedSignatoriesHirer: [],
    contractManager: '',
    documents: [],
    recruiter: '',
    revision: defaultRevision(),
    division: defaultDivision(),
    invoiceDetails: defaultInvoiceDetails(),
    financial: defaultTAOFinancial(),
    assignment: defaultAssignment(),
    mode: TimesheetApproversMode.One
});

export const defaultInhuurContract = (): InhuurContract => ({
    _id: '',
    contractnumber: '',
    contractKind: Contracts.Inhuur,
    signedDate: 0,
    creationDate: 0,
    isArchived: false,
    hirer: defaultOrganisation(),
    timesheetApprovers: [],
    supplier: defaultOrganisation(),
    contactHirer: defaultPerson(),
    endCustomer: defaultOrganisation(),
    professional: defaultPerson(),
    authorizedSignatoriesSupplier: [],
    authorizedSignatoriesHirer: [],
    mode: TimesheetApproversMode.One,
    contractManager: '',
    documents: [],
    recruiter: '',
    revision: defaultRevision(),
    division: defaultDivision(),
    invoiceDetails: defaultInvoiceDetails(),
    financial: defaultCompanyFinancial(),
    assignment: defaultAssignment()
});

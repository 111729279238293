import { BookmarkTwoTone, EmailTwoTone } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { VacancyRelationState } from "../../../types/recruiter";
import { FollowUpEmailTemplate } from "../../../types/recruiter/followUp";
import { requiredValidation } from "../../validations";
import { CollapsableTitle } from "../CollapsableTitle";
import { TextInput } from "../inputs";
import { RowCentered } from "../Row";
import { EmailEditorMolecule } from "./EmailEditor";
import { retrieveLogo } from "../../../utils/cache";
import { Checkbox } from "../inputs/CheckboxInput";

export const defaultFollowUpHtml = () => `
<html>
    <head>
        <style>
            .darkBlue, h2, h3 {
                color: #34495e !important;
            }

            hr {
                margin: 10px 0;
                height: 2px;
                background: hsl(0, 0%, 87%);
                border: 0;
            }
        </style>
    </head>
    <body>
        <p>
            <img class="image_resized" style="height:100px;" src="${retrieveLogo()}" height="100">
        </p>
        <h2 class="darkBlue">
            Follow Up Bericht
        </h2>
        <hr>
        <p>
            <strong>Goedemorgen / Goedemiddag [NAME],</strong>
            <br>
            Zoals besproken, ontvang je hieronder uitslag voor de vacature <strong>[VACANCY_TITLE]</strong>:<br /><br />

            Onze tooling scoort jouw match op <strong>[MATCHING_SCORE]%</strong>.<br />
            De AI gegenereerde samenvatting (feedback op de matching van jouw CV en aanvraag) is als volgt: <br /><br />
            [MATCHING_SUMMARY]<br /><br />

            <strong>Mogelijke verbeterpunten:</strong><br />
            [MATCHING_IMPROVEMENTS]
        <br /><br />
        Met vriendelijke groet,<br /><br />

        <strong>Onze Organisatie</strong>
    </body>
</html>
`;

export const FollowUpEditor: FC<{
    value: FollowUpEmailTemplate;
    onChange: (value: FollowUpEmailTemplate) => void;
    states: VacancyRelationState[];
}> = ({ value, onChange, states }) => {

    const isStateChecked = (stateId: string) => value.usedByStates.includes(stateId);

    return <Box>
        <RowCentered sx={{ mb: 1 }}>
            <TextInput
                value={value.name}
                icon={BookmarkTwoTone}
                label="Naam"
                sx={{ flex: 1, pr: 1 }}
                required
                validator={requiredValidation}
                onChange={name => onChange({ ...value, name })}
            />

            <TextInput
                sx={{ flex: 2 }}
                value={value.subject}
                icon={EmailTwoTone}
                label="Onderwerp"
                required
                validator={requiredValidation}
                onChange={subject => onChange({ ...value, subject })}
            />
        </RowCentered>

        <CollapsableTitle defaultOpen={false} title="Tonen bij kandidaat status" variant="body1" sx={{ my: 2, minWidth: 300, flex: 1 }}>
            <Typography variant="body2" color={'secondary'}>Hiermee word deze email optie enkel getoond bij kandidaten welke de volgende status hebben:</Typography>
            {
                states.map(state => <Checkbox
                    key={state._id}
                    title={state.name}
                    checked={value.usedByStates.includes(state._id)}
                    onChange={() => onChange({
                        ...value,
                        usedByStates: !isStateChecked(state._id)
                            ? [...value.usedByStates, state._id]
                            : value.usedByStates.filter(id => id !== state._id)
                    })}
                />
                )
            }
        </CollapsableTitle>

        <EmailEditorMolecule
            config={{
                withGenericNameButton: true,
                recruiter: {
                    withVacancyTitleButton: true,
                    withRecruiterMatchingScoreButton: true,
                    withRecruiterMatchingSummaryButton: true,
                    withRelationStatusButton: true,
                    withRecruiterMatchingImprovementsButton: true,
                    withVacancyLocationButton: true,
                    withVacancyDescriptionButton: true,
                    withVacancyStartDateButton: true,
                    withVacancyEndDateButton: true
                }
            }}
            html={value.body}
            onChange={body => onChange({ ...value, body })}
        />
    </Box>
}
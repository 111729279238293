import { BusinessTwoTone, DynamicFormTwoTone, EmojiPeopleTwoTone, HandymanTwoTone, HistoryEduTwoTone, PersonTwoTone, PictureAsPdfTwoTone, TransferWithinAStationTwoTone, UploadTwoTone } from "@mui/icons-material";
import { DocumentList, ListUsedFor, ListItemUsedFor, DocumentType, DocumentModule } from "../types/documents";


export const getDefaultOnboardingList = (inviteType: string, documentLists: DocumentList[]) =>
    documentLists.find(d => d.title.toLocaleLowerCase().startsWith(inviteType.toLocaleLowerCase()));

export const ListUsedForString = (type: ListUsedFor) => {
    switch (type) {
        case ListUsedFor.Contract: return 'Contract';
        case ListUsedFor.Onboarding: return 'Onboarding';
    }
}

export const documentModuleString = (type: DocumentModule) => {
    switch (type) {
        case DocumentModule.Company: return 'Bedrijf';
        case DocumentModule.Person: return 'Persoon';
        case DocumentModule.Contract: return 'Contract';
    }
}

export const documentModuleIcon = (type: DocumentModule) => {
    switch (type) {
        case DocumentModule.Company: return BusinessTwoTone;
        case DocumentModule.Person: return PersonTwoTone;
        case DocumentModule.Contract: return HistoryEduTwoTone;
    }
}

export const documentTypeIcon = (type: DocumentType) => {
    switch (type) {
        case DocumentType.DocuSign: return PictureAsPdfTwoTone;
        case DocumentType.Form: return DynamicFormTwoTone;
        case DocumentType.FileUpload: return UploadTwoTone;
    }
}

export const listItemUsedForIcon = (type: ListItemUsedFor) => {
    switch (type) {
        case ListItemUsedFor.Person: return HandymanTwoTone;
        case ListItemUsedFor.Hirer: return EmojiPeopleTwoTone;
        case ListItemUsedFor.Supplier: return TransferWithinAStationTwoTone;
    }
}

export const documentTargetString = (type: ListItemUsedFor) => {
    switch (type) {
        case ListItemUsedFor.Person: return "Professional";
        case ListItemUsedFor.Hirer: return "Inlener";
        case ListItemUsedFor.Supplier: return "Leverancier";
    }
}

export const documentTypeString = (type: DocumentType) => {
    switch (type) {
        case DocumentType.DocuSign: return 'Docusign';
        case DocumentType.Form: return 'Formulier';
        case DocumentType.FileUpload: return 'Bestand';
    }
}
import { Box, Chip, Link, Typography } from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";
import { Professional, WorkExperience } from "../../../../types/recruiter/professional";
import { useNavigate } from "react-router-dom";
import { Table } from "../Table";
import { recruiterRoutes } from "../../../../router/paths";
import { RowCentered } from "../../Row";
import { MemoTooltip } from "../../MemoTooltip";
import { professionalPriceColumn } from "./Columns";

export const SkillsRenderer = (props: CustomCellRendererProps) => <Box display='flex'>{
    props.value.map((skill: string) => <Chip label={skill} key={skill} />)
}</Box>

export const FunctionRenderer = (props: CustomCellRendererProps) => <Box display='flex' alignItems={'center'} py={1}>
    <Box sx={{ borderRadius: 2, minWidth: 40, background: "#eee", px: 1, py: 0, lineHeight: 1 }}>
        <Typography variant="overline" sx={{ fontSize: '.6rem' }}>
            {props.value}
        </Typography>
    </Box>
</Box>

export const ExperiencesRenderer = (props: CustomCellRendererProps) => <Box display='flex' alignItems={'center'} py={1}>
    {
        props.value.map((experience: WorkExperience) => <Box key={experience.company + experience.title} sx={{ borderRadius: 2, minWidth: 40, background: "#34495e", px: 1, py: 0, lineHeight: 1, mr: 1 }}>
            <Typography color="white" variant="overline" sx={{ fontSize: '.6rem' }}>
                {experience.company}
            </Typography>
        </Box>)
    }

</Box>
export function ProfessionalsTable(props: {
    professionals: Professional[]
}) {
    const nav = useNavigate();

    // Column Definitions: Defines the columns to be displayed.
    const colDefs = [
        {
            width: 200,
            headerName: "Naam",
            valueGetter: (row: any) => row.data.name,
            cellRenderer: (row: { data: Professional }) => <RowCentered>
                <Link color={row.data.isAvailable ? 'primary' : 'error'} onClick={() => nav(recruiterRoutes.kandidaten.details(row.data._id!))}>{row.data.name}</Link>
                {
                    row.data.memo && <MemoTooltip memo={row.data.memo} />
                }
            </RowCentered>
            ,

        },
        { field: "title", headerName: "Functie", width: 250, cellRenderer: FunctionRenderer },
        professionalPriceColumn(row => row.data),
        { field: "city", headerName: "Plaats", width: 180 },
        { field: "skills", headerName: "Vaardigheden", flex: 1, cellRenderer: SkillsRenderer },
    ];

    return <Table id="Profs" slim columns={colDefs} data={props.professionals} paging />
}
import { useNavigate } from "react-router-dom";
import { recruiterRoutes } from "../../../../router/paths";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { createProfessional, professionalsSelector } from "../../../../store/slices/recruiter/professionalsSlice";
import { ProfessionalDetails } from "../../../../types/recruiter/professional";

import Form from "../../../forms/recruiter/professional";

export function ProfessionalCreateOrganism() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const professionalsState = useAppSelector(professionalsSelector);
    const professionals = professionalsState.professionals;
    const fetching = professionalsState.fetchingCreate === 'fetching';

    const onComplete = (professional: ProfessionalDetails, resume?: File) => {
        dispatch(createProfessional({
            ...professional,
            phone: professional.phone === '06' ? '' : professional.phone,
            _id: "",
            resume
        }))
            .then((result: any) => {
                if (!result.error) {
                    navigate(recruiterRoutes.kandidaten.list);
                }
            });
    };

    return <Form
        completeLabel="Aanmaken"
        onComplete={onComplete}
        existingProfessionals={professionals}
        fetchingCreate={fetching}
    />
}
import { generateGuid } from "../../utils/strings";
import { Person } from "../company";

export interface ContractPerson {
    _id: string;
    name: string;
    email: string;
    telephone: string;
    bsn: string;
    function: string;
    poNumber: string;
}

export const defaultPerson = (): ContractPerson => ({
    _id: generateGuid(),
    name: '',
    email: '',
    telephone: '',
    bsn: '',
    function: '',
    poNumber: ''
})

export const contractPerson = (p: Person): ContractPerson => {
    return {
        _id: p._id,
        name: `${p.firstName} ${p.lastName}`,
        email: p.email,
        telephone: p.phoneNumber,
        bsn: p.bsn,
        function: p.function,
        poNumber: ''
    }
}
import { VacancyRelationState } from "../../types/recruiter/relations";
import { BaseApi } from "../base";
import { apiRoutes } from "../routes";

class RelationStatesApi extends BaseApi {
    constructor() {
        super(apiRoutes.recruiter + "/relationstates");
    }

    public create = (relation: VacancyRelationState) => this.fetchJson<VacancyRelationState>('', 'POST', relation);
    public list = () => this.fetchJson<VacancyRelationState[]>();
    public update = (relation: VacancyRelationState) => this.fetchJson<VacancyRelationState>(relation._id, 'PUT', relation);
    public delete = (id: string) => this.fetch(id, 'DELETE');

}

const api = new RelationStatesApi();
export default api;
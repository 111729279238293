import { ReactNode, useEffect, useState } from "react";
import { Badge, Box, Collapse, Dialog, DialogContent, ListItem, ListItemButton, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { PlatformUser } from "../../types/user";
import { ContainedButton, ContextMenuMore, CootjeLogo, TextInput } from ".";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { LogoutButtonOrganism } from "../organisms";
import useCtrlPressed from "../hooks/CtrlPressed";
import { primaryLighter } from "../theme";

import api from "../../api/auth";
import { retrieveLogo, storeToken } from "../../utils/cache";

export type DrawerItemProps = {
    icon?: ReactNode;
    title: string;
    link?: string;
    notifications?: number;
    items?: DrawerItemProps[];
}

const isExactMatch = (_item: DrawerItemProps) => Boolean(_item.link) && Boolean(window.location.pathname === _item.link);
const isParentMatch = (_item: DrawerItemProps) => window.location.pathname.startsWith(_item.link!);

export function DashboardDrawer({ modules, footer, user }: { user: PlatformUser, modules: DrawerItemProps[], footer?: ReactNode }) {
    const [environments, setEnvironments] = useState<{ _id: string, description: string }[]>([]);
    const [openWhitelabelDialog, setOpenWhitelabelDialog] = useState(false);
    const CtrlPressed = useCtrlPressed();
    const [whitelabelName, setWhitelabelName] = useState('');
    const [whitelabelCreating, setWhitelabelCreating] = useState(false);
    const [logoUri, setLogoUri] = useState<string | null>();

    useEffect(() => {
        if (!user.isAdmin)
            return;

        api.listEnvironments().then(setEnvironments);
    }, [user]);

    useEffect(() => {
        const _logo = retrieveLogo();
        setLogoUri(_logo);
    }, []);

    const exchangeToken = (environmentId: string) => async () => {
        const token = await api.getEnvironmentAdminToken(environmentId);

        if (CtrlPressed)
            window.open(window.location.origin + `?token=${token}`, '_blank');
        else {
            storeToken(token);
            window.location.reload();
        }
    };

    const createEnvButtons = () => {
        var buttons = environments
            .map(env => ({
                color: 'secondary',
                label: env.description,
                onClick: exchangeToken(env._id)
            })) as any;


        buttons.push({
            color: 'primary',
            label: 'Whitelabel Toevoegen',
            onClick: () => setOpenWhitelabelDialog(true)
        });

        return buttons;
    }

    const createWhitelabel = async () => {
        setWhitelabelCreating(true);
        await api.createWhitelabel(whitelabelName);
        api.listEnvironments().then(setEnvironments);
        setWhitelabelCreating(false);
        setOpenWhitelabelDialog(false);
    }


    return <Box sx={{ height: '100vh', minWidth: 250, zIndex: 100, overflowY: 'auto', position: 'relative' }} display='flex' flexDirection='column'>
        <LogoutButtonOrganism />
        <Dialog autoFocus open={openWhitelabelDialog} onClose={() => setOpenWhitelabelDialog(false)}>
            <DialogContent sx={{ p: 6 }}>
                <Typography variant="h6" mb={3}>Whitelabel Toevoegen</Typography>
                <TextInput value={whitelabelName} onChange={setWhitelabelName} label="Naam van nieuwe Whitelabel" sx={{ minWidth: 500 }} />
                <Box display={'flex'} width={'100%'}>
                    <Box flex={1} />
                    <ContainedButton
                        disabled={whitelabelName.length < 3}
                        onClick={createWhitelabel} loading={whitelabelCreating} sx={{ mt: 2 }}>Toevoegen</ContainedButton>
                </Box>
            </DialogContent>
        </Dialog>
        <Box>
            <Box sx={{ px: 2, pt: 3, display: 'flex' }}>
                <CootjeLogo />
            </Box>
            {
                logoUri && <Box sx={{ pt: 1, display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <img alt="alt" src={logoUri} style={{ maxWidth: 200 }} />
                </Box>
            }

            <Box sx={{ display: 'flex', alignItems: 'center', p: 2, pl: user.isAdmin ? 0 : 2 }}>
                {user.isAdmin && <ContextMenuMore
                    buttons={createEnvButtons()}
                />}
                <Box>
                    <Typography variant="caption" fontWeight={'bold'}>{user.environmentName}</Typography>
                </Box>
            </Box>


        </Box>

        <Box sx={{ pr: 2 }}>
            {modules.map((_module) => <MenuNavButton key={_module.title} {..._module} />)}
        </Box>
        <Box flex={1} />
        {footer && <Box sx={{ mb: 1, pr: 2 }}>
            {footer}
        </Box>}
    </Box>
}

export function MenuNavButton(item: DrawerItemProps) {
    const childMatch = item.items?.some(isExactMatch);
    const isActive = childMatch || isParentMatch(item) || isExactMatch(item);

    const [opened, setOpened] = useState(isActive);
    const navigate = useNavigate();

    const onClick = () => {
        if (item.items && item.items.length > 0)
            setOpened(!opened);
        else
            navigate(item.link!);
    }

    return <ListItem key={item.title} disablePadding sx={{ display: 'block', my: 1 }}>
        <ListItemButton
            onClick={onClick} sx={{
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
                minWidth: 260,
                pr: 2,
                ':hover': {
                    background: primaryLighter
                }
            }}>
            <Box mr={2} />
            {
                item.icon
            }

            <Typography variant="caption" color={isActive ? 'primary' : 'secondary'} lineHeight={'normal'} sx={{
                ml: 2,
                fontSize: '.8rem',
                fontWeight: (opened || isActive) ? 600 : 300,
                width: 80
            }}>{item.title}</Typography>

            <Box flex={1} />
            {
                Boolean(item.items?.length) && <>
                    {
                        opened
                            ? <ExpandLess color="primary" />
                            : <ExpandMore color="primary" />

                    }
                </>
            }
        </ListItemButton>

        <Collapse in={opened} timeout="auto" unmountOnExit>
            {item.items && item.items.map(childItem => <SubModuleListItem {...childItem} key={childItem.title} />)}
        </Collapse>
    </ListItem>
}

function SubModuleListItem(item: DrawerItemProps) {
    const navigate = useNavigate();
    const selected = isExactMatch(item);
    const isCtrlPressed = useCtrlPressed();

    const onClick = () => {
        if (!item.link)
            return;

        if (isCtrlPressed)
            window.open(item.link!, '_blank');
        else
            navigate(item.link!);
    }

    return <Box key={item.title} sx={{ display: 'block', mt: 1 }}>
        <ListItemButton selected={selected} onClick={onClick} sx={{ borderTopRightRadius: 4, borderBottomRightRadius: 4, minWidth: 240, px: 2.5, background: primaryLighter }}>
            {
                item.icon
            }
            <Box flex={1} />
            <Typography variant="caption" fontWeight={selected ? 600 : 300} lineHeight={'normal'} color={selected ? 'primary' : 'secondary'} sx={{ ml: 2, fontSize: '.8rem' }}>{item.title}</Typography>
            {
                item.notifications && <Badge badgeContent={item.notifications} color="primary">
                </Badge>
            }
        </ListItemButton>
    </Box>
}
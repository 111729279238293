import React, { FC, useEffect } from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography, DialogTitle, Container, Box, Divider, Modal } from "@mui/material";
import { TextInput } from "./inputs";
import { contractMergeFields, declarationMergeFields, endCustomerMergeFields, finesMergeFields, hirerMergeFields, professionalFinancialMergeFields, professionalMergeFields, supplierMergeFields, taoFinancialMergeFields } from "../../utils/contractMergeFields";
import { ContainedButton } from "./Buttons";
import { ConfirmationNumberTwoTone, ContentCopyTwoTone, DocumentScannerTwoTone, EditTwoTone, EmailTwoTone, EmojiPeopleTwoTone, EuroSymbolTwoTone, GavelTwoTone, HandymanTwoTone, HistoryEduTwoTone, HomeTwoTone, SearchTwoTone, TransferWithinAStationTwoTone } from "@mui/icons-material";
import { Row, RowCentered } from "./Row";
import { RecruiterEmailDocument } from "../../types/recruiter";
import { millisToFriendlyString } from "../../utils/dateUtils";
import { EmailEditorMolecule } from "./email/EmailEditor";
import { LoadingButton } from "@mui/lab";

export function CardDialog(props: {
    title?: string;
    closeText?: string;
    visible?: boolean;
    children?: React.ReactNode;
    onDismiss?: () => void;
}) {
    return <Dialog
        sx={{
            '& .MuiDialog-paper': {
                maxWidth: '100%',
                overflow: 'hidden',
            }
        }}
        maxWidth="lg"
        onKeyDown={(e) => {
            if (e.key !== 'Escape')
                return;

            props.onDismiss?.();
        }}
        open={Boolean(props.visible)}>
        {
            props.title && <DialogTitle>
                <Typography variant="h5" fontWeight={300} color="Secondary">{props.title}</Typography>
            </DialogTitle>
        }
        <DialogContent dividers >
            {
                props.children
            }
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={props.onDismiss}>
                {props.closeText ?? "Sluiten"}
            </Button>
        </DialogActions>
    </Dialog>
}

export const MergeFieldsDialog: FC<{
    visible: boolean;
    onDismiss: () => void;
}> = ({ visible, onDismiss }) => {
    const [search, setSearch] = React.useState('');
    const copyClipboard = (text: string) => () => {
        navigator.clipboard.writeText(text);
        onDismiss();
    }

    const MergeFieldBtn = (field: string) => <ContainedButton sx={{ mr: 1, mb: 1 }} key={field} onClick={copyClipboard(field)}>
        {field} <ContentCopyTwoTone fontSize="small" sx={{ ml: 1 }} />
    </ContainedButton>

    const filterMergeFields = (fields: string[]) => search.length > 0 ? fields.filter(f => f.toLowerCase().includes(search.toLowerCase())) : fields;

    return <CardDialog title={"Veld referenties"} closeText={"Sluiten"} visible={visible} onDismiss={onDismiss}>
        <Container maxWidth='md'>
            <Box sx={{ minWidth: 800 }}>
                <TextInput icon={SearchTwoTone} label="Zoeken" value={search} onChange={setSearch} />

                <RowCentered sx={{ mt: 2 }}>
                    <DocumentScannerTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Contract</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(contractMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <TransferWithinAStationTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Leverancier</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(supplierMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <EmojiPeopleTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Inlener</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(hirerMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <HomeTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Eindklant</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(endCustomerMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <HandymanTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Professional</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(professionalMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <HistoryEduTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">TAO</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(taoFinancialMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <EuroSymbolTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Leverancier Financieel</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(professionalFinancialMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <ConfirmationNumberTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Declaraties</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(declarationMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <GavelTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Boetes</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(finesMergeFields).map(MergeFieldBtn)}

            </Box>
        </Container>
    </CardDialog>
}

export const EmailDocumentViewerDialog: FC<{
    item: RecruiterEmailDocument,
    visible: boolean;
    onDismiss: () => void;
}> = ({ item, visible, onDismiss }) => {
    if (!item || !visible)
        return null;

    const { messages, subject } = item;
    return <CardDialog closeText={"Sluiten"} visible={visible} onDismiss={onDismiss}>
        <Container maxWidth='md'>
            <Box sx={{ minWidth: 800 }}>
                <Row><Typography variant="body1">{subject}</Typography></Row>
            </Box>
            {
                messages.map((m, i) => <Box key={i} sx={{ minWidth: 800 }}>
                    <Divider sx={{ my: 2 }} />
                    <Row>
                        <Typography variant="body2" color={'secondary'} sx={{ mr: 1 }} fontWeight={'bold'}>Van: </Typography>
                        <Typography variant="body2" color={'secondary'} fontWeight={300}>{m.from}</Typography>
                    </Row>
                    <Row>
                        <Typography variant="body2" color={'secondary'} sx={{ mr: 1 }} fontWeight={'bold'}>Aan: </Typography>
                        <Typography variant="body2" color={'secondary'} fontWeight={300}>{m.to}</Typography>
                    </Row>
                    <Row>

                        <Typography variant="body2" color={'secondary'} sx={{ mr: 1 }} fontWeight={'bold'}>Verzonden: </Typography>
                        <Typography variant="body2" color={'secondary'} fontWeight={300}>{millisToFriendlyString(m.sentAt!)}</Typography>
                    </Row>
                    <br />
                    <Row><Typography variant="body2" whiteSpace={'pre-line'}>{m.message}</Typography></Row>
                </Box>)
            }
        </Container>
    </CardDialog>
}

export const SendFollowUpDialog: FC<{
    visible: boolean;
    onDismiss: () => void;
    onSend: (
        to: string,
        subject: string,
        html: string
    ) => void;
    initialEmail: string;
    initialSubject: string;
    initialHtml: string;
    sending?: boolean;
}> = ({ visible, onDismiss, onSend, initialEmail, initialSubject, initialHtml, sending }) => {
    const [emailInput, setEmailInput] = React.useState(initialEmail);
    const [subjectInput, setSubjectInput] = React.useState(initialSubject);
    const [showEditor, setShowEditor] = React.useState(false);
    const [html, setHtml] = React.useState(initialHtml);

    useEffect(() => {
        setEmailInput(initialEmail);
        setSubjectInput(initialSubject);
        setHtml(initialHtml);
    }, [initialEmail, initialSubject, initialHtml]);

    return <>
        <Dialog open={visible} onClose={onDismiss}>
            <DialogContent sx={{ minWidth: 600 }}>
                <Typography sx={{ mb: 2 }} variant="h6">Follow up</Typography>
                <TextInput label="Email"
                    required
                    value={emailInput}
                    onChange={setEmailInput}
                />
                <TextInput sx={{ my: 2 }} label="Onderwerp" required value={subjectInput} onChange={setSubjectInput} />

                <Box sx={{ mt: 3 }} />

                <Row sx={{ mb: 2, mt: 4 }}>
                    <Button variant="text" onClick={() => setShowEditor(true)} color="primary">
                        <EditTwoTone fontSize="small" sx={{ mr: 2 }} />
                        <Typography variant="caption">Bewerk Email</Typography>
                    </Button>
                    <Box sx={{ flex: 1 }} />
                    <LoadingButton loading={sending} variant="outlined" onClick={() => onSend(emailInput, subjectInput, html)}>
                        <EmailTwoTone fontSize="small" sx={{ mr: 2 }} />
                        <Typography variant="caption">Verzenden</Typography>
                    </LoadingButton>
                </Row>
            </DialogContent>
        </Dialog>


        <Modal open={showEditor} onClose={() => setShowEditor(false)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ p: 3, background: '#fff', maxWidth: 1200, borderRadius: 1, maxHeight: '95vh', overflow: 'auto' }}>
                <Typography variant="h5" fontWeight={100}>Follow Up</Typography>
                <Box sx={{ mt: 2 }} />
                <EmailEditorMolecule
                    html={html}
                    onChange={setHtml}
                    config={{}} />

                <ContainedButton fullWidth sx={{ mt: 2 }} onClick={() => setShowEditor(false)}>Bewerken Sluiten</ContainedButton>
            </Box>
        </Modal>
    </>
}
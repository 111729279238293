import { TimesheetApproversMode } from "./Contracts";
import { Organization } from "./Organisation";
import { ContractPerson } from "./Person";
import { ReferenceValue } from "./Reference";

export enum TimeRegistrationState {
    Pending = 1,
    Approved = 2,
    Rejected = 3
}

export interface ApprovalState {
    approverId: string;
    approverName: string;
    updatedAt: number;
    rejectedReason: string;
    state: TimeRegistrationState;
}

export interface TimeRegistration {
    _id: string;
    amount: number;
    createdAt: number;
    dateTime: number;
    approvalStates: ApprovalState[];
}

export interface ContractTimeRegistrations {
    _id: string;
    professional: ContractPerson;
    role: string;
    hirer: Organization;
    contractnumber: string;
    startDate: number;
    endDate: number;
    mode: TimesheetApproversMode;
    references: ReferenceValue[];
    timeRegistrations: TimeRegistration[];
}
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, CircularProgress, SxProps, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { HexColorPicker } from 'react-colorful';

interface Props {
    loading?: boolean;
    sx?: SxProps;
    buttons: {
        icon?: React.ReactNode;
        onClick: () => void;
        label: string;
        color: 'info' | 'success' | 'error' | 'secondary' | 'primary'
    }[];
};

interface CustomProps extends Props {
    children: any;
}

export function ContextMenuMore(props: Props) {
    return (<ContextMenu {...props}>
        <IconButton disableRipple>
            <MoreVertIcon />
        </IconButton>
    </ContextMenu>
    );
}

export function ContextMenu(props: CustomProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    React.useEffect(() => {
        if (!props.loading) handleClose();
    }, [props.loading]);

    if (props.loading)
        return <CircularProgress sx={props.sx} size={18} />;

    return (
        <Box sx={props.sx}>
            <div onClick={handleClick}>
                {props.children}
            </div>
            <Menu
                id="long-menu"
                MenuListProps={{ 'aria-labelledby': 'long-button' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {props.buttons.map((btn, index) => (
                    <Box key={index}>
                        <LoadingButton fullWidth sx={{ px: 2, py: 1 }} onClick={(e) => {
                            e.stopPropagation();
                            btn.onClick();
                        }}>
                            {btn.icon}
                            <Typography sx={{ ml: 1 }} variant="overline" color={btn.color}>{btn.label}</Typography>
                            <Box flex={1} />
                        </LoadingButton>
                    </Box>
                ))}
            </Menu>
        </Box>
    );
}

export function ColorPickerContextMenu(props: {
    color: string;
    onChange: (color: string) => void;
    onClose: () => void;
    children: any;
}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => {
        setAnchorEl(null);
        props.onClose();
    };

    return (
        <Box>
            <div onClick={handleClick}>
                {props.children}
            </div>
            <Menu
                elevation={0}
                sx={{
                    '& .MuiList-root': {
                        padding: 0,
                        overflow: 'hidden',
                        borderRadius: 1
                    },
                    borderRadius: 1
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <HexColorPicker
                    color={props.color} onChange={props.onChange} />
            </Menu>
        </Box>
    );
}
import { Box, Button, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";

import timeRegistrationsApi from "../../../api/contracts/timeRegistrations";
import { DotsLoader, TextInput } from "../../molecules";
import { ContractTimeRegistrations, TimeRegistrationState } from "../../../types/contracts";
import { SearchTwoTone } from "@mui/icons-material";
import { TimeRegistrationsOverviewTable } from "../../molecules/tables/contracts";
import { Checkbox } from "../../molecules/inputs/CheckboxInput";
import { calculateApprovalState } from "../../../utils/timesheets";

export const TimeRegistrationsOverviewOrganism: FC = () => {
    const [search, setSearch] = useState<string>('');
    const [fetching, setFetching] = useState(false);
    const [contractRegistrations, setContractRegistrations] = useState<ContractTimeRegistrations[]>([]);
    const [hideReviewed, setHideReviewed] = useState(true);

    useEffect(() => { fetchTimeRegistrations() }, []);

    const fetchTimeRegistrations = () => {
        setFetching(true);

        timeRegistrationsApi
            .list()
            .then(setContractRegistrations)
            .finally(() => setFetching(false));
    }

    const filterRegistrations = (contractRegistrations: ContractTimeRegistrations) => {
        if (!hideReviewed)
            return true;

        return contractRegistrations
            .timeRegistrations
            .some(
                tr => calculateApprovalState(contractRegistrations.mode, tr.approvalStates) === TimeRegistrationState.Pending
            );
    };

    const data = contractRegistrations.filter(filterRegistrations);

    return <Box>
        <Box display={'flex'} alignItems={'flex-end'} mb={-1}>
            <Box>
                <TextInput icon={SearchTwoTone} label="Zoeken" sx={{ my: 2, minWidth: 300 }} value={search} onChange={v => setSearch(v as string)} />
            </Box>
            <Box>
                <Button onClick={fetchTimeRegistrations} variant="text" sx={{ mb: 2, ml: 1 }}>
                    <Typography variant="caption">Vernieuwen</Typography>
                </Button>
            </Box>
        </Box>
        <Checkbox
            checked={hideReviewed}
            onChange={() => setHideReviewed(!hideReviewed)}
            title="Verberg beoordeelde uren"
        />
        {
            fetching && <DotsLoader message="- Tijd registraties worden opgehaald -" />
        }
        {
            !fetching && data.length === 0 && <Typography variant="caption">Alle ingevoerde uren zijn beoordeeld</Typography>
        }
        {
            !fetching && data.length > 0 && <TimeRegistrationsOverviewTable details={data} />
        }
    </Box>
}
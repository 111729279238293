import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { Professional, ProfessionalDetails } from "../../types/recruiter/professional";
import { RecruiterDocument } from "../../types/recruiter";
import { retrieveToken } from "../../utils/cache";

class ProfessionalsApi extends BaseApi {
    constructor() {
        super(apiRoutes.recruiter + "/professionals");
    }

    public list = () => this.fetchJson<Professional[]>();
    public get = (id: string) => this.fetchJson<Professional>(id);
    public create = (prof: ProfessionalDetails) => this.fetchJson<Professional>(undefined, "POST", prof);
    public update = (prof: Professional) => this.fetch(prof._id, "PUT", prof);
    public subset = (ids: string[]) => this.fetchJson<Professional[]>("subset?ids=" + ids.join("&ids="));
    public delete = (id: string) => this.fetch(id, "DELETE");


    public uploadDocument = async (professionalId: string, file: File) => {
        const formData = new FormData();
        formData.append("file", file);

        const document: RecruiterDocument = await fetch(apiRoutes.recruiter + "/professionals/" + professionalId + "/documents", {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + retrieveToken(),
            },
        }).then((res) => res.json());

        return document;
    };

    public deleteDocument = (professionalId: string, itemId: string) => this.fetch(`${professionalId}/documents/${itemId}`, "DELETE");
    public downloadDocument = (professionalId: string, itemId: string) => this.fetchBlob(`${professionalId}/documents/${itemId}`, "GET");
    public listDocuments = (professionalId: string) => this.fetchJson<RecruiterDocument[]>(`${professionalId}/documents`);
}

const api = new ProfessionalsApi();
export default api;
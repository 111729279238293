import { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import { UploadTwoTone } from "@mui/icons-material";
import { useAppDispatch } from "../../../../store/hooks";
import { ButtonInput, DotsLoader, EmailDocumentViewerDialog, Toolbar } from "../../../molecules";
import { RecruiterDocument, RecruiterDocumentType, RecruiterEmailDocument } from "../../../../types/recruiter";

import { showToast } from "../../../../store/slices/toastSlice";
import { RecruiterDocumentsTable } from "../../../molecules/tables/recruiter/Documents";
import { previewDocumentInBrowser } from "../../../../utils/downloadFile";

import api from "../../../../api/recruiter/professionals";

export function ProfessionalsDocumentsOrganism(props: { professionalId: string }) {
    const { professionalId } = props;
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState(false);
    const [uploadingCount, setUploadingCount] = useState(0);
    const [documents, setDocuments] = useState<RecruiterDocument[]>([]);
    const [showEmailViewer, setShowEmailViewer] = useState(false);
    const selectedEmail = useRef<RecruiterEmailDocument>();

    useEffect(() => {
        setFetching(true);
        api
            .listDocuments(professionalId)
            .then(setDocuments)
            .catch(e => dispatch(showToast({ message: e.message, type: 'error' })))
            .finally(() => setFetching(false));

    }, [professionalId, dispatch]);

    const onUpload = (file: File) => {
        setUploadingCount(uploadingCount + 1);
        api
            .uploadDocument(professionalId!, file)
            .then(doc => setDocuments(docs => [...docs, doc]))
            .catch(e => dispatch(showToast({ message: e.message, type: 'error' })))
            .finally(() => setUploadingCount(count => count - 1));
    }

    if (fetching)
        return <DotsLoader message="- Documenten worden opgehaald -" />

    const onDownload = async (id: string, filename: string) => {
        var data = await api.downloadDocument(professionalId, id);
        await previewDocumentInBrowser(data, filename);
    }

    const onViewDocument = async (document: RecruiterDocument) => {
        if (document.type === RecruiterDocumentType.FILE)
            await onDownload(document._id, document.name);

        if (document.type === RecruiterDocumentType.EMAIL) {
            selectedEmail.current = document;
            setShowEmailViewer(true);
        }
    }

    const onDelete = (id: string) => {
        api.deleteDocument(professionalId, id)
            .then(() => setDocuments(documents.filter(d => d._id !== id)))
            .catch(e => dispatch(showToast({ message: e.message, type: 'error' })));
    }

    return <Box>
        <Toolbar>
            <ButtonInput
                onChange={onUpload}
                icon={UploadTwoTone}
                placeholder="Sleep of klik om bestand te uploaden"
                variant="text"
            />
        </Toolbar>
        <EmailDocumentViewerDialog
            onDismiss={() => setShowEmailViewer(false)}
            visible={showEmailViewer}
            item={selectedEmail.current!}
        />
        <RecruiterDocumentsTable
            items={documents}
            onViewDocument={onViewDocument}
            onDelete={onDelete}
            uploadingCount={uploadingCount}
        />
    </Box>
}

import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    ClassicEditor,
    Heading,
    Bold,
    Essentials,
    Italic,
    Paragraph,
    Undo,
    Alignment,
    Image,
    Link,
    Font,
    ImageInsertViaUrl,
    ImageResize,
    ImageToolbar,
    HorizontalLine,
    FullPage
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import ToolbarLabelPlugin from './ToolbarLabelPlugin';
import {
    CredentialsLinkPlugin,
    GenericNamePlugin,
    InviteLinkPlugin,
    PwdResetLinkPlugin,
    UsernamePlugin,
    VacancyLinkPlugin,
    MatchScorePlugin,
    MatchSummaryPlugin,
    VacancyTitlePlugin,
    RelationStatusPlugin,
    MatchImprovementsPlugin,
    VacancyEndDatePlugin,
    VacancyLocationPlugin,
    VacancyStartDatePlugin,
    VacancyDescriptionPlugin
} from './EmailPlugins';

export function EmailEditorMolecule(props: {
    html: string;
    onChange: (html: string) => void;
    config: {
        withNameButton?: boolean;
        withGenericNameButton?: boolean;
        withPwdResetLinkButton?: boolean;
        withCredentialsLinkButton?: boolean;
        withInviteLinkButton?: boolean;
        withVacancyLinkButton?: boolean;

        recruiter?: {
            withRecruiterMatchingScoreButton?: boolean;
            withRecruiterMatchingSummaryButton?: boolean;
            withRecruiterMatchingImprovementsButton?: boolean;
            withVacancyTitleButton?: boolean;
            withVacancyLocationButton?: boolean;
            withVacancyDescriptionButton?: boolean;
            withVacancyStartDateButton?: boolean;
            withVacancyEndDateButton?: boolean;
            withRelationStatusButton?: boolean;
        }
    }
}) {
    const items = [
        'undo', 'redo', '|',
        'heading', '|',
        'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify', '|',
        'fontfamily', 'fontsize', 'bold', 'italic', 'horizontalLine', '|',
        'link', '|',
        'toolbarLabel'
    ];

    if (props.config.withNameButton)
        items.push('custom:name');

    if (props.config.withGenericNameButton)
        items.push('custom:genericName');

    if (props.config.withPwdResetLinkButton)
        items.push('custom:pwdResetLink');

    if (props.config.withCredentialsLinkButton)
        items.push('custom:credentialsLink');

    if (props.config.withInviteLinkButton)
        items.push('custom:inviteLink');

    if (props.config.withVacancyLinkButton)
        items.push('custom:vacancyPublishLink');


    if (props.config.recruiter?.withRecruiterMatchingScoreButton)
        items.push('custom:matchScore');

    if (props.config.recruiter?.withRecruiterMatchingSummaryButton)
        items.push('custom:matchSummary');

    if (props.config.recruiter?.withRecruiterMatchingImprovementsButton)
        items.push('custom:matchImprovements');

    if (props.config.recruiter?.withVacancyTitleButton)
        items.push('custom:vacancyTitle');

    if (props.config.recruiter?.withVacancyDescriptionButton)
        items.push('custom:vacancyDescription');

    if (props.config.recruiter?.withVacancyLocationButton)
        items.push('custom:vacancyLocation');

    if (props.config.recruiter?.withVacancyStartDateButton)
        items.push('custom:vacancyStartDate');

    if (props.config.recruiter?.withVacancyEndDateButton)
        items.push('custom:vacancyEndDate');

    if (props.config.recruiter?.withRelationStatusButton)
        items.push('custom:relationStatus');


    return (
        <CKEditor
            editor={ClassicEditor} onChange={(_, editor) => props.onChange(editor.getData())}
            config={{
                image: {
                    toolbar: ['insertImageViaUrl'],
                    resizeUnit: 'px'
                },
                toolbar: { items, shouldNotGroupWhenFull: true },
                plugins: [
                    Heading,
                    Bold,
                    Essentials,
                    Italic,
                    Paragraph,
                    Undo,
                    Alignment,
                    Link,
                    Font,
                    Image,
                    ImageToolbar,
                    ImageResize,
                    ImageInsertViaUrl,
                    HorizontalLine,

                    ToolbarLabelPlugin,
                    UsernamePlugin,
                    PwdResetLinkPlugin,
                    InviteLinkPlugin,
                    VacancyLinkPlugin,
                    CredentialsLinkPlugin,
                    GenericNamePlugin,

                    MatchScorePlugin,
                    MatchSummaryPlugin,
                    MatchImprovementsPlugin,

                    VacancyTitlePlugin,
                    VacancyDescriptionPlugin,
                    VacancyStartDatePlugin,
                    VacancyEndDatePlugin,
                    VacancyLocationPlugin,

                    RelationStatusPlugin,

                    FullPage
                ],
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1 darkBlue' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2 darkBlue' },
                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3 darkBlue' }
                    ]
                },
                initialData: props.html
            }}
        />
    );
}

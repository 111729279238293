import { ContractTimeRegistrations } from "../../types/contracts";
import { BaseApi } from "../base";
import { apiRoutes } from "../routes";

class TimeRegistrationsApi extends BaseApi {
    constructor() { super(apiRoutes.contracts + "/timeregistrations"); }

    public list = async () => await this.fetchJson<ContractTimeRegistrations[]>();
    public listByContract = async (contractId: string) => await this.fetchJson<ContractTimeRegistrations>(contractId);
    public approve = async (registrationId: string) => await this.fetch(`${registrationId}/approve`, "PUT");
    public reject = async (registrationId: string, reason: string) => await this.fetch(`${registrationId}/reject`, "PUT", reason);

}

const timeRegistrationsApi = new TimeRegistrationsApi();
export default timeRegistrationsApi;
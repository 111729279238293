import { FC } from "react"
import { Periods, Rate } from "../../../types/contracts"
import { Box, Button, SxProps } from "@mui/material"
import { AsyncAutocomplete } from "./AsyncAutocomplete"
import { RowCentered } from "../Row"
import { BusinessTwoTone, DeleteTwoTone } from "@mui/icons-material"
import { IconLabel } from "../IconLabel"
import { SelectMolecule } from "../MultiSelect"

export const FeeReceiverInput: FC<{
    title: string,
    sx?: SxProps,
    value: Rate,
    onChange?: (value: Rate) => void,
    onDelete?: () => void,
    options: { label: string, id: string }[],
    disabled?: boolean
}> = ({ title, value, onChange, options, sx, disabled, onDelete }) => {
    return <Box sx={{ ...sx }}>
        <RowCentered>
            <Box flex={1}>
                <IconLabel icon={BusinessTwoTone} label={`${title} - Ontvanger`} />
                <RowCentered>
                    <AsyncAutocomplete
                        disabled={disabled}
                        sx={{ flex: 1 }}
                        options={options}
                        getTitleFromOption={o => o.label}
                        selected={options.find(o => o.id === value?.receiverId)}
                        onSelect={o => o && onChange?.({ ...value!, receiverId: o.id })}
                    />
                    {!disabled &&
                        <Button variant="text" sx={{ minWidth: 0 }} onClick={onDelete}>
                            <DeleteTwoTone color="error" fontSize="small" />
                        </Button>
                    }
                </RowCentered>
            </Box>
        </RowCentered>
        <SelectMolecule
            sx={{ mt: 1, width: '100%' }}
            disabled={disabled}
            selected={[value?.period ?? Periods.Hour]}
            onClick={(period) => value && onChange?.({ ...value, period: period })}
            items={[
                {
                    label: 'Uur',
                    value: Periods.Hour
                },
                {
                    label: 'Dag',
                    value: Periods.Day
                },
                {
                    label: 'Week',
                    value: Periods.Week
                },
                {
                    label: 'Maand',
                    value: Periods.Month
                },
                {
                    label: 'Fixed Price',
                    value: Periods.Once,
                    flex: 1
                }
            ]}
        />
    </Box>
}
import { Theme } from "@emotion/react";
import { AbcTwoTone, AlternateEmailTwoTone, ArrowDownward, ArrowUpward, HouseTwoTone, LinkedIn, LocationOnTwoTone, Looks3TwoTone, MyLocationTwoTone, NoteAltTwoTone, PhoneTwoTone, WorkTwoTone } from "@mui/icons-material";
import { Box, Button, SxProps, Typography } from "@mui/material";

import addressApi from "../../../../api/addressApi";
import { Gender, ContractingType, Professional } from "../../../../types/recruiter/professional";
import { FormContainer, TextInput, IconLabel, Dropdown, RowCentered, TextArea } from "../../../molecules";
import { SelectMolecule } from "../../../molecules/MultiSelect";
import { requiredValidation } from "../../../validations";
import { Checkbox } from "../../../molecules/inputs/CheckboxInput";

export const onChangeText = (setter: (value: string) => void) => (v: string | number) => setter(v as string);

export function ProfessionalDetailsForm(props: {
    state: Professional,
    onChange: (data: Professional) => void,
    hideContract?: boolean,
    hideMemo?: boolean,
    sx?: SxProps<Theme>
}) {
    const { state } = props;
    const { title, email, houseNumber, name, phone, zipCode, gender, city, address, linkedIn, memo } = state;

    const fetchAddress = async (zipCode: string, houseNumber: string) => {
        await addressApi.toAddress(zipCode, houseNumber)
            .then(({ city, street }) => props.onChange({ ...state, address: street, city }))
            .catch(_ => {
                alert('Onjuiste adres gegevens. Probeer opnieuw.')
            });
    }

    return <FormContainer sx={props.sx}>
        {!props.hideContract && <Checkbox
            checked={state.isAvailable}
            title="Kandidaat is inzetbaar"
            onChange={() => props.onChange({ ...state, isAvailable: !state.isAvailable })}
        />}
        <Box display={'flex'} width={'100%'} mt={1} alignItems={'flex-start'} sx={{
            '& .MuiSelect-select': {
                padding: 0
            }
        }}>
            <TextInput sx={{ mr: 2, flex: 1 }} label="Naam"
                required
                validator={requiredValidation}
                icon={AbcTwoTone}
                onChange={onChangeText(name => {
                    props.onChange({ ...state, name });
                })} value={name} />

            <Box>
                <Box mt={1} />
                <IconLabel label="Geslacht" required />
                <Dropdown label="Geslacht" value={gender}
                    onChange={gender => props.onChange({ ...state, gender })}
                    options={[
                        { label: 'Man', value: Gender.MALE },
                        { label: 'Vrouw', value: Gender.FEMALE },
                    ]} />
            </Box>
        </Box>

        <RowCentered sx={{ mt: 2 }}>
            <TextInput sx={{ flex: 1 }}
                label="Functie" icon={WorkTwoTone}
                onChange={onChangeText(title => props.onChange({ ...state, title }))}
                value={title} />

            <TextInput sx={{ flex: 1, pl: 1 }}
                label="LinkedIn Profiel" icon={LinkedIn}
                onChange={onChangeText(linkedIn => props.onChange({ ...state, linkedIn }))}
                value={linkedIn} />
        </RowCentered>

        {!props.hideContract && <Box my={2} display={'flex'} alignItems={'center'}>
            <Box>
                <IconLabel label="Contractvorm" icon={AbcTwoTone} />
                <SelectMolecule
                    items={[
                        { label: "ZZP", value: ContractingType.ZZP },
                        { label: "Leverancier", value: ContractingType.Leverancier },
                        { label: "Tijdelijke arbeidsovereenkomst", value: ContractingType.TAO },
                    ]}
                    onClick={(item) => {
                        const newContractingTypes = state.contractingTypes.includes(item)
                            ? state.contractingTypes.filter(c => c !== item)
                            : [...state.contractingTypes, item];
                        props.onChange({ ...state, contractingTypes: newContractingTypes });
                    }}
                    selected={state.contractingTypes}
                />
            </Box>

            <TextInput
                sx={{ flex: 1, pl: 1 }} label="Prijs laag" type="double"
                icon={ArrowDownward}
                onChange={onChangeText((priceLow: any) => props.onChange({ ...state, priceLow }))}
                value={(state.priceLow as any) > 0 ? state.priceLow : ''} />

            <TextInput sx={{ flex: 1, pl: 1 }} label="Prijs hoog" type="double"
                icon={ArrowUpward}
                onChange={onChangeText((priceHigh: any) => props.onChange({ ...state, priceHigh }))}
                value={(state.priceHigh as any) > 0 ? state.priceHigh : ''} />
        </Box>}

        <Box display={'flex'} width={'100%'} mt={1}>
            <TextInput sx={{ flex: 1 }} label="Email"
                icon={AlternateEmailTwoTone}
                onChange={onChangeText(email => props.onChange({ ...state, email }))}
                value={email} />

            <Box mx={1} />
            <TextInput type="tel" label="Telefoon"
                icon={PhoneTwoTone}
                onChange={onChangeText(phone => props.onChange({ ...state, phone }))}
                value={phone} />
        </Box>

        <Box display={'flex'} width={'100%'} mt={2} alignItems={'flex-end'}>
            <TextInput sx={{ flex: 1, mr: 2 }} label="Postcode" icon={LocationOnTwoTone}
                onChange={onChangeText(zipCode => props.onChange({ ...state, zipCode }))}
                value={zipCode} />

            <TextInput label="Huisnummer"
                sx={{ flex: 1 }}
                icon={Looks3TwoTone}
                onChange={onChangeText(houseNumber => props.onChange({ ...state, houseNumber }))}
                value={houseNumber} />
        </Box>

        <Box display={'flex'} width={'100%'} mt={2} alignItems={'flex-end'}>
            <TextInput label="Adres" disabled icon={HouseTwoTone} value={
                city && address
                    ? city + ", " + address
                    : ''
            } sx={{ flex: 2 }} />

            <Button
                sx={{ flex: 1, height: 30, ml: 2 }}
                disabled={!zipCode || !houseNumber}
                onClick={() => fetchAddress(zipCode, houseNumber)} variant="outlined" color="primary">
                <MyLocationTwoTone fontSize="small" />
                <Typography sx={{ ml: 2 }} variant="caption">
                    Invullen
                </Typography>

            </Button>
        </Box>

        {!props.hideMemo && <TextArea
            icon={NoteAltTwoTone}
            sx={{ mt: 2 }}
            value={memo}
            onChange={onChangeText(memo => props.onChange({ ...state, memo }))}
            label="Memo"
            inputSx={{ minHeight: 50 }}

        />}
    </FormContainer>
}

import { FollowUpEmailTemplate } from "../../types/recruiter/followUp";
import { BaseApi } from "../base";
import { apiRoutes } from "../routes";

class FollowUpApi extends BaseApi {
    constructor() {
        super(apiRoutes.recruiter + "/followup");
    }

    public list = () => this.fetchJson<FollowUpEmailTemplate[]>();
    public get = (id: string) => this.fetchJson<FollowUpEmailTemplate>(id);
    public create = (email: FollowUpEmailTemplate) => this.fetchJson<FollowUpEmailTemplate>(undefined, "POST", email);
    public update = (email: FollowUpEmailTemplate) => this.fetchJson<FollowUpEmailTemplate>(email._id, "PUT", email);
    public delete = (id: string) => this.fetch(id, "DELETE");

    public send = (payload: {
        to: string;
        subject: string;
        body: string;
    }) => this.fetch("send-follow-up", "POST", payload);
}

const api = new FollowUpApi();
export default api;
import { FC } from "react"
import { Link } from "../../molecules/Link"
import { Box, Checkbox, SxProps, Typography, Link as MuiLink } from "@mui/material"
import { BaseContract } from "../../../types/contracts"
import { ApproverModeInput, IconLabel, TextInput } from "../../molecules"
import { BorderColorTwoTone, BusinessTwoTone, ConnectWithoutContactTwoTone, EmojiPeopleTwoTone, Filter1TwoTone, ForwardToInboxTwoTone, HandymanTwoTone, HomeTwoTone, HowToRegTwoTone, ImportContactsTwoTone, LocationOnTwoTone, ManageAccountsTwoTone, SchoolTwoTone, TransferWithinAStationTwoTone } from "@mui/icons-material"
import { primaryLighter } from "../../theme"
import { organizationRoutes, personRoutes } from "../../../router/paths"
import { RowCentered } from "../../molecules/Row"
import { indexToWord } from "../../../utils/strings"

export const ContractDetailsForm: FC<{
    contract: BaseContract,
    sx?: SxProps
}> = ({ contract, sx }) => {
    const hirerIsEndClient = contract.hirer._id === contract.endCustomer._id;

    return <Box sx={sx}>
        <Box>
            <Box mb={2}>
                <IconLabel sx={{ mt: 0, mb: 0 }} icon={ManageAccountsTwoTone} label="Contractmanager" />

                <RowCentered>
                    <MuiLink
                        sx={{ fontSize: 14 }}
                        href={`mailto:${contract.contractManager}?subject=Betreft%20Contract:%20${contract.contractnumber}&body=${window.location.href}`}>{contract.contractManager}</MuiLink>
                    <ForwardToInboxTwoTone color="primary" sx={{ ml: 1 }} fontSize="small" />
                </RowCentered>
            </Box>

            <TextInput
                icon={Filter1TwoTone}
                label="Contractnummer"
                required
                sx={{ width: 575, mb: 2 }}
                value={contract.contractnumber}
            />

            {!hirerIsEndClient && <Box flex={1} />}
        </Box>

        <Box display={'flex'} flexWrap={'wrap'}>


            <Box flex={1} p={2} sx={{ background: primaryLighter, minWidth: 300, mb: 2 }}>
                <Typography variant="overline">Inlener gegevens</Typography>

                <IconLabel sx={{ mt: 2 }} icon={EmojiPeopleTwoTone} label="Inlener" />
                <Link sx={{ fontSize: 14 }} path={organizationRoutes.details(contract.hirer._id)}>{contract.hirer.traderName}</Link>
                <Box mb={2} />

                <IconLabel sx={{ mt: 2 }} icon={ConnectWithoutContactTwoTone} label="Contactpersoon" />
                <Link sx={{ fontSize: 14 }} path={personRoutes.details(contract.contactHirer._id)}>{contract.contactHirer.name}</Link>
                <Box mb={2} />

                <IconLabel sx={{ mt: 2 }} icon={BorderColorTwoTone} label="Tekenbevoegde" />
                {
                    contract.authorizedSignatoriesHirer.map((authorizedSignatory, index) =>
                        <Box key={authorizedSignatory._id + '-authsig'} sx={{ mb: 2 }}>
                            <RowCentered sx={{ px: 1, borderRadius: 1 }}>
                                <Typography variant="body1" fontSize={12} fontWeight={'bold'} sx={{ mr: 1 }}>{indexToWord(index)}</Typography>
                                <Box sx={{ background: '#eee', borderRadius: 1, px: 1, width: '100%' }}>
                                    <Link
                                        sx={{ fontSize: 14 }}
                                        path={personRoutes.details(authorizedSignatory._id)}>{authorizedSignatory.name}</Link>
                                </Box>
                            </RowCentered>
                        </Box>
                    )
                }
                <Box mb={2} />

                <IconLabel sx={{ mt: 2 }} icon={HowToRegTwoTone} label="Urenverantwoordelijke" />
                {
                    contract.timesheetApprovers.map((approver, index) =>
                        <Box key={approver._id + 'approver'} sx={{ mb: 2 }}>
                            <RowCentered sx={{ px: 1, borderRadius: 1 }}>
                                <Typography variant="body1" fontSize={12} fontWeight={'bold'} sx={{ mr: 1 }}>{indexToWord(index)}</Typography>


                                <Box sx={{ background: '#eee', borderRadius: 1, px: 1, width: '100%' }}>
                                    <Link
                                        sx={{ fontSize: 14 }}
                                        path={personRoutes.details(approver._id)}>{approver.name}</Link>
                                </Box>
                            </RowCentered>
                        </Box>
                    )
                }


                {
                    contract.timesheetApprovers.length > 1 && <Box sx={{ mb: 2 }}>
                        <ApproverModeInput selected={contract.mode} />
                    </Box>
                }

                <TextInput sx={{ mb: 2 }} icon={ImportContactsTwoTone} label="Divisie" value={contract.division.name} disabled />
                <TextInput
                    icon={SchoolTwoTone}
                    label="Recruiter"
                    optional
                    sx={{ flex: 1, minWidth: 300, mb: 2 }}
                    value={contract.recruiter}
                />

                <Box display={'flex'} alignItems={'center'} mt={2}>
                    <Checkbox size="small" sx={{ ml: -1 }} title={'Inlener is eindklant'} checked={hirerIsEndClient} />
                    <Typography variant="overline" fontSize={10}>Inlener is eindklant</Typography>
                </Box>
            </Box>

            <Box width={16} />

            <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: 300, mb: 2 }}>
                <Typography variant="overline">Leverancier gegevens</Typography>
                <Box mb={2} />

                <IconLabel sx={{ mt: 2 }} icon={TransferWithinAStationTwoTone} label="Leverancier" />
                <Link sx={{ fontSize: 14 }} path={organizationRoutes.details(contract.supplier._id)}>{contract.supplier.traderName}</Link>
                <Box mb={2} />

                <IconLabel sx={{ mt: 2 }} icon={HandymanTwoTone} label="Professional" />
                <Link sx={{ fontSize: 14 }} path={personRoutes.details(contract.professional._id)}>{contract.professional.name}</Link>
                <Box mb={2} />

                <IconLabel sx={{ mt: 2 }} icon={BorderColorTwoTone} label="Tekenbevoegde" />
                {
                    contract.authorizedSignatoriesSupplier.map((authorizedSignatory, index) =>
                        <Box key={authorizedSignatory._id + '-authsig'} sx={{ mb: 2 }}>
                            <RowCentered sx={{ px: 1, borderRadius: 1 }}>
                                <Typography variant="body1" fontSize={12} fontWeight={'bold'} sx={{ mr: 1 }}>{indexToWord(index)}</Typography>
                                <Box sx={{ background: '#eee', borderRadius: 1, px: 1, width: '100%' }}>
                                    <Link
                                        sx={{ fontSize: 14 }}
                                        path={personRoutes.details(authorizedSignatory._id)}>{authorizedSignatory.name}</Link>
                                </Box>
                            </RowCentered>
                        </Box>
                    )
                }
                <Box mb={2} />
            </Box>

            <Box width={16} />
            {!hirerIsEndClient && <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: 300, mb: 2 }} maxWidth={400}>
                <Typography variant="overline">Eindklant</Typography>
                <TextInput sx={{ my: 2 }} icon={BusinessTwoTone} label="Eindklant" value={contract.endCustomer.traderName} disabled />
                <TextInput sx={{ mb: 2 }} icon={LocationOnTwoTone} label="Locatie" value={contract.endCustomer.address} disabled />
                <TextInput icon={HomeTwoTone} label="Vestigingsnummer" value={contract.endCustomer.locationNumber} disabled />
            </Box>}

            <Box width={16} />
        </Box>
    </Box>
}
import { ContactPersonProps } from "./contactPerson";
import { AddCompanyPayload, CredentialsPayload } from "./payloads";
import { Person } from "./person";

interface FileUploadedPayload {
    documentId: string;
    base64Payload: string;
    fileName: string;
}

interface BaseInvitePayload {
    professional?: Person;
    files: FileUploadedPayload[];
}

const inviteFormData = (payload: BaseInvitePayload): FormData => {
    const formData = new FormData();

    if (payload.professional)
        formData.append("person", JSON.stringify(payload.professional));

    if (payload.files)
        formData.append("files", JSON.stringify(payload.files));

    return formData;
}

interface BaseCompanyInvitePayload extends BaseInvitePayload {
    company: AddCompanyPayload;
}

const companyFormData = (payload: BaseCompanyInvitePayload): FormData => {
    const formData = inviteFormData(payload);

    formData.append('kvk', payload.company.kvK);
    formData.append('btw', payload.company.btw);
    formData.append('vestigingsNummer', payload.company.vestigingsNummer);
    formData.append('phone', payload.company.phone);
    formData.append('billingAddress', payload.company.billingAddress);
    formData.append('genericAddress', payload.company.genericAddress);

    if (payload.company.bankAccount && payload.company.bankAccount?.iban.length > 0)
        formData.append('bankAccount', JSON.stringify(payload.company.bankAccount));

    return formData;
}

export interface ZzpInvitePayload extends BaseCompanyInvitePayload {
    credentials: CredentialsPayload;
}

export const ZzpFormData = (payload: ZzpInvitePayload): FormData => {
    const formData = companyFormData(payload);
    formData.append("credentials", JSON.stringify(payload.credentials));
    return formData;
}

export interface InhuurWithCompanyInvitePayload extends BaseCompanyInvitePayload {
    allowProfessionalLogin: boolean;
    contacts: ContactPersonProps[];
}

export const InhuurWithCompanyFormData = (payload: InhuurWithCompanyInvitePayload): FormData => {
    const formData = companyFormData(payload);

    formData.append("allowProfessionalLogin", payload.allowProfessionalLogin.toString());

    if (payload.contacts)
        formData.append("contacts", JSON.stringify(payload.contacts));

    return formData;
}

export interface InhuurInvitePayload extends BaseInvitePayload {
    credentials?: CredentialsPayload;
}

export const InhuurFormData = (payload: InhuurInvitePayload): FormData => {
    const formData = inviteFormData(payload);

    if (payload.credentials)
        formData.append("credentials", JSON.stringify(payload.credentials));

    return formData;
}

export interface TAOInvitePayload extends InhuurInvitePayload {
    credentials: CredentialsPayload;
    iban: string;
}

export const TAOFormData = (payload: TAOInvitePayload): FormData => {
    const formData = inviteFormData(payload);

    formData.append("credentials", JSON.stringify(payload.credentials));
    formData.append("iban", payload.iban);

    return formData;
}
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import relationStatesApi from '../../../api/recruiter/relationStates';
import { RootState } from '../..';
import { FetchingState } from '../../../types/fetchingState';
import { VacancyRelationState } from '../../../types/recruiter';
import { showToast } from '../toastSlice';
import { listVacanciesRelations } from './relationsSlice';

export interface RelationStatesState {
    fetchingRelationStates: FetchingState;
    relationStates: VacancyRelationState[];
}

const initialState: RelationStatesState = {
    fetchingRelationStates: 'init',
    relationStates: [],
};

export const listRelationStates = createAsyncThunk('relationStates/listRelationStates', async (_, thunkApi) => {
    try {
        const states = await relationStatesApi.list();
        return states;
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: e.message, type: 'error' }));
        throw e;
    }
});

export const updateRelationState = createAsyncThunk('relationStates/updateRelationState', async (state: VacancyRelationState, thunkApi) => {
    try {
        await relationStatesApi.update(state);

        thunkApi.dispatch(listVacanciesRelations());

        return state;
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: 'Fout bij het bijwerken:' + e.message, type: 'error' }));
        throw e;
    }
});

export const deleteRelationState = createAsyncThunk('relationStates/deleteRelationState', async (id: string, thunkApi) => {
    try {
        await relationStatesApi.delete(id);
        return id;
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: 'Fout bij het verwijderen:' + e.message, type: 'error' }));
        throw e;
    }
});

export const createRelationState = createAsyncThunk('relationStates/createRelationState', async (state: VacancyRelationState, thunkApi) => {
    try {
        const newState = await relationStatesApi.create(state);
        return newState;
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: 'Fout bij het aanmaken:' + e.message, type: 'error' }));
        throw e;
    }
});

export const relationStatesSlice = createSlice({
    name: 'relationStates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // List Relation States
        builder
            .addCase(listRelationStates.pending, (state) => {
                state.fetchingRelationStates = 'fetching';
            })
            .addCase(listRelationStates.rejected, (state) => {
                state.fetchingRelationStates = 'error';
            })
            .addCase(listRelationStates.fulfilled, (state, action: PayloadAction<VacancyRelationState[]>) => {
                state.fetchingRelationStates = 'completed';
                state.relationStates = action.payload;
            });

        // Update Relation State
        builder
            .addCase(updateRelationState.fulfilled, (state, action: PayloadAction<VacancyRelationState>) => {
                state.fetchingRelationStates = 'completed';
                state.relationStates = state.relationStates.map((s) => (s._id === action.payload._id ? action.payload : s));
            });

        // Delete Relation State
        builder
            .addCase(deleteRelationState.pending, (state) => {
                state.fetchingRelationStates = 'fetching';
            })
            .addCase(deleteRelationState.rejected, (state) => {
                state.fetchingRelationStates = 'error';
            })
            .addCase(deleteRelationState.fulfilled, (state, action: PayloadAction<string>) => {
                state.fetchingRelationStates = 'completed';
                state.relationStates = state.relationStates.filter((s) => s._id !== action.payload);
            });

        // Create Relation State
        builder
            .addCase(createRelationState.pending, (state) => {
                state.fetchingRelationStates = 'fetching';
            })
            .addCase(createRelationState.rejected, (state) => {
                state.fetchingRelationStates = 'error';
            })
            .addCase(createRelationState.fulfilled, (state, action: PayloadAction<VacancyRelationState>) => {
                state.fetchingRelationStates = 'completed';
                state.relationStates = [action.payload, ...state.relationStates];
            });
    },
});

export const relationStatesSelector = (state: RootState) => state.recruiter.relationStates;
export default relationStatesSlice.reducer;
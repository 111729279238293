import { ButtonView, Editor, Plugin, Position, Writer } from "ckeditor5";

export const LINK_VARIABLE = "[LINK_VALUE]";
export const NAME_VARIABLE = "[NAME]";

export const VACANCY_TITLE_VARIABLE = "[VACANCY_TITLE]";
export const VACANCY_DESCRIPTION_VARIABLE = "[VACANCY_DESCRIPTION]";
export const VACANCY_LOCATION_VARIABLE = "[VACANCY_LOCATION]";
export const VACANCY_START_DATE_VARIABLE = "[VACANCY_START_DATE]";
export const VACANCY_END_DATE_VARIABLE = "[VACANCY_END_DATE]";
export const RELATION_STATUS_VARIABLE = "[RELATION_STATUS]";
export const MATCHING_SCORE_VARIABLE = "[MATCHING_SCORE]";
export const MATCHING_SUMMARY_VARIABLE = "[MATCHING_SUMMARY]";
export const MATCHING_IMPROVEMENTS_VARIABLE = "[MATCHING_IMPROVEMENTS]";

abstract class CustomPlugin extends Plugin {
    private readonly _componentName: string;
    private readonly _btnTitle: string;

    constructor(componentName: string, btnTitle: string, editor: Editor) {
        super(editor);

        this._componentName = componentName;
        this._btnTitle = btnTitle;
    }

    abstract write(writer: Writer, pos: Position): void;

    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add(this._componentName, locale => {
            const view = new ButtonView(locale);

            view.set({ label: this._btnTitle, tooltip: false, withText: true });
            view.on('execute', () => {
                editor.model.change(writer => {
                    const insertPosition = editor.model.document.selection.getFirstPosition();
                    if (!insertPosition)
                        return;

                    this.write(writer, insertPosition);
                });
            });

            return view;
        });
    }
}

class TextPlugin extends CustomPlugin {
    private readonly _text: string;

    constructor(componentName: string, title: string, text: string, editor: Editor) {
        super(componentName, title, editor);

        this._text = text;
    }

    write(writer: Writer, pos: Position) {
        writer.insertText(this._text, pos);
    }
}

class LinkPlugin extends CustomPlugin {
    private readonly _href: string;
    private readonly _title: string;

    constructor(componentName: string, btnTitle: string, href: string, title: string, editor: Editor) {
        super(componentName, btnTitle, editor);

        this._href = href;
        this._title = title;
    }

    write() {
        const viewFragment = this.editor.data.processor.toView(`<a href="${this._href}">${this._title}</a>`);
        const modelFragment = this.editor.data.toModel(viewFragment);
        this.editor.model.insertContent(modelFragment);
    }
}

export class UsernamePlugin extends TextPlugin {
    constructor(editor: Editor) {
        super('custom:name', 'Voornaam van Gebruiker', NAME_VARIABLE, editor);
    }
}

export class GenericNamePlugin extends TextPlugin {
    constructor(editor: Editor) {
        super('custom:genericName', 'Naam', NAME_VARIABLE, editor);
    }
}

export class PwdResetLinkPlugin extends LinkPlugin {
    constructor(editor: Editor) {
        super('custom:pwdResetLink', 'Wachtwoord Reset Link', LINK_VARIABLE, 'Wachtwoord herstellen', editor);
    }
}

export class CredentialsLinkPlugin extends LinkPlugin {
    constructor(editor: Editor) {
        super('custom:credentialsLink', 'Gegevens Reset Link', LINK_VARIABLE, "Inloggegevens instellen", editor);
    }
}

export class InviteLinkPlugin extends LinkPlugin {
    constructor(editor: Editor) {
        super('custom:inviteLink', 'Uitnodiging Link', LINK_VARIABLE, 'Gegevens opvoeren', editor);
    }
}

export class VacancyLinkPlugin extends LinkPlugin {
    constructor(editor: Editor) {
        super('custom:vacancyPublishLink', 'Vacature Link', LINK_VARIABLE, 'Vacature bekijken', editor);
    }
}

export class MatchScorePlugin extends TextPlugin {
    constructor(editor: Editor) {
        super('custom:matchScore', 'Score Percentage', MATCHING_SCORE_VARIABLE, editor);
    }
}

export class MatchSummaryPlugin extends TextPlugin {
    constructor(editor: Editor) {
        super('custom:matchSummary', 'Score Samenvatting', MATCHING_SUMMARY_VARIABLE, editor);
    }
}

export class MatchImprovementsPlugin extends TextPlugin {
    constructor(editor: Editor) {
        super('custom:matchImprovements', 'Score Verbeteringen', MATCHING_IMPROVEMENTS_VARIABLE, editor);
    }
}

export class VacancyTitlePlugin extends TextPlugin {
    constructor(editor: Editor) {
        super('custom:vacancyTitle', 'Vacature Titel', VACANCY_TITLE_VARIABLE, editor);
    }
}

export class RelationStatusPlugin extends TextPlugin {
    constructor(editor: Editor) {
        super('custom:relationStatus', 'Kandidaat Status', RELATION_STATUS_VARIABLE, editor);
    }
}

export class VacancyDescriptionPlugin extends TextPlugin {
    constructor(editor: Editor) {
        super('custom:vacancyDescription', 'Vacature Beschrijving', VACANCY_DESCRIPTION_VARIABLE, editor);
    }
}

export class VacancyLocationPlugin extends TextPlugin {
    constructor(editor: Editor) {
        super('custom:vacancyLocation', 'Vacature Locatie', VACANCY_LOCATION_VARIABLE, editor);
    }
}

export class VacancyStartDatePlugin extends TextPlugin {
    constructor(editor: Editor) {
        super('custom:vacancyStartDate', 'Vacature Startdatum', VACANCY_START_DATE_VARIABLE, editor);
    }
}

export class VacancyEndDatePlugin extends TextPlugin {
    constructor(editor: Editor) {
        super('custom:vacancyEndDate', 'Vacature Einddatum', VACANCY_END_DATE_VARIABLE, editor);
    }
}




export interface WorkExperience {
    title: string;
    company: string;
    startDate?: string;
    endDate?: string;
    location?: string;
    description?: string;
}

export interface Education {
    title: string;
    institution: string;
    kind?: string;
    graduationDate?: string;
}

export enum Gender {
    MALE = 1,
    FEMALE = 2
}

export enum ContractingType {
    ZZP = 0,
    Leverancier = 1,
    TAO = 2,
}

export interface ProfessionalDetails {
    contractingTypes: ContractingType[];
    name: string;
    email: string;
    isAvailable: boolean;

    zipCode: string;
    houseNumber: string;
    city: string;
    address: string;

    phone: string;
    gender: Gender;
    title: string;
    linkedIn: string;

    skills: string[];
    experiences: WorkExperience[];
    educations: Education[];
    memo: string;
    priceLow: number;
    priceHigh: number;
}

export interface Professional extends ProfessionalDetails {
    _id: string;
}

export const emptyProfessional: Professional = {
    _id: '',
    name: '',
    email: '',
    phone: '',
    linkedIn: '',
    gender: Gender.MALE,
    title: '',
    educations: [],
    experiences: [],
    houseNumber: '',
    isAvailable: true,
    zipCode: '',
    skills: [],
    address: '',
    city: '',
    contractingTypes: [ContractingType.ZZP],
    memo: '',
    priceLow: 0,
    priceHigh: 0
}

export const emptyEducation: Education = {
    title: '',
    institution: '',
    graduationDate: '',
    kind: ''
}

export const emptyExperience: WorkExperience = {
    title: '',
    company: '',
    startDate: '',
    endDate: '',
    location: '',
    description: ''
}
import { FileUploadTwoTone, HistoryEduTwoTone, RemoveRedEyeTwoTone } from "@mui/icons-material";
import { DocumentState, DocumentType } from "../types/documents";
import { Stage, StageProgress } from "../types/documents/Stages";
import { errorColor, infoColor, successColor, warningColor } from "../ui/theme";

export const getStageColor = (stage: Stage, opacity?: number) => {
    switch (stage.state) {
        case DocumentState.Completed:
            return successColor(opacity ?? 0.1);
        case DocumentState.Pending:
            return infoColor(opacity ?? 0.1);
        case DocumentState.Rejected:
            return errorColor(opacity ?? 0.1);
        case DocumentState.Requested:
            return warningColor(opacity ?? 0.1);
    }
}

export const getStageStepDescription = (stage: Stage) => {
    switch (stage.type) {
        case DocumentType.DocuSign:
            return "Contract";
        case DocumentType.FileUpload:
            return "Bestanden";
        case DocumentType.Form:
            return "Invullen";
    }
}

export const getStageIcon = (stage: Stage) => {
    switch (stage.type) {
        case DocumentType.DocuSign:
            return HistoryEduTwoTone;
        case DocumentType.FileUpload:
            return FileUploadTwoTone;
        case DocumentType.Form:
            return RemoveRedEyeTwoTone;
    }
}

export const getStageTooltip = (stage: Stage) => {
    switch (stage.type) {
        case DocumentType.DocuSign:
            switch (stage.state) {
                case DocumentState.Completed:
                    return "Contract getekend";
                case DocumentState.Pending:
                    return "Ter ondertekening bij: " + stage.processedBy;
                case DocumentState.Rejected:
                    return "Ondertekenverzoek afgewezen door: " + stage.processedBy;
                case DocumentState.Requested:
                    return "Contract aangemaakt";
            }
            break;
        case DocumentType.FileUpload:
            switch (stage.state) {
                case DocumentState.Pending:
                    return `${stage.documentName}: aangeleverd en ligt ter goedkeuring`;
                case DocumentState.Completed:
                    return `${stage.documentName}: goedgekeurd`;
                case DocumentState.Rejected:
                    return `${stage.documentName}: afgekeurd`;
                case DocumentState.Requested:
                    return `${stage.documentName}: nog niet aangeleverd`;
            }
            break;
        case DocumentType.Form:
            return "Invullen";
    }
}

export const stageValueGetter = (progress?: StageProgress) => {
    let count = 0;
    if (!progress) return 100;
    if (progress.stages.every(s => s.state === DocumentState.Completed)) return 100;

    for (const stage of progress.stages) {
        switch (stage.state) {
            case DocumentState.Completed:
                count += 3;
                break;
            case DocumentState.Pending:
                return count += 2;

            case DocumentState.Rejected:
                return count += 1;
        }
    }

    return count;
}
import { useNavigate, useParams } from 'react-router-dom';
import { InviteType, PersonInvite } from '../../../types/company/invite';
import { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { personRoutes } from '../../../router/paths';
import { TAOInvitePayload, ZzpInvitePayload, InhuurInvitePayload, InhuurWithCompanyInvitePayload } from '../../../types/company/invitePayloads';
import { InhuurInviteForm, InhuurWithCompanyInviteForm, TAOInviteForm, ZzpInviteForm } from '../../forms/invites';
import { CootjeTitle } from '../../molecules';

import inviteApi from '../../../api/organization/invite';
import documentsApi from '../../../api/documents/requested';
import { useAppDispatch } from '../../../store/hooks';
import { setTutorial } from '../../../store/slices/heroTutorialSlice';
import { RequestedDocument } from '../../../types/documents/Documents';
import { fileFoBase64 } from '../../../utils/fileToBase64';

export function PersonInviteFormOrganism() {
    const [invite, setInvite] = useState<PersonInvite>();
    const [requestedDocs, setRequestedDocs] = useState<RequestedDocument[]>([]);
    const [fetching, setFetching] = useState(true);
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useAppDispatch();

    const env = params.environment!;
    const code = params.code!;

    useEffect(() => {
        inviteApi
            .getInvite(env, code)
            .then(setInvite)
            .catch(() => { })
            .then(() => setFetching(false));

    }, [code, env, navigate]);

    useEffect(() => {
        if (!invite || !env) return;

        documentsApi
            .listByInviteCode(env!, code)
            .then(setRequestedDocs);

    }, [code, env, invite])

    if (!invite && fetching) return <Box sx={{ pl: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography sx={{ my: 4 }}>Gegevens worden opgehaald.</Typography>
        <CircularProgress />
    </Box>

    if (!invite && !fetching) return <Box sx={{ pl: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h6' color={'secondary'} sx={{ my: 4 }}>Deze link is verlopen</Typography>
    </Box>

    const onFormCompleted = async (cb: () => Promise<void>) => {
        setFetching(true);
        try {
            await cb();
            navigate(personRoutes.registered);
            dispatch(setTutorial({ sections: [] }));
        } catch (e: any) {
            alert("Fout opgetreden: " + e.message);
        } finally {
            setFetching(false);
        }

    };

    const mapFiles = async (files: (File | undefined)[]) => {
        var payloads = [];

        for (let i = 0; i < invite!.requestedFiles.length; i++) {
            payloads.push({
                documentId: invite!.requestedFiles[i],
                fileName: files[i]?.name ?? '',
                base64Payload: files[i] ? await fileFoBase64(files[i]!) : ''
            })
        }

        return payloads;
    };

    const onInhuurWithCompanyCompleted = async (details: InhuurWithCompanyInvitePayload, files: (File | undefined)[]) => onFormCompleted(
        async () => {
            details.files = await mapFiles(files);
            return inviteApi.postInhuurWithCompanyRegisterForm(env, code, details)
        }
    );

    const onZzpCompleted = async (details: ZzpInvitePayload, files: (File | undefined)[]) => onFormCompleted(
        async () => {
            details.files = await mapFiles(files);
            return inviteApi.postZzpRegisterForm(env, code, details)
        });

    const onInhuurCompleted = async (details: InhuurInvitePayload, files: (File | undefined)[]) => onFormCompleted(
        async () => {
            details.files = await mapFiles(files);
            return inviteApi.postInhuurRegisterForm(env, code, details);
        });

    const onTAOCompleted = async (details: TAOInvitePayload, files: (File | undefined)[]) => onFormCompleted(
        async () => {
            details.files = await mapFiles(files);
            return inviteApi.postTAORegisterForm(env, code, details);
        });

    if (invite!.type === InviteType.ZZP)
        return <>
            <CootjeTitle title="ZZP Formulier" />
            <Box sx={{ pt: 3 }} />
            <ZzpInviteForm loading={fetching}
                initialEmail={invite?.email}
                onCompleted={onZzpCompleted}
                onboardingItems={requestedDocs}
            />
        </>

    if (invite!.type === InviteType.InhuurWithCompany)
        return <>
            <CootjeTitle title="Organisatie Formulier" />
            <Box sx={{ pt: 3 }} />
            <InhuurWithCompanyInviteForm
                loading={fetching}
                onCompleted={onInhuurWithCompanyCompleted}
                initialEmail={invite?.email}
                onboardingItems={requestedDocs}
            />
        </>

    if (invite!.type === InviteType.Inhuur)
        return <>
            <CootjeTitle title="Inhuur Derde Formulier" />
            <Box sx={{ pt: 3 }} /><InhuurInviteForm loading={fetching} onCompleted={onInhuurCompleted}
                initialEmail={invite?.email}
                withCredentials={invite?.registerCredentials}
                onboardingItems={requestedDocs}
            />
        </>

    if (invite!.type === InviteType.TAO)
        return <>
            <CootjeTitle title="TAO Formulier" />
            <Box sx={{ pt: 3 }} /><TAOInviteForm
                loading={fetching}
                onCompleted={onTAOCompleted}
                initialEmail={invite?.email}
                onboardingItems={requestedDocs}
            />
        </>

    return <h1>Not implemented.</h1>
}

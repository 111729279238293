import { EmailTwoTone, FileOpenTwoTone } from "@mui/icons-material";
import { RecruiterDocumentType } from "../types/recruiter";


export const recruiterDocumentTypeIcon = (type: RecruiterDocumentType) => {
    switch (type) {
        case RecruiterDocumentType.EMAIL: return EmailTwoTone;
        case RecruiterDocumentType.FILE: return FileOpenTwoTone;
    }
}

export const recruiterDocumentTypeString = (type: RecruiterDocumentType) => {
    switch (type) {
        case RecruiterDocumentType.EMAIL: return 'Email';
        case RecruiterDocumentType.FILE: return 'Bestand';
    }
}
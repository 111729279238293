import { Typography } from "@mui/material";
import { Professional, Vacancy } from "../../../../types/recruiter";
import { sanitizeIsoStringToDate } from "../../../../utils/strings";

export const professionalPriceColumn = (professionalGetterFromRow: (row: any) => Professional) =>
({
    width: 120,
    headerName: "Prijs",
    valueGetter: (row: any) => {
        const prof = professionalGetterFromRow(row);
        if (prof.priceLow === 0 && prof.priceHigh === 0)
            return 0;

        if (prof.priceHigh === 0)
            return prof.priceLow;

        if (prof.priceLow === 0)
            return prof.priceHigh;
    },
    cellRenderer: (row: any) => {
        if (professionalGetterFromRow(row).priceLow === 0 && professionalGetterFromRow(row).priceHigh === 0)
            return null;

        let valueString = professionalGetterFromRow(row).priceLow.toString();
        if (professionalGetterFromRow(row).priceHigh > 0) {
            if (professionalGetterFromRow(row).priceLow > 0)
                valueString += ` - ${professionalGetterFromRow(row).priceHigh}`;
            else
                valueString = professionalGetterFromRow(row).priceHigh.toString();
        }

        return <Typography variant="caption" color="secondary">€ {
            valueString
        }</Typography>
    }
})

export const vacancyExpiryDateColumn = (vacancyGetterFromRow: (row: any) => Vacancy) => ({
    field: "closureDate",
    headerName: "Sluitingsdatum",
    width: 150,
    valueGetter: (row: any) => vacancyGetterFromRow(row).closureDateTime,
    cellRenderer: (row: any) => sanitizeIsoStringToDate(vacancyGetterFromRow(row).closureDateTime)
});
import { ColumnProps, Table } from "../Table";
import { PreviewButton } from "../../Buttons";
import { recruiterDocumentTypeIcon, recruiterDocumentTypeString } from "../../../../utils/recuiterDocuments";
import { RowCentered } from "../../Row";
import { RecruiterDocument } from "../../../../types/recruiter";
import { ContextMenuMore } from "../../ContextMenu";
import { Skeleton } from "@mui/material";

export function RecruiterDocumentsTable(props: {
    items: RecruiterDocument[];
    onViewDocument?: (id: RecruiterDocument) => void;
    onDelete?: (id: string) => void;
    uploadingCount?: number;
}) {
    const skeletonLoader = <Skeleton variant="rectangular" height={30} sx={{ mt: 1, borderRadius: 1 }} />;
    const isLoader = (row: { data: RecruiterDocument }) => row.data._id?.startsWith("uploading-");

    const colDefs: ColumnProps<RecruiterDocument>[] = [
        {
            width: 50,
            cellRenderer: (row) => !isLoader(row)
                ? <ContextMenuMore
                    buttons={[
                        {
                            label: "Verwijderen",
                            color: "error",
                            onClick: () => props.onDelete?.(row.data._id)
                        }
                    ]} />
                : <></>
        },
        {
            headerName: "Bekijk",
            width: 150,
            cellRenderer: (row) => !isLoader(row)
                ? <PreviewButton onClick={() => props.onViewDocument?.(row.data)} />
                : skeletonLoader
        },
        {
            headerName: "Soort",
            width: 170,
            cellRenderer: (row) => {
                if (isLoader(row))
                    return skeletonLoader;

                const Icon = recruiterDocumentTypeIcon(row.data.type);
                return <RowCentered>
                    <Icon color="primary" sx={{ mr: 2 }} />
                    {recruiterDocumentTypeString(row.data.type)}
                </RowCentered>
            }
        },
        {
            field: "name",
            headerName: "Titel",
            cellRenderer: (row) => isLoader(row) ? skeletonLoader : row.data.name
        }
    ];

    const loaders: any[] = Array.from({ length: props.uploadingCount ?? 0 }, (_, i) => ({
        _id: `uploading-${i}`
    }));

    return <Table id="DocListItems" columns={colDefs} data={
        props.items.concat(loaders)
    } paging />
}
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { FetchingState } from '../../../types/fetchingState';
import { showToast } from '../toastSlice';
import { FollowUpEmailTemplate } from '../../../types/recruiter/followUp';
import followUpTemplatesApi from '../../../api/recruiter/followUp';

export interface FollowUpTemplatesState {
    fetchingList: FetchingState;
    fetchingCreate: FetchingState;
    followUpTemplates: FollowUpEmailTemplate[];
}

const initialState: FollowUpTemplatesState = {
    fetchingList: 'init',
    fetchingCreate: 'init',
    followUpTemplates: [],
};

export const listFollowUpTemplates = createAsyncThunk('followUpTemplates/listFollowUpTemplates', async (_, thunkApi) => {
    try {
        const templates = await followUpTemplatesApi.list();
        return templates;
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: e.message, type: 'error' }));
        throw e;
    }
});

export const updateFollowUpTemplate = createAsyncThunk('followUpTemplates/updateFollowUpTemplate', async (template: FollowUpEmailTemplate, thunkApi) => {
    try {
        await followUpTemplatesApi.update(template);
        thunkApi.dispatch(showToast({ message: 'Follow-up is bijgewerkt', type: 'success' }));
        return template;
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: 'Fout bij het bijwerken:' + e.message, type: 'error' }));
        throw e;
    }
});

export const deleteFollowUpTemplate = createAsyncThunk('followUpTemplates/deleteFollowUpTemplate', async (id: string, thunkApi) => {
    try {
        await followUpTemplatesApi.delete(id);
        return id;
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: 'Fout bij het verwijderen:' + e.message, type: 'error' }));
        throw e;
    }
});

export const createFollowUpTemplate = createAsyncThunk('followUpTemplates/createFollowUpTemplate', async (template: FollowUpEmailTemplate, thunkApi) => {
    try {
        const newTemplate = await followUpTemplatesApi.create(template);

        thunkApi.dispatch(showToast({ message: 'Follow-up is aangemaakt', type: 'success' }));

        return newTemplate;
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: 'Fout bij het aanmaken:' + e.message, type: 'error' }));
        throw e;
    }
});

export const followUpTemplatesSlice = createSlice({
    name: 'followUpTemplates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // List Follow Up Templates
        builder
            .addCase(listFollowUpTemplates.pending, (state) => {
                state.fetchingList = 'fetching';
            })
            .addCase(listFollowUpTemplates.rejected, (state) => {
                state.fetchingList = 'error';
            })
            .addCase(listFollowUpTemplates.fulfilled, (state, action: PayloadAction<FollowUpEmailTemplate[]>) => {
                state.fetchingList = 'completed';
                state.followUpTemplates = action.payload;
            });

        // Update Follow Up Template
        builder
            .addCase(updateFollowUpTemplate.pending, (state) => {
                state.fetchingCreate = 'fetching';
            })
            .addCase(updateFollowUpTemplate.rejected, (state) => {
                state.fetchingCreate = 'error';
            })
            .addCase(updateFollowUpTemplate.fulfilled, (state, action: PayloadAction<FollowUpEmailTemplate>) => {
                state.fetchingCreate = 'completed';
                state.followUpTemplates = state.followUpTemplates.map((t) => (t._id === action.payload._id ? action.payload : t));
            });

        // Delete Follow Up Template
        builder
            .addCase(deleteFollowUpTemplate.pending, (state) => {
                state.fetchingList = 'fetching';
            })
            .addCase(deleteFollowUpTemplate.rejected, (state) => {
                state.fetchingList = 'error';
            })
            .addCase(deleteFollowUpTemplate.fulfilled, (state, action: PayloadAction<string>) => {
                state.fetchingList = 'completed';
                state.followUpTemplates = state.followUpTemplates.filter((t) => t._id !== action.payload);
            });

        // Create Follow Up Template
        builder
            .addCase(createFollowUpTemplate.pending, (state) => {
                state.fetchingCreate = 'fetching';
            })
            .addCase(createFollowUpTemplate.rejected, (state) => {
                state.fetchingCreate = 'error';
            })
            .addCase(createFollowUpTemplate.fulfilled, (state, action: PayloadAction<FollowUpEmailTemplate>) => {
                state.fetchingCreate = 'completed';
                state.followUpTemplates = [action.payload, ...state.followUpTemplates];
            });
    },
});

export const followUpTemplatesSelector = (state: RootState) => state.recruiter.followUpTemplates;
export default followUpTemplatesSlice.reducer;
import { Box, Tooltip, Typography } from "@mui/material"
import { DocumentState, SignatureRequest, FileUploadRequest } from "../../types/documents"
import { warningColor, errorColor, successColor } from "../theme"
import { ApprovalState, Periods, TimeRegistration, TimeRegistrationState, TimesheetApproversMode } from "../../types/contracts"
import { FC } from "react"
import { calculateApprovalState } from "../../utils/timesheets"
import { BlockTwoTone, CheckTwoTone, QuestionMarkTwoTone } from "@mui/icons-material"
import { RowCentered } from "./Row"


export const FileUploadStatusLabel = (row: { item: FileUploadRequest }) => <Box>
    {row.item.state === DocumentState.Requested && <Typography sx={{ borderRadius: 2, px: 2, py: 1, background: warningColor(0.1) }} variant="overline" color={warningColor(1)}>Nog niet aangeleverd</Typography>}
    {row.item.state === DocumentState.Completed && <Typography sx={{ borderRadius: 2, px: 2, py: 1, background: successColor(0.1) }} variant="overline" color={successColor(1)}>Goedgekeurd</Typography>}
    {row.item.state === DocumentState.Rejected && <Typography sx={{ borderRadius: 2, px: 2, py: 1, background: errorColor(0.1) }} variant="overline" color={errorColor(1)}>Afgekeurd</Typography>}
</Box>

export const DocuSignStatusLabel = (row: { item: SignatureRequest }) => <Box>
    {row.item.state === DocumentState.Pending &&
        <>
            <Typography sx={{ borderRadius: 2, px: 2, py: 1, background: warningColor(0.1) }} variant="overline" color={warningColor(1)}>Ter ondertekening</Typography>
            <Typography sx={{ borderRadius: 2, px: 2, py: 1, ml: 1, background: '#eee' }} variant="overline" color={'warning'}>{row.item.awaitingSignatureFrom}</Typography>
        </>
    }
    {row.item.state === DocumentState.Rejected &&
        <>
            <Typography sx={{ borderRadius: 2, px: 2, py: 1, background: errorColor(0.1) }} variant="overline" color={errorColor(1)}>Afgewezen</Typography>
            <Typography sx={{ borderRadius: 2, px: 2, py: 1, ml: 1, background: '#eee' }} variant="overline" color={'warning'}>{row.item.awaitingSignatureFrom}</Typography>
        </>
    }
    {row.item.state === DocumentState.Completed && <Typography sx={{ borderRadius: 2, px: 2, py: 1, background: successColor(0.1) }} variant="overline" color={successColor(1)}>Ondertekend</Typography>}
</Box>

export const ApprovalStateStatusLabel: FC<{
    mode: TimesheetApproversMode,
    states: ApprovalState[],
    amount?: number
}> = ({
    mode,
    states,
    amount
}) => {
        const state = calculateApprovalState(mode, states);
        const data: {
            icon: React.ElementType,
            color: (opacity: number) => string,
            tooltip: string | undefined
        } = {
            icon: QuestionMarkTwoTone,
            color: warningColor,
            tooltip: undefined
        };

        switch (state) {
            case TimeRegistrationState.Pending:
                data.icon = QuestionMarkTwoTone;
                data.color = warningColor;
                data.tooltip = 'In afwachting van goedkeuring van ' + states
                    .filter(s => s.state === TimeRegistrationState.Pending)
                    .map(s => s.approverName)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .join(', ');
                break;

            case TimeRegistrationState.Rejected:
                data.icon = BlockTwoTone;
                data.color = errorColor;
                data.tooltip = 'Afgekeurd door: ' + states
                    .filter(s => s.state === TimeRegistrationState.Rejected).map(s => s.approverName)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .join(', ');
                break;

            case TimeRegistrationState.Approved:
                data.icon = CheckTwoTone;
                data.color = successColor;
                data.tooltip = 'Goedgekeurd door: ' + states.filter(s => s.state === TimeRegistrationState.Approved).map(s => s.approverName)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .join(', ');
                break;
        }

        return <Tooltip title={data.tooltip}>
            <RowCentered sx={{ height: 30, width: 60, background: data.color(0.1), justifyContent: 'center', borderRadius: 1, mr: 1 }}>
                <data.icon fontSize="small" htmlColor={data.color(1)} />
                {
                    amount !== undefined &&
                    <Typography variant="overline" color={data.color(1)} sx={{ ml: .5 }}>({amount})</Typography>
                }
            </RowCentered>
        </Tooltip>
    }


export const ApprovalStateRow: FC<{
    approvedRegistrations: TimeRegistration[],
    rejectedRegistrations: TimeRegistration[],
    pendingRegistrations: TimeRegistration[]
    viewMode: Periods,
    mode: TimesheetApproversMode
}> = ({
    approvedRegistrations,
    rejectedRegistrations,
    pendingRegistrations,
    viewMode,
    mode
}) => {
        return <RowCentered sx={{ mt: 1 }}>
            {
                rejectedRegistrations.length > 0 && (
                    <ApprovalStateStatusLabel
                        amount={viewMode !== Periods.Day ? rejectedRegistrations.length : undefined}
                        mode={mode}
                        states={rejectedRegistrations.map((x: TimeRegistration) => x.approvalStates).flat()}
                    />
                )
            }
            {
                approvedRegistrations.length > 0 && (
                    <ApprovalStateStatusLabel
                        amount={viewMode !== Periods.Day ? approvedRegistrations.length : undefined}
                        mode={mode}
                        states={approvedRegistrations.map((x: TimeRegistration) => x.approvalStates).flat()}
                    />
                )
            }
            {
                pendingRegistrations.length > 0 && (
                    <ApprovalStateStatusLabel
                        amount={viewMode !== Periods.Day ? pendingRegistrations.length : undefined}
                        mode={mode}
                        states={pendingRegistrations.map((x: TimeRegistration) => x.approvalStates).flat()}
                    />
                )
            }
        </RowCentered>
    }
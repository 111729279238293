
import { Link } from "@mui/material";
import { ColumnProps, Table } from "../Table";
import { RelationButton } from "../../Buttons";
import { Company } from "../../../../types/company/company";
import { useNavigate } from "react-router-dom";
import { organizationRoutes } from "../../../../router/paths";

type RowData = {
    company: Company;
    isMember: boolean;
}

export function CategoryCompaniesTable({
    companies, members, processing, onApply, onRemove
}: {
    companies: Company[];
    members: string[];
    processing: string[];

    onApply: (id: string) => void;
    onRemove: (id: string) => void;
}) {
    const nav = useNavigate();
    const data: RowData[] = companies.map(company => ({
        company,
        isMember: members.some(member => member === company._id)
    }))

    const columns: ColumnProps<RowData>[] = [
        {
            valueGetter: (params) => params.data.isMember ? 1 : 0,
            headerName: "Koppelen",
            sort: 'desc',
            width: 200,
            cellRenderer: (row: { data: RowData }) => <RelationButton
                loading={processing.some(x => x === row.data.company._id)}
                onAdd={() => onApply(row.data.company._id)}
                onRemove={() => onRemove(row.data.company._id)}
                relation={row.data.isMember}
            />
        },
        {
            headerName: "Naam",
            valueGetter: (params) => params.data.company.naam,
            cellRenderer: (row: { data: RowData }) => <Link onClick={() => nav(organizationRoutes.details(row.data.company._id))}>{row.data.company.naam}</Link>,
        },
        { headerName: "Vestigingsnummer", field: "company.vestigingsNummer" }
    ]

    return <Table id="CategoryCompaniesTable" data={data} columns={columns} />
}
import { Box, Card } from "@mui/material";
import { storeTheme } from "../../../utils/cache";
import api from '../../../api/organization/whitelabel';
import { useAppDispatch } from "../../../store/hooks";
import { showToast } from "../../../store/slices/toastSlice";
import { useEffect, useState } from "react";
import { WhitelabelTheme } from "../../../types/company";
import { FileInput, IconLabel } from "../../molecules";
import { PaletteTwoTone } from "@mui/icons-material";

const colors = [
    "#2aa3b9",
    '#1abc9c',
    '#16a085',
    '#2ecc71',
    '#27ae60',
    '#3498db',
    '#2980b9',
    '#9b59b6',
    '#8e44ad',
    '#34495e',
    '#2c3e50',
    '#95a5a6',
    '#7f8c8d',
    '#ecf0f1',
    '#bdc3c7',
    '#e74c3c',
    '#c0392b',
    '#e67e22',
    '#d35400',
    '#f1c40f',
    '#f39c12'
];

export function ThemeOrganism() {
    const dispatch = useAppDispatch();
    const [theme, setTheme] = useState<WhitelabelTheme>({
        imageUri: '',
        themeColor: ''
    });

    const fetchTheme = () => {
        api.getThemeSettings()
            .then(setTheme)
            .catch((e: any) => dispatch(showToast({ message: e.message, type: 'error' })))
    }

    const onThemeChange = (color: string) => () => {
        if (theme.themeColor === color)
            return;

        api.updateThemeColor(color)
            .then(() => storeTheme(color))
            .then(() => window.location.reload())
            .catch((e: any) => dispatch(showToast({ message: e.message, type: 'error' })));
    }

    const onLogoChange = (file: File) => {
        api
            .updateThemeLogo(file)
            .then(imageUri => {
                setTheme({ ...theme, imageUri });
                storeTheme(theme.themeColor);
                window.location.reload();
            })
            .catch((e: any) => dispatch(showToast({ message: e.message, type: 'error' })));
    }

    useEffect(fetchTheme, [dispatch]);

    return <Box>
        <IconLabel icon={PaletteTwoTone} label="Bedrijf Logo" sx={{
            pt: 2
        }} />
        {theme.imageUri && <img src={theme.imageUri} alt="logo" style={{ maxWidth: 400 }} />}
        <FileInput
            sx={{ mt: 2, maxWidth: 400 }}
            fileTypes={['png', 'jpg', 'jpeg']}
            onChange={onLogoChange}
        />

        <IconLabel icon={PaletteTwoTone} label="Thema" sx={{ pb: 2, pt: 4 }} />
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {
                colors.map((color) => <Card
                    onClick={onThemeChange(color)}
                    elevation={theme.themeColor === color ? 10 : 0}
                    key={color}
                    sx={{
                        cursor: 'pointer',
                        border: theme.themeColor === color ? '4px solid #000' : undefined, width: '100px', height: '100px', background: color, borderRadius: 4, m: 2
                    }}
                />)
            }
        </Box>
    </Box>
}
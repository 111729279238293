import { MatchScore } from "../../types/recruiter/matchScore";
import { VacancyRelation, VacancyRelationState } from "../../types/recruiter/relations";
import { BaseApi } from "../base";
import { apiRoutes } from "../routes";

class RelationsApi extends BaseApi {
    constructor() {
        super(apiRoutes.recruiter + "/relations");
    }

    public create = (relation: VacancyRelation) => this.fetchJson<VacancyRelation>('', 'POST', relation);

    public getProfessionalScores = (id: string) => this.fetchJson<MatchScore[]>(`professional/${id}/scores`);
    public getVacancyScores = (id: string) => this.fetchJson<MatchScore[]>(`vacancy/${id}/scores`);

    public getScores = async (relations: {
        vacancyId: string;
        professionalId: string;
    }[]) => {
        var values = relations.map(rel => ({ vacancyId: rel.vacancyId, professionalId: rel.professionalId }));
        return await this.fetchJson<MatchScore[]>(`scores`, "POST", values);
    }
    public getByVacancies = (ids: string[]) => this.fetchJson<VacancyRelation[]>(`vacancies?ids=${ids.join("&ids=")}`);
    public getByVacancy = (id: string) => this.fetchJson<VacancyRelation[]>(`vacancy/${id}`);
    public getByProfessional = (id: string) => this.fetchJson<VacancyRelation[]>(`professional/${id}`);

    public updateStatus = (id: string, status: VacancyRelationState) => this.fetch(`${id}/status`, 'PUT', status._id);
    public updateComment = (id: string, comment: string) => this.fetch(`${id}/comment`, 'PUT', comment);
    public delete = (id: string) => this.fetch(id, 'DELETE');

}

const api = new RelationsApi();
export default api;
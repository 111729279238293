import { useNavigate } from "react-router-dom";
import { PublishSettings, Vacancy } from "../../../../types/recruiter/vacancy";
import { ColumnProps, Table } from "../Table";
import { VacancyRelation } from "../../../../types/recruiter/relations";
import { CustomCellRendererProps } from "ag-grid-react";
import { PublishedStateIndicator } from "../../PublishedStateIndicator";
import { sanitizeIsoStringToDate } from "../../../../utils/strings";
import moment from 'moment';
import { Link } from "@mui/material";
import { recruiterRoutes } from "../../../../router/paths";
import { Checkbox } from "../../inputs/CheckboxInput";
import { useState } from "react";

type RowData = {
    id: string;
    title: string;
    company: string;
    relations: number;
    reference: string;
    status?: PublishSettings;
    closureDate: number;
};

export function VacanciesTable(props: {
    vacancies: Vacancy[],
    relations: VacancyRelation[],
    statusses: PublishSettings[]
}) {
    const navigate = useNavigate();
    const [showClosedVacancies, setShowClosedVacancies] = useState(false);

    const data: RowData[] = props.vacancies
        .filter(v => showClosedVacancies || new Date(v.closureDateTime).getTime() > Date.now())
        .map(v => {
            const relations = props.relations.filter(r => r.vacancyId === v._id);
            const status = props.statusses.find(s => s._id === v._id);

            return {
                id: v._id,
                title: v.title,
                company: v.company,
                relations: relations.length,
                reference: v.reference ?? "",
                status,
                closureDate: new Date(v.closureDateTime).getTime()
            } as RowData;
        });


    const StatusRenderer = (props: CustomCellRendererProps<RowData>) => <PublishedStateIndicator status={props.data?.status} />
    const colDefs: ColumnProps<RowData>[] = [
        { cellRenderer: StatusRenderer, minWidth: 40, width: 40 },
        {
            field: "title", headerName: "Titel", minWidth: 300,
            cellRenderer: (row: { data: RowData }) => <Link onClick={() => navigate(recruiterRoutes.vacatures.details(row.data.id))}>{row.data.title}</Link>,
        },
        { field: "company", headerName: "Klant", width: 250 },
        { field: "reference", headerName: "Referentie", width: 150 },
        {
            field: 'relations',
            width: 120,
            valueGetter: (params) => params.data.relations,
            headerName: "Professionals"
        },
        {
            field: "closureDate",
            sort: 'asc',
            headerName: "Sluitingsdatum",
            width: 150,
            valueGetter: (params) => params.data.closureDate,
            cellRenderer: (props) => sanitizeIsoStringToDate(props.data.closureDate)
        },
        {
            width: 300,
            flex: 1,
            valueGetter: (params) =>
                moment(params.data.closureDate).locale('nl').fromNow(),

        },
    ];

    return <>
        <Checkbox checked={showClosedVacancies}
            onChange={() => setShowClosedVacancies(!showClosedVacancies)}
            title="Toon Gesloten Vacatures"
        />
        <Table id="Vac" slim columns={colDefs} data={data} paging />
    </>
}

import moment from 'moment';

export const sanitizeUrl = (url: string, path?: string): string => {
    const uriWithoutLastSlash = url.endsWith('/') ? url.slice(0, -1) : url;
    const pathWithoutFirstSlash = path?.startsWith('/') ? path.slice(1) : path;

    if (path)
        return `${uriWithoutLastSlash}/${pathWithoutFirstSlash}`;

    return uriWithoutLastSlash;
}

export const capitalizeFirstLetter = (value: string) => {
    if (value.length === 0) return value;

    return value.charAt(0).toUpperCase() + value.slice(1);
}

export const sanitizeIsoStringToDate = (isoString?: string): string => isoString ? moment(isoString).format('DD-MM-YYYY') : '';

export const ellipsis = (text: string, maxLength: number): string => text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

export const containsIgnoreCase = (str: string, substr: string): boolean => {
    return str?.toLowerCase().includes(substr.toLowerCase());
};

export const isValidGuid = (value: string) => {
    const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return guidPattern.test(value);
}

export const generateGuid = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.floor(Math.random() * 16);
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export const isStringType = (value: any): boolean => typeof value === 'string';

export const parseAddress = (address: string): {
    street?: string;
    houseNumber?: string;
    zipcode?: string;
    city?: string;
} => {
    if (!address || address.length === 0) return {};

    let street = '';
    let houseNumber = '';
    let zipcode = '';
    let city = '';

    // Regular expression to match Dutch zipcode
    const zipcodeRegex = /([1-9][0-9]{3}\s?[A-Z]{2})/i;
    const zipcodeMatch = address.match(zipcodeRegex);
    if (zipcodeMatch) {
        zipcode = zipcodeMatch[1].toUpperCase().replace(/\s+/, '');
        // Remove the zipcode from the address string
        address = address.replace(zipcodeRegex, '').trim();
    }

    // Split the remaining address into words
    let addressParts = address.split(/\s+/);

    // Regular expression to identify house numbers
    const houseNumberRegex = /^[0-9]+[a-zA-Z]?$/;

    // Initialize index to the last word in the addressParts array
    let index = addressParts.length - 1;
    let cityParts = [];

    // Collect city parts from the end until a house number is found
    while (index >= 0 && !houseNumberRegex.test(addressParts[index])) {
        cityParts.unshift(addressParts[index]);
        index--;
    }

    // Combine city parts into a single string
    city = cityParts.join(' ');

    // If a house number is found, extract it
    if (index >= 0 && houseNumberRegex.test(addressParts[index])) {
        houseNumber = addressParts[index];
        index--;
    }

    // The remaining words constitute the street name
    street = addressParts.slice(0, index + 1).join(' ');

    return { street, houseNumber, zipcode, city };
}

export const onlyAllowUnderscores = (value: string): string => value.replace(/[^a-zA-Z0-9_]/g, '');

export const indexToWord = (index: number): string => `${index + 1}e`;

export const cleanLinkedInUri = (uri: string): string => {
    let cleanUrl = uri.toLocaleLowerCase().replace("https://", "");
    if (cleanUrl.endsWith("/"))
        cleanUrl = cleanUrl.slice(0, -1);

    return cleanUrl;
}

export const removeLeadingCharacters = (value: string, character: string): string => {
    if (!character) return value; // Handle empty character string

    // Escape special RegEx characters in 'character' to avoid unintended behavior
    const escapedChar = character.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Create a RegEx pattern to match one or more leading 'character' strings
    const regex = new RegExp(`^(?:${escapedChar})+`);

    // Replace the matched pattern with an empty string
    return value.replace(regex, '');
}
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { professionalsSelector } from "../../../../store/slices/recruiter/professionalsSlice";
import { VacancyRelation, VacancyRelationState } from "../../../../types/recruiter/relations";
import { DotsLoader, VacancyProfessionalsTable } from "../../../molecules";
import { useCallback, useEffect, useState } from "react";
import { MatchScore } from "../../../../types/recruiter/matchScore";
import { relationStatesSelector } from "../../../../store/slices/recruiter/relationStatesSlice";
import { relationsSelector, updateRelationComment, updateRelationStatus } from "../../../../store/slices/recruiter/relationsSlice";

import api from "../../../../api/recruiter/relations";
import { useMissingScoresHook } from "../../../hooks/fetchMissingScores";

export function RelatedProfessionalsOrganism() {
    const params = useParams();
    const dispatch = useAppDispatch();

    const relationStates = useAppSelector(relationStatesSelector).relationStates;
    const relationsState = useAppSelector(relationsSelector);
    const relations = relationsState.relations.filter(x => x.vacancyId === params.id);
    const professionalsState = useAppSelector(professionalsSelector);

    const [scores, setScores] = useState<MatchScore[]>([]);
    const professionals = professionalsState.professionals.filter(prof => relations.some(y => y.professionalId === prof._id));
    const fetching = professionalsState.fetchingList === "fetching" || relationsState.fetchingRelations === "fetching";

    const fetchProfessionalScores = useCallback(async () => {
        const _relations = relationsState.relations.filter(x => x.vacancyId === params.id);
        if (_relations.length === 0) return;

        try {
            const result = await api.getScores(_relations);
            setScores(result);
        } catch (error) {
            console.error("Error fetching professional scores:", error);
        }
    }, [relationsState.relations, params.id]);

    const fetchMissingScores = useMissingScoresHook(scores, setScores);

    useEffect(() => {
        fetchProfessionalScores()
    }, [fetchProfessionalScores]);

    useEffect(() => {
        fetchMissingScores()
    }, [fetchMissingScores]);

    const onChangeRelation = (relation: VacancyRelation, status: VacancyRelationState) => dispatch(updateRelationStatus({
        id: relation._id,
        status: status
    }));

    const onChangeComment = (relation: VacancyRelation, comment: string) => dispatch(updateRelationComment({
        id: relation._id,
        comment
    }));

    return <>
        {
            fetching && <DotsLoader message={"Professionals worden opgehaald"} />
        }
        {
            !fetching && <VacancyProfessionalsTable states={relationStates} scores={scores} onCommentChange={onChangeComment} onRelationChange={onChangeRelation} professionals={professionals} relations={relations} />
        }
    </>
}
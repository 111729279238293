import { Company } from "../../../../types/company/company";
import { Address } from "../../../../types/company/address";
import { companyTypeString } from "../../../../utils/companyType";
import { ColumnProps, Table } from "../Table";
import { Box, Link as MuiLink } from "@mui/material";
import { Link } from "../../Link";
import { organizationRoutes } from "../../../../router/paths";
import { StageProgress } from "../../../../types/documents/Stages";
import { StageProgressTiles } from "../../StageProgress";
import { stageValueGetter } from "../../../../utils/stage";

export function CompaniesTable(props: {
    companies: Company[];
    stages: StageProgress[];
}) {
    const getStageProgress = (contractId: string) => props.stages.find(s => s.id === contractId);
    const toMapsUrl = (address: Address) => `https://www.google.com/maps/place/${address.straatnaam}+${address.huisnummer},+${address.postcode}+${address.plaats}`.replaceAll(' ', '+');
    const colDefs: ColumnProps<Company>[] = [
        {
            headerName: "Documenten",
            valueGetter: row => stageValueGetter(getStageProgress(row.data._id)),
            cellRenderer: (props) => {
                const stageProgress = getStageProgress(props.data._id);
                return <Box sx={{ mt: 1 }}>
                    <StageProgressTiles stageProgress={stageProgress} />
                </Box>
            },
        },
        {
            headerName: "Naam",
            cellRenderer: (row) => {
                if (!row.data) return null;
                return <Link path={organizationRoutes.details(row.data._id)}>{row.data.naam}</Link>
            },
        },
        {
            headerName: "Plaats",
            cellRenderer: (row) => {
                const adr = row.data.address;
                if (!adr) return null;
                return <MuiLink onClick={() => window.open(toMapsUrl(adr))}>{adr.plaats}</MuiLink>
            },
        },
        {
            field: 'billingAddress',
            headerName: "Factuur email"
        },
        {
            field: "phone",
            headerName: "Telefoon"
        },
        {
            field: "kvkNummer",
            headerName: "KvK"
        },
        {
            headerName: "Soort",
            cellRenderer: (row) => companyTypeString(row.data!.rechtsvorm),
            flex: 1
        },
    ];

    return <Table id="Companies" slim columns={colDefs} data={props.companies} paging />;
}


import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { PublishSettings, PublishedState, Vacancy } from '../../../types/recruiter/vacancy';
import vacancyApi from '../../../api/recruiter/vacancy';
import { FetchingState } from '../../../types/fetchingState';
import { showToast } from '../toastSlice';


export interface VacanciesState {
    vacancies: Vacancy[];
    publishSettings: PublishSettings[];
    fetchingList: FetchingState;
    fetchingCreate: FetchingState;
    fetchingDelete: FetchingState;
    fetchingUpdate: FetchingState;
    fetchingPublishSettings: FetchingState;
}

const initialState: VacanciesState = {
    vacancies: [],
    publishSettings: [],
    fetchingList: 'init',
    fetchingCreate: 'init',
    fetchingDelete: 'init',
    fetchingUpdate: 'init',
    fetchingPublishSettings: 'init',
};

export const listVacancies = createAsyncThunk('vacancies/list', async (_, thunkApi) => {
    try {
        const vacancies = await vacancyApi.list();
        return vacancies;
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: e.message, type: 'error' }));
        throw e;
    }
});

export const deleteVacancy = createAsyncThunk('vacancies/delete', async (id: string, thunkApi) => {
    try {
        await vacancyApi.delete(id);
        thunkApi.dispatch(showToast({ message: 'Vacature is verwijderd', type: 'success' }));
        return id;
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: e.message, type: 'error' }));
        throw e;
    }
});

export const listVacanciesSettings = createAsyncThunk('vacancies/list-settings', async (_, thunkApi) => {
    try {
        const ids = (thunkApi.getState() as RootState).recruiter.vacancies.vacancies.map((v) => v._id);

        if (ids.length === 0) return [];

        const settings = await vacancyApi.listPublishSettings(ids);
        return settings;
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: e.message, type: 'error' }));
        throw e;
    }
});

export const publishVacancy = createAsyncThunk('vacancies/publish', async (id: string, thunkApi) => {
    try {
        const code = await vacancyApi.publish(id);
        thunkApi.dispatch(showToast({ message: 'Vacature is gepubliceerd', type: 'success' }));
        return { id, code };
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: e.message, type: 'error' }));
        throw e;
    }
});

export const unpublishVacancy = createAsyncThunk('vacancies/unpublish', async (id: string, thunkApi) => {
    try {
        await vacancyApi.unpublish(id);
        thunkApi.dispatch(showToast({ message: 'Vacature is gedepubliceerd', type: 'success' }));
        return { id };
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: e.message, type: 'error' }));
        throw e;
    }
});

export const createVacancy = createAsyncThunk('vacancies/create', async (v: Vacancy, thunkApi) => {
    try {
        const vacancy = await vacancyApi.create(v);
        const vacancySettings = (await vacancyApi.getPublishSettings(vacancy._id)).find((s) => s._id === vacancy._id);

        thunkApi.dispatch(showToast({ message: 'Vacature is aangemaakt', type: 'success' }));
        return { vacancy, settings: vacancySettings };
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: e.message, type: 'error' }));
        throw e;
    }
});

export const updateVacancy = createAsyncThunk('vacancies/update', async (v: Vacancy, thunkApi) => {
    try {
        await vacancyApi.update(v);
        thunkApi.dispatch(showToast({ message: 'Vacature is bijgewerkt', type: 'success' }));
        return v;
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: 'Fout bij het bijwerken:' + e.message, type: 'error' }));
        throw e;
    }
});

export const vacanciesSlice = createSlice({
    name: 'vacancies',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // List Vacancies
        builder
            .addCase(listVacancies.pending, (state) => {
                state.fetchingList = 'fetching';
            })
            .addCase(listVacancies.rejected, (state) => {
                state.fetchingList = 'error';
            })
            .addCase(listVacancies.fulfilled, (state, action: PayloadAction<Vacancy[]>) => {
                state.fetchingList = 'completed';
                state.vacancies = action.payload;
            });

        // Create Vacancy
        builder
            .addCase(createVacancy.pending, (state) => {
                state.fetchingCreate = 'fetching';
            })
            .addCase(createVacancy.rejected, (state) => {
                state.fetchingCreate = 'error';
            })
            .addCase(createVacancy.fulfilled, (state, action: PayloadAction<{ vacancy: Vacancy; settings: PublishSettings | undefined }>) => {
                state.fetchingCreate = 'completed';
                state.vacancies = [action.payload.vacancy, ...state.vacancies];

                if (action.payload.settings) state.publishSettings = [action.payload.settings, ...state.publishSettings];
            });

        // Update Vacancy
        builder
            .addCase(updateVacancy.pending, (state) => {
                state.fetchingUpdate = 'fetching';
            })
            .addCase(updateVacancy.rejected, (state) => {
                state.fetchingUpdate = 'error';
            })
            .addCase(updateVacancy.fulfilled, (state, action: PayloadAction<Vacancy>) => {
                state.fetchingUpdate = 'completed';
                state.vacancies = state.vacancies.map((p) => (p._id === action.payload._id ? action.payload : p));
            });

        // Delete Vacancy
        builder
            .addCase(deleteVacancy.pending, (state) => {
                state.fetchingDelete = 'fetching';
            })
            .addCase(deleteVacancy.rejected, (state) => {
                state.fetchingDelete = 'error';
            })
            .addCase(deleteVacancy.fulfilled, (state, action: PayloadAction<string>) => {
                state.fetchingDelete = 'completed';
                state.vacancies = state.vacancies.filter((p) => p._id !== action.payload);
            });

        // List Publish Settings
        builder
            .addCase(listVacanciesSettings.pending, (state) => {
                state.fetchingPublishSettings = 'fetching';
            })
            .addCase(listVacanciesSettings.rejected, (state) => {
                state.fetchingPublishSettings = 'error';
            })
            .addCase(listVacanciesSettings.fulfilled, (state, action) => {
                state.fetchingPublishSettings = 'completed';
                state.publishSettings = action.payload;
            });

        // Publish Vacancy
        builder
            .addCase(publishVacancy.pending, (state) => {
                state.fetchingPublishSettings = 'fetching';
            })
            .addCase(publishVacancy.rejected, (state) => {
                state.fetchingPublishSettings = 'error';
            })
            .addCase(publishVacancy.fulfilled, (state, action) => {
                state.fetchingPublishSettings = 'completed';

                const existing = state.publishSettings.find((s) => s._id === action.payload.id);
                if (!existing)
                    state.publishSettings = [{ _id: action.payload.id, published: PublishedState.Published, code: action.payload.code }, ...state.publishSettings];
                else
                    state.publishSettings = state.publishSettings.map((s) => {
                        if (s._id === action.payload.id) return { ...s, published: PublishedState.Published, code: action.payload.code };
                        return s;
                    });
            });

        // Unpublish Vacancy
        builder
            .addCase(unpublishVacancy.pending, (state) => {
                state.fetchingPublishSettings = 'fetching';
            })
            .addCase(unpublishVacancy.rejected, (state) => {
                state.fetchingPublishSettings = 'error';
            })
            .addCase(unpublishVacancy.fulfilled, (state, action) => {
                state.fetchingPublishSettings = 'completed';

                state.publishSettings = state.publishSettings.map((s) => {
                    if (s._id === action.payload.id) return { ...s, published: PublishedState.Unpublished, code: '' };
                    return s;
                });
            });
    },
});

export const vacanciesSelector = (state: RootState) => state.recruiter.vacancies;
export const publishSettingsSelector = (id: string) => (state: RootState) => state.recruiter.vacancies.publishSettings.find((s) => s._id === id);
export const publishSettingsLoadingSelector = (state: RootState) => state.recruiter.vacancies.fetchingPublishSettings === 'fetching';
export default vacanciesSlice.reducer;
import { Box, Button, Chip, CircularProgress, Dialog, DialogContent, IconButton, Modal, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ContentCopyTwoTone, EditTwoTone, EmailTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { recruiterRoutes } from "../../../../router/paths";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { PublishedState } from "../../../../types/recruiter/vacancy";
import { ellipsis } from "../../../../utils/strings";
import { ContainedButton, Dropdown, IconLabel, PublishedStateIndicator, TextInput } from "../../../molecules";
import { EmailEditorMolecule } from "../../../molecules/email/EmailEditor";

import emailTemplatesApi from "../../../../api/email/templates";
import { EmailTemplateFor } from "../../../../types/company/emailTemplates";
import { useEffect, useState } from "react";
import { Row } from "../../../molecules/Row";
import { CompanyGroup } from "../../../../types/company/companyGroup";
import { emailValidation } from "../../../validations/email";

import groupsApi from "../../../../api/organization/companyGroups";
import vacancyApi from "../../../../api/recruiter/vacancy";
import { publishSettingsSelector, publishSettingsLoadingSelector, unpublishVacancy, publishVacancy } from "../../../../store/slices/recruiter/vacanciesSlice";

export function PublishSettingsOrganism() {
    const params = useParams();
    const nav = useNavigate();
    const dispatch = useAppDispatch();

    const { id } = params;
    const status = useAppSelector(publishSettingsSelector(id ?? ''))
    const loading = useAppSelector(publishSettingsLoadingSelector);
    const label = status?.published === PublishedState.Published ? 'Vacature depubliceren' : 'Vacature publiceren';
    const [fetchingHtml, setFetchingHtml] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [html, setHtml] = useState('');
    const [fetchingCategories, setFetchingCategories] = useState(false);
    const [groups, setGroups] = useState<CompanyGroup[]>([]);
    const [emailTo, setEmailTo] = useState<string[]>([]);
    const [emailInput, setEmailInput] = useState('');
    const [subjectInput, setSubjectInput] = useState('');
    const [sending, setSending] = useState(false);

    const handleClick = () => {
        if (status?.published === PublishedState.Published)
            dispatch(unpublishVacancy(id!));
        else
            dispatch(publishVacancy(id!));
    }

    const onOpenEditor = async () => {
        setFetchingHtml(true);

        if (html.length === 0) {
            const html = await emailTemplatesApi.get(EmailTemplateFor.VACANCY_LINK_SHARE.toString());
            setHtml(html);
        }

        setShowEditor(true);
        setFetchingHtml(false);
    }

    const getPublishUri = () => {
        return window.location.origin + recruiterRoutes.inschrijven(status?.code ?? '');
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(getPublishUri());
    }

    const onSelectedGroup = async (id: string) => {
        setFetchingCategories(true);

        const members = await groupsApi.listMembers(id);
        var set = new Set([...emailTo, ...members.map(m => m.genericAddress)]);
        setEmailTo(Array.from(set));

        setFetchingCategories(false);
    };

    const addEmail = (email: string) => {
        var validator = emailValidation(email);
        if (validator !== undefined)
            return;

        var set = new Set([...emailTo, email]);
        setEmailTo(Array.from(set));
        setEmailInput('');
    }

    const onSend = async () => {
        setSending(true);

        vacancyApi.shareByEmail(id!, {
            body: html.replace("[LINK_VALUE]", getPublishUri()),
            subject: subjectInput,
            to: emailTo
        })
            .then(() => {
                setShowEditor(false);
                setShowShareModal(false);
                setSubjectInput('');
            })
            .catch(() => {
                alert('Er is iets misgegaan bij het versturen van de email.');
            })
            .finally(() => setSending(false));
    };

    useEffect(() => {
        setFetchingCategories(true);
        groupsApi.list().then(setGroups).finally(() => setFetchingCategories(false));
        emailTemplatesApi.get(EmailTemplateFor.VACANCY_LINK_SHARE.toString()).then(setHtml);
    }, []);

    return <>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PublishedStateIndicator status={status} size={15} />
            <Typography sx={{ mx: 2, fontWeight: 800 }}>{
                status?.published === PublishedState.Published ? 'Gepubliceerd: ' : 'Niet gepubliceerd'
            }</Typography>
            {
                status?.published === PublishedState.Published && <>
                    <Typography>{ellipsis(window.location.origin + recruiterRoutes.inschrijven(status.code!), 100)}</Typography>

                    <IconButton sx={{ ml: 1 }} onClick={handleCopy}>
                        <ContentCopyTwoTone sx={{ width: 20 }} color="primary" />
                    </IconButton>
                </>
            }
        </Box>
        <Box display={'flex'} mt={2}>
            <LoadingButton loading={loading} variant="text" color={
                status?.published === PublishedState.Published ? 'error' : 'primary'
            } sx={{ height: 35, mr: 2 }} onClick={handleClick}>
                <Typography variant="caption">
                    {label}
                </Typography>
            </LoadingButton>

            <Button disabled={status?.published !== PublishedState.Published} sx={{ height: 35 }} variant="text" onClick={() => nav(recruiterRoutes.inschrijven(status?.code!))}>
                <Typography variant="caption">
                    Voorbeeld
                </Typography>
            </Button>
        </Box>

        <Box display={'flex'} mt={2}>
            <LoadingButton loading={fetchingHtml} variant="text" color={'primary'} sx={{ height: 35, mr: 2 }} onClick={() => setShowShareModal(true)} disabled={status?.published !== PublishedState.Published}>
                <EmailTwoTone sx={{ mr: 1 }} fontSize="small" />
                <Typography variant="caption" fontWeight={'bold'}>
                    Vacature Delen via email
                </Typography>
            </LoadingButton>
        </Box>

        <Dialog open={showShareModal} onClose={() => setShowShareModal(false)}>
            <DialogContent sx={{ minWidth: 600 }}>
                <Typography sx={{ mb: 2 }} variant="h6">Vacature Delen</Typography>

                <TextInput label="Email"
                    required
                    value={emailInput}
                    onChange={setEmailInput}
                    onSubmit={() => addEmail(emailInput)}
                />
                <Box sx={{ my: 2 }}>
                    {

                        emailTo.map(e => <Chip key={e} label={e} onDelete={() => setEmailTo(emailTo.filter(x => x !== e))} />)
                    }
                </Box>
                <IconLabel label="Importeer email adressen uit categorie" />
                {
                    fetchingCategories
                        ? <CircularProgress />
                        : <Dropdown
                            label="Importeer email adressen uit categorie"
                            options={groups.map(g => ({ label: g.name, value: g._id }))}
                            value={undefined}
                            onChange={onSelectedGroup}
                        />}
                <TextInput sx={{ my: 2 }} label="Onderwerp" required value={subjectInput} onChange={setSubjectInput} />

                <Box sx={{ mt: 3 }} />

                <Row sx={{ mb: 2, mt: 4 }}>
                    <Button variant="text" onClick={() => onOpenEditor()} color="primary">
                        <EditTwoTone fontSize="small" sx={{ mr: 2 }} />
                        <Typography variant="caption">Bewerk Email</Typography>
                    </Button>
                    <Box sx={{ flex: 1 }} />
                    <LoadingButton loading={sending} variant="outlined" onClick={onSend} disabled={
                        !Boolean(subjectInput) || emailTo.length === 0 || sending
                    }>
                        <EmailTwoTone fontSize="small" sx={{ mr: 2 }} />
                        <Typography variant="caption">Verzenden</Typography>
                    </LoadingButton>
                </Row>
            </DialogContent>
        </Dialog>


        <Modal open={showEditor} onClose={() => setShowEditor(false)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ p: 3, background: '#fff', maxWidth: 800, borderRadius: 1, maxHeight: '95vh', overflow: 'auto' }}>
                <Typography variant="h5" fontWeight={100}>Link delen</Typography>
                <Box sx={{ mt: 2 }} />
                <EmailEditorMolecule
                    html={html}
                    onChange={setHtml}
                    config={{
                        withInviteLinkButton: true,
                        withCredentialsLinkButton: false,
                        withNameButton: false,
                        withPwdResetLinkButton: false,
                    }} />

                <ContainedButton fullWidth sx={{ mt: 2 }} onClick={() => setShowEditor(false)}>Bewerken Sluiten</ContainedButton>
            </Box>
        </Modal>


    </>
}
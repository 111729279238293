import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { SearchTwoTone } from "@mui/icons-material";
import { filterBySearch } from "../../../../filters/vacatureFilters";
import { recruiterRoutes } from "../../../../router/paths";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { VacancyRelation } from "../../../../types/recruiter/relations";
import { Vacancy } from "../../../../types/recruiter/vacancy";
import { TextInput } from "../../../molecules";
import { ApplyVacancyTable } from "../../../molecules/tables/recruiter/ApplyVacancy";
import { MatchScore } from "../../../../types/recruiter/matchScore";
import api from "../../../../api/recruiter/relations";
import { vacanciesSelector } from "../../../../store/slices/recruiter/vacanciesSlice";
import { createVacancyRelation, deleteVacancyRelation, relationsSelector } from "../../../../store/slices/recruiter/relationsSlice";
import { Checkbox } from "../../../molecules/inputs/CheckboxInput";
import { getScoreMappings } from "../../../../utils/scoreMappings";
import { useMissingScoresHook } from "../../../hooks/fetchMissingScores";

export function ApplyVacancy({ professionalId }: { professionalId: string; }) {
    const nav = useNavigate();
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState<string>("");
    const [scores, setScores] = useState<MatchScore[]>([]);
    const [showClosedVacancies, setShowClosedVacancies] = useState<boolean>(false);

    const vacanciesState = useAppSelector(vacanciesSelector);
    const relationsState = useAppSelector(relationsSelector);

    const fetchProfessionalScores = useCallback(async () => {
        const mappings = getScoreMappings(vacanciesState.vacancies.map(x => x._id), [professionalId]);
        if (mappings.length === 0) return;

        try {
            const result = await api.getScores(mappings);
            setScores(result);
        } catch (error) {
            console.error("Error fetching professional scores:", error);
        }
    }, [vacanciesState.vacancies, professionalId]);

    const fetchMissingScores = useMissingScoresHook(scores, setScores);

    useEffect(() => {
        fetchProfessionalScores()
    }, [fetchProfessionalScores]);

    useEffect(() => {
        fetchMissingScores()
    }, [fetchMissingScores]);

    const addRelation = (vacancy: Vacancy) => {
        dispatch(createVacancyRelation({
            professionalId: professionalId!,
            vacancyId: vacancy._id,
            targetId: vacancy._id
        }));
    };

    const removeRelation = (relation: VacancyRelation) => {
        dispatch(deleteVacancyRelation({ relation, targetId: relation.vacancyId }));
    };

    return <Box>
        <TextInput icon={SearchTwoTone} label="Zoeken" sx={{ my: 2 }} value={search} onChange={v => setSearch(v as string)} />
        <Checkbox checked={showClosedVacancies}
            onChange={() => setShowClosedVacancies(!showClosedVacancies)}
            title="Toon Gesloten Vacatures"
        />
        <ApplyVacancyTable
            scores={scores}
            processing={relationsState.fetchingProfessionalRelations}
            vacancies={filterBySearch(vacanciesState.vacancies, search, !showClosedVacancies)}
            relations={relationsState.relations.filter(x => x.professionalId === professionalId)}
            onApply={addRelation}
            onRemove={removeRelation}
            onVacancyClick={(vacancy) => nav(recruiterRoutes.vacatures.details(vacancy._id))}
        />
    </Box>
}
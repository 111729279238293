import { listProfessionals } from "./slices/recruiter/professionalsSlice";
import { listCompanies } from "./slices/companiesSlice";
import { listVacancies, listVacanciesSettings } from "./slices/recruiter/vacanciesSlice";
import { listPersons } from "./slices/personsSlice";
import { listTags } from "./slices/tagsSlice";
import { listContracts } from "./slices/contractsSlice";
import { listVacanciesRelations } from "./slices/recruiter/relationsSlice";
import { listRelationStates } from "./slices/recruiter/relationStatesSlice";
import { listFollowUpTemplates } from "./slices/recruiter/followUpTemplatesSlice";

export const refreshRecruiterModuleData = async (
    dispatch: (action: any) => Promise<void>
) => {
    await Promise.all([
        dispatch(listProfessionals()),
        dispatch(listVacancies())
    ]);

    dispatch(listVacanciesRelations());
    dispatch(listVacanciesSettings());
}

export const hydrateRecruiterModule = async (
    dispatch: (action: any) => void) => {

    console.log("===== Hydrating Recruiter Module =====");

    const promises = [];
    promises.push(dispatch(listProfessionals()));
    promises.push(dispatch(listVacancies()));
    promises.push(dispatch(listRelationStates()));

    await Promise.all(promises);

    dispatch(listVacanciesRelations());
    dispatch(listVacanciesSettings());
    dispatch(listFollowUpTemplates());
}

export const hydrateOrgModule = async (
    dispatch: (action: any) => void) => {
    console.log("===== Hydrating Organization Module =====");
    dispatch(listCompanies());
    dispatch(listPersons());
    dispatch(listTags());
}

export const hydrateContractsModule = async (
    dispatch: (action: any) => void) => {
    console.log("===== Hydrating Contracts Module =====");
    dispatch(listContracts());
}


const PRD_apiRoutes = {
    iam: `${window.location.origin}/api/auth`,
    organization: `${window.location.origin}/api/organization`,
    documents: `${window.location.origin}/api/documents`,
    email: `${window.location.origin}/api/email`,
    recruiter: `${window.location.origin}/api/recruiter`,
    contracts: `${window.location.origin}/api/contracts`,
    address: "https://postcode.tech/api/v1"
}

const DEV_apiRoutes = {
    iam: "http://localhost:8001",
    organization: "http://localhost:8006",
    documents: "http://localhost:8007",
    email: "http://localhost:8005",
    recruiter: "http://localhost:9002",
    contracts: "http://localhost:8009",
    address: "https://postcode.tech/api/v1"
}

export const apiRoutes = process.env.NODE_ENV === 'production' ? PRD_apiRoutes : DEV_apiRoutes;
import { pathPermissions } from "../router/paths";
import { PlatformUser, Permissions } from "../types/user";
import { DrawerItemProps } from "../ui/molecules/Drawer";

export const getPathPermissions = (path: string): Permissions[] => pathPermissions[path] ?? undefined;

export const createPermissionValidator = (user?: PlatformUser) => (permissions?: Permissions[], companyId?: string) => !!user && (
    user?.isAdmin ||
    !permissions ||
    permissions.some(permission => user.permissions.includes(permission) ||
        user
            .companyPermissions
            .filter(companyPermission => companyId ? companyPermission.companyId === companyId : true)
            .some(companyPermission => companyPermission.permissions.includes(permission))
    ));

export const createWhitelabelPermissionValidator = (user?: PlatformUser) => (permissions?: Permissions[]) => user?.isAdmin ||
    (!!user && (!permissions || permissions.some(permission => user.permissions.includes(permission)))
    );

export const createMenuAuthorizer = (user?: PlatformUser) => (modules: DrawerItemProps[]): DrawerItemProps[] => {
    if (user?.isAdmin) return modules;

    const allowedModules: DrawerItemProps[] = [];
    if (!user) return [];

    const validator = createPermissionValidator(user);
    const moduleItemsPermissionsFilter = (listItems: DrawerItemProps[]): DrawerItemProps[] => {
        const results: DrawerItemProps[] = [];

        listItems.forEach(moduleListItem => {
            if (moduleListItem.link) {
                const permissions = getPathPermissions(moduleListItem.link);
                if (validator(permissions))
                    results.push(moduleListItem);

                return;
            }

            const allowedItems = moduleListItem.items!.filter(item => {
                const permissions = getPathPermissions(item.link!);
                return validator(permissions);
            });

            if (allowedItems.length > 0)
                results.push({
                    ...moduleListItem,
                    items: allowedItems
                });
        });

        return results;
    }

    modules.forEach(module => {
        const allowedItems = moduleItemsPermissionsFilter(module.items ?? []);
        if (allowedItems.length > 0 || module.items === undefined)
            allowedModules.push({ ...module, items: allowedItems });
    });

    return allowedModules;
}

export const isProfessionalUser = (user?: PlatformUser) => {
    if (!user) return false;
    return user.permissions.length === 0 && user.companyPermissions.length === 0;
};
import { FC } from "react";
import { CloseTwoTone } from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";
import { indexToWord } from "../../utils/strings";
import { primaryLight } from "../theme";
import { RowCentered, TextInput, AsyncAutocomplete } from ".";

export const PersonInputList: FC<{
    selected: { name: string, id: string }[],
    list: { name: string, id: string }[],
    disabled?: boolean,
    onRemove?: (id: string) => void,
    onSelect?: (id: string) => void
}> = ({ list, selected, disabled, onRemove, onSelect }) => {
    return <Box>
        {
            selected.map((item, index) =>
                <TextInput key={item.id}
                    leftNeighbour={
                        <RowCentered sx={{ background: primaryLight, px: 1, borderRadius: 1 }}>
                            <Typography variant="body1" fontSize={12} fontWeight={'bold'}>{indexToWord(index)}</Typography>
                        </RowCentered>
                    }
                    rightNeighbour={
                        onRemove
                            ? <Button sx={{ p: 0, minWidth: 0, mr: 1 }}>
                                <CloseTwoTone
                                    fontSize="small"
                                    color="error"
                                    sx={{ padding: 0 }}
                                    onClick={() => onRemove(item.id)}
                                />
                            </Button>
                            : undefined
                    }
                    disabled={disabled}
                    value={item.name}
                    sx={{ mb: 2 }}
                />
            )
        }
        <Box mb={2}>
            <AsyncAutocomplete
                key={selected.length}
                disabled={disabled}
                options={list.filter(x => !selected.find(a => a.id === x.id))}
                getTitleFromOption={x => x.name}
                onSelect={person => onSelect && person && onSelect(person.id)}
            />
        </Box>
    </Box>
}
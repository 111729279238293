import { Box, Typography, Chip } from "@mui/material";
import styled from "styled-components";
import { Vacancy } from "../../types/recruiter/vacancy";
import { sanitizeIsoStringToDate } from "../../utils/strings";
import { FileUploadTwoTone } from "@mui/icons-material";
import { useState } from "react";
import { Professional } from "../../types/recruiter/professional";
import { ButtonInput } from "./inputs/FileInput";
import { ProfessionalDetailsForm } from "../forms/recruiter/professional/ProfessionalDetails";
import { Row } from "./Row";
import { CootjeTitle } from "./CootjeLogo";

const Divider = styled(Box)({ marginTop: '2rem' })

export function VacatureViewer(props: { vacancy: Vacancy }) {
    const { vacancy } = props;

    return <>
        <Typography variant="h4" color={'secondary'}>{vacancy.title}</Typography>
        {Boolean(vacancy.company) && <Typography variant="h6" color={'secondary'}>{vacancy.company}</Typography>}
        <Divider />
        {
            vacancy.requiredSkills.map(skill => <Chip key={skill} label={skill} sx={{ mr: 1, mb: 1 }} />)
        }
        <Divider />
        {
            Boolean(vacancy.location) && <Row>
                <Typography color={'secondary'} fontWeight={'bold'} mr={1} variant="body2">Locatie</Typography>
                <Typography variant="body2">{vacancy.location}</Typography>
            </Row>
        }
        <Row>
            <Typography color={'secondary'} fontWeight={'bold'} mr={1} variant="body2">Start Datum</Typography>
            <Typography variant="body2">{sanitizeIsoStringToDate(vacancy.startDate)}</Typography>
        </Row>
        <Row>
            <Typography color={'secondary'} fontWeight={'bold'} mr={1} variant="body2">Eind Datum</Typography>
            <Typography variant="body2">{sanitizeIsoStringToDate(vacancy.endDate)}</Typography>
        </Row>

        <Divider />

        <Row>
            <Typography color={'secondary'} fontWeight={'bold'} mr={1} variant="body2">Beschikbaarheid</Typography>
            <Typography variant="body2">{vacancy.hoursPerWeek} uur per week</Typography>
        </Row>

        {(vacancy.hourPrice > 0) && <Row>
            <Typography color={'secondary'} fontWeight={'bold'} mr={1} variant="body2">Tarief</Typography>
            <Typography variant="body2">{vacancy.hourPrice} euro per uur</Typography>
        </Row>}
        <Divider />
        <Typography whiteSpace={'pre-wrap'} variant="body2">{vacancy.description}</Typography>
    </>
}

export function VacatureAppliance(props: {
    value: Professional,
    onChange: (value: Professional) => void,
    onUpload?: (cv: File) => void,
    hideContract?: boolean
}) {
    const [, setCV] = useState<File>();

    return <Box>
        <CootjeTitle title="Persoonsgegevens" />
        {
            props.onUpload && <>
                <Divider />
                <Box sx={{ mb: 2 }}>
                    <Typography variant="caption" color='secondary'>Door het te uploaden van uw CV zullen ook de velden automatisch worden ingevuld.</Typography>
                </Box>

                <ButtonInput icon={FileUploadTwoTone} key={Math.random()} fileTypes={["pdf", "eml", "msg", "docx"]} loading={false} placeholder="Importeer vanuit CV" onChange={file => {
                    setCV(file);
                    if (file)
                        props.onUpload!(file);
                }} />
                <Box sx={{ mb: 2 }} />
            </>
        }

        <Box>
            <Typography variant="caption" color='secondary'><strong>Naam</strong>, <strong>email</strong> en <strong>functie</strong> zijn verlicht.</Typography>
        </Box>
        <Box>
            <Typography variant="caption" color='secondary'>Bij <strong>telefoon</strong> dient het landcode te worden weggelaten.</Typography>
        </Box>
        <ProfessionalDetailsForm state={props.value} onChange={props.onChange} hideContract={props.hideContract} hideMemo />
    </Box>
}
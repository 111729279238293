import { Box, Checkbox, SxProps, Typography } from "@mui/material"
import { FC, useState } from "react"
import { Company } from "../../../types/company/company"
import { BaseContract, Division } from "../../../types/contracts"
import { ApproverModeInput, AsyncAutocomplete, IconLabel, PersonInputList, TextInput } from "../../molecules"
import { BusinessTwoTone, ConnectWithoutContactTwoTone, EmojiPeopleTwoTone, Filter1TwoTone, HandymanTwoTone, HistoryEduTwoTone, HomeTwoTone, HowToRegTwoTone, ImportContactsTwoTone, LocationOnTwoTone, ManageAccountsTwoTone, SchoolTwoTone, TransferWithinAStationTwoTone } from "@mui/icons-material"
import { Person } from "../../../types/company/person"
import { contractPerson, defaultPerson } from "../../../types/contracts/Person"
import { primaryLighter } from "../../theme"
import { KvKSearchResult, KvKListVestigingItem } from "../../../types/company/kvk"

import kvkApi from "../../../api/organization/kvk"
import { defaultOrganisation } from "../../../types/contracts/Organisation"

export const ContractDetailsEditForm: FC<{
    contract: BaseContract,
    companies: Company[],
    professionals: Person[],
    divisions: Division[],
    authorizedSignatoriesSupplier: Person[],
    authorizedSignatoriesHirer: Person[],
    timesheetApprovers: Person[],
    onChange: (value: BaseContract) => void,
    hirerMembers: Person[],
    sx?: SxProps
}> = ({
    companies, professionals, onChange,
    contract, sx,
    authorizedSignatoriesSupplier,
    authorizedSignatoriesHirer, hirerMembers, divisions, timesheetApprovers
}) => {
        const [searching, setSearching] = useState<boolean>(false);
        const [searchResults, setSearchResults] = useState<KvKSearchResult[]>([]);
        const [selectedCompany, setSelectedCompany] = useState<KvKSearchResult | null>(null);
        const [vestigingen, setVestigingen] = useState<KvKListVestigingItem[]>([]);
        const [selectedVestiging, setSelectedVestiging] = useState<KvKListVestigingItem | null>(null);
        const [fetchingVestigingen, setFetchingVestigingen] = useState<boolean>(false);
        const [hirerIsEndClient, setHirerIsEndClient] = useState<boolean>(false);

        const searchKvK = (search: string) => {
            setSearching(true);
            kvkApi.searchKvk(search)
                .then(setSearchResults)
                .finally(() => setSearching(false));
        }

        const listVestigingen = async (kvk: string) => {
            setSelectedVestiging(null);

            setFetchingVestigingen(true);
            const vestigingen = await kvkApi.listKvkVestigingen(kvk);
            setVestigingen(vestigingen.sort((a, b) => a.volledigAdres.localeCompare(b.volledigAdres)));
            setFetchingVestigingen(false);

            if (vestigingen.length > 0)
                onSelectVestiging(vestigingen[0]);
        }

        const onSelectVestiging = (v: KvKListVestigingItem) => {
            setSelectedVestiging(v);

            onChange({
                ...contract,
                endCustomer: {
                    ...contract.endCustomer,
                    kvk: selectedCompany?.kvkNummer ?? contract.endCustomer.kvk,
                    locationNumber: v.vestigingsnummer,
                    address: v.volledigAdres,
                    traderName: v.eersteHandelsnaam,
                }
            });
        }

        const toggleHirerIsEndClient = () => {
            const newValue = !hirerIsEndClient;
            setHirerIsEndClient(newValue);

            onChange({
                ...contract,
                endCustomer: newValue ? { ...contract.hirer } : defaultOrganisation()
            });
        }

        return <Box sx={sx}>

            <TextInput
                icon={Filter1TwoTone}
                label="Contractnummer"
                required
                sx={{ flex: 1, minWidth: 300, mb: 2, maxWidth: 500 }}
                value={contract.contractnumber}
                onChange={e => onChange({ ...contract, contractnumber: e })}
            />

            <Box width={16} />

            <TextInput
                icon={ManageAccountsTwoTone}
                label="Contractmanager"
                required
                sx={{ flex: 1, minWidth: 300, mb: 2, maxWidth: 500 }}
                value={contract.contractManager}
                onChange={e => onChange({ ...contract, contractManager: e })}
            />

            <Box display={'flex'} flexWrap={'wrap'}>
                <Box flex={1} p={2} sx={{ background: primaryLighter, minWidth: 300, mb: 2 }}>
                    <Typography variant="overline">Inlener gegevens</Typography>
                    <Box mb={2} />

                    <IconLabel icon={EmojiPeopleTwoTone} label="Inlener" required />
                    <AsyncAutocomplete
                        options={companies}
                        getTitleFromOption={(company) => `${company?.naam} - ${company?.address.volledigAdres}`}
                        onSelect={
                            (company) => {
                                company && onChange({
                                    ...contract, hirer: {
                                        _id: company._id,
                                        address: company.address.volledigAdres,
                                        kvk: company.kvkNummer,
                                        locationNumber: company.vestigingsNummer,
                                        traderName: company.handelsnamen.find(_ => true)?.naam ?? '',
                                    },
                                    assignment: hirerIsEndClient ? {
                                        ...contract.assignment,
                                        location: company.address.plaats
                                    } : contract.assignment
                                });
                            }
                        }
                    />

                    <Box mb={2} />
                    <IconLabel icon={ConnectWithoutContactTwoTone} label="Contactpersoon" required />
                    <AsyncAutocomplete
                        key={contract.contactHirer._id + '-contactHirer'}
                        options={hirerMembers}
                        selected={hirerMembers.find(p => p._id === contract.contactHirer._id)}
                        getTitleFromOption={prof => prof ? `${prof.firstName} ${prof.lastName} - ${prof.email}` : ''}
                        onSelect={person => onChange({
                            ...contract,
                            contactHirer: person ? {
                                _id: person._id,
                                bsn: person.bsn,
                                email: person.email,
                                name: `${person.firstName} ${person.lastName}`,
                                telephone: person.phoneNumber,
                                function: person.function,
                                poNumber: ''
                            } : defaultPerson()
                        })}
                    />

                    <IconLabel icon={HistoryEduTwoTone} label="Tekenbevoegde" required sx={{ mt: 2 }} />
                    <PersonInputList
                        list={authorizedSignatoriesHirer.map(a => ({ name: a.firstName + " " + a.lastName, id: a._id }))}
                        selected={contract.authorizedSignatoriesHirer.map(a => ({ name: a.name, id: a._id }))}
                        onSelect={id => onChange({
                            ...contract,
                            authorizedSignatoriesHirer: [
                                ...contract.authorizedSignatoriesHirer,
                                contractPerson(authorizedSignatoriesHirer.find(a => a._id === id)!)
                            ]
                        })}
                        onRemove={id => onChange({
                            ...contract,
                            authorizedSignatoriesHirer: contract.authorizedSignatoriesHirer.filter(a => a._id !== id)
                        })}
                    />


                    <IconLabel icon={HowToRegTwoTone} label="Urenverantwoordelijke" required />
                    <PersonInputList
                        list={timesheetApprovers.map(a => ({ name: a.firstName + " " + a.lastName, id: a._id }))}
                        selected={contract.timesheetApprovers.map(a => ({ name: a.name, id: a._id }))}
                        onSelect={id => onChange({
                            ...contract,
                            timesheetApprovers: [
                                ...contract.timesheetApprovers,
                                contractPerson(timesheetApprovers.find(a => a._id === id)!)
                            ]
                        })}
                        onRemove={id => onChange({
                            ...contract,
                            timesheetApprovers: contract.timesheetApprovers.filter(a => a._id !== id)
                        })}
                    />

                    {
                        contract.timesheetApprovers.length > 1 && <ApproverModeInput
                            selected={contract.mode}
                            onChange={mode => onChange({ ...contract, mode })}
                        />
                    }

                    <Box mb={2} />
                    <IconLabel icon={ImportContactsTwoTone} label="Divisie" required />
                    <AsyncAutocomplete
                        key={'contractDivision'}
                        options={divisions}
                        selected={contract.division}
                        getTitleFromOption={division => division?.name}
                        onSelect={division => {
                            if (!division)
                                return;

                            onChange({
                                ...contract,
                                division
                            })
                        }
                        }
                    />

                    <TextInput
                        icon={SchoolTwoTone}
                        label="Recruiter"
                        optional
                        sx={{ flex: 1, minWidth: 200, my: 2 }}
                        value={contract.recruiter}
                        onChange={e => onChange({ ...contract, recruiter: e })}
                    />


                    <Box display={'flex'} alignItems={'center'} mt={2}>
                        <Checkbox
                            size="small"
                            sx={{ ml: -1 }}
                            title={'Inlener is eindklant'}
                            checked={hirerIsEndClient}
                            onChange={toggleHirerIsEndClient}
                        />
                        <Typography variant="overline" fontSize={10}>
                            Inlener is eindklant
                        </Typography>
                    </Box>
                </Box>

                <Box width={16} />


                <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: 300, mb: 2 }}>
                    <Typography variant="overline">Leverancier gegevens</Typography>
                    <Box mb={2} />

                    <IconLabel icon={TransferWithinAStationTwoTone} label="Leverancier" required />
                    <AsyncAutocomplete
                        options={companies}
                        getTitleFromOption={(company) => `${company.naam} - ${company.address.volledigAdres}`}
                        onSelect={
                            (company) => {
                                company && onChange({
                                    ...contract, supplier: {
                                        _id: company._id,
                                        address: company.address.volledigAdres,
                                        kvk: company.kvkNummer,
                                        locationNumber: company.vestigingsNummer,
                                        traderName: company.handelsnamen.find(_ => true)?.naam ?? '',
                                    }
                                });
                            }
                        }
                    />

                    <Box mt={2} />

                    <IconLabel icon={HandymanTwoTone} label="Professional" required />
                    <AsyncAutocomplete
                        key={contract.supplier._id}
                        options={professionals}
                        selected={professionals.find(p => p._id === contract.professional._id)}
                        getTitleFromOption={prof => prof ? `${prof.firstName} ${prof.lastName} - ${prof.email}` : ''}
                        onSelect={prof => onChange({
                            ...contract, professional: prof ? {
                                _id: prof._id,
                                bsn: prof.bsn,
                                email: prof.email,
                                name: `${prof.firstName} ${prof.lastName}`,
                                telephone: prof.phoneNumber,
                                function: prof.function,
                                poNumber: ''
                            } : defaultPerson()
                        })}
                    />

                    <Box mb={2} />
                    <IconLabel icon={HistoryEduTwoTone} label="Tekenbevoegde" required sx={{ mt: 2 }} />
                    <PersonInputList
                        list={authorizedSignatoriesSupplier.map(a => ({ name: a.firstName + " " + a.lastName, id: a._id }))}
                        selected={contract.authorizedSignatoriesSupplier.map(a => ({ name: a.name, id: a._id }))}
                        onSelect={id => onChange({
                            ...contract,
                            authorizedSignatoriesSupplier: [
                                ...contract.authorizedSignatoriesSupplier,
                                contractPerson(authorizedSignatoriesSupplier.find(a => a._id === id)!)
                            ]
                        })}
                        onRemove={id => onChange({
                            ...contract,
                            authorizedSignatoriesSupplier: contract.authorizedSignatoriesSupplier.filter(a => a._id !== id)
                        })}
                    />
                </Box>
                <Box width={16} />

                {!hirerIsEndClient && <><Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: 300, mb: 2 }}>
                    <Typography variant="overline">Eindklant</Typography>
                    <Box mb={2} />

                    <IconLabel icon={BusinessTwoTone} label="Bedrijf" required />
                    <AsyncAutocomplete
                        sx={{ width: '100%' }}
                        getTitleFromOption={(option) => option?.naam ?? ''}
                        onChange={searchKvK}
                        selected={selectedCompany}
                        onSelect={(result) => {
                            if (!result)
                                return;

                            setSelectedCompany(result);
                            listVestigingen(result.kvkNummer);
                        }}
                        fetching={searching}
                        disabled={fetchingVestigingen}
                        options={searchResults}
                    />

                    <Box mt={2} />

                    <IconLabel icon={LocationOnTwoTone} label="Locatie" required />
                    <AsyncAutocomplete
                        sx={{ width: '100%' }}
                        getTitleFromOption={(option) => option!.volledigAdres}
                        options={vestigingen}
                        onSelect={v => v && onSelectVestiging(v)}
                        required
                        selected={selectedVestiging}
                    />

                    <Box mt={2} />

                    <TextInput
                        icon={HomeTwoTone}
                        label="Vestigingsnummer"
                        value={selectedVestiging?.vestigingsnummer}
                        disabled
                    />
                </Box>
                    <Box width={16} />
                </>
                }

            </Box>
        </Box>
    }
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { ContainedButton, FormStepper, PrevNextButtons, RequestedDocumentPicker, StepProps } from "../../molecules";
import { ContractAssignmentEditForm, ContractDetailsEditForm, ContractFinancialEditForm, ContractTypeEditForm } from "../../forms/contract";
import { Contracts, defaultInhuurContract, defaultTaoContract, defaultZzpContract, InhuurContract, TaoContract, ZzpContract } from "../../../types/contracts/Contracts";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { companiesSelector } from "../../../store/slices/companiesSlice";
import { personsSelector } from "../../../store/slices/personsSlice";

import { defaultPerson, ContractPerson } from "../../../types/contracts/Person";
import { Membership, MembershipRole } from "../../../types/company/membership";
import { CAO, defaultReference, Division } from "../../../types/contracts";

import membershipsApi from "../../../api/organization/memberships";
import divisionsApi from "../../../api/contracts/divisions";
import caoApi from "../../../api/caoApi";
import invoiceRefsApi from "../../../api/contracts/references";
import defaultValuesApi from "../../../api/contracts/defaultValues";

import { ContractInvoiceEditForm } from "../../forms/contract/ContractInvoiceDetailsEdit";
import { contractsSelector, createContract } from "../../../store/slices/contractsSlice";
import { authSelector } from "../../../store/slices/authSlice";
import { parseAddress } from "../../../utils/strings";
import { useNavigate } from "react-router-dom";
import { contractenRoutes } from "../../../router/paths";
import { ListUsedFor, ListItemUsedFor, DocumentType, DocumentState, DocumentModule } from "../../../types/documents";
import { Box } from "@mui/material";
import { RemoveRedEyeTwoTone } from "@mui/icons-material";
import { generateContractMergeFields } from "../../../utils/contractMergeFields";
import { previewDocumentInBrowser } from "../../../utils/downloadFile";
import { LoadingButton } from "@mui/lab";

import api from "../../../api/documents/pdfs";

export const ContractCreateOrganism: FC = () => {
    const nav = useNavigate();
    const dispatch = useAppDispatch();
    const companies = useAppSelector(companiesSelector).companies;
    const persons = useAppSelector(personsSelector).persons;
    const user = useAppSelector(authSelector).user;
    const contractsState = useAppSelector(contractsSelector);

    const [supplierMemberships, setSupplierMemberships] = useState<Membership[]>([]);
    const [selectedDocuments, setSelectedDocuments] = useState<{
        _id: string,
        target: ListItemUsedFor
    }[]>([]);
    const [hirerMemberships, setHirerMemberships] = useState<Membership[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [caoList, setCaoList] = useState<CAO[]>([]);
    const [contractType, setContractType] = useState<Contracts>(Contracts.Zzp);
    const [contract, setContract] = useState<TaoContract | ZzpContract | InhuurContract>(defaultZzpContract());
    const [step, setStep] = useState(0);
    const [fetchingPreview, setFetchingPreview] = useState(false);
    const hasFetchedRefs = useRef(false);

    const findDefaultPersonByRole = useCallback((memberships: Membership[], role: MembershipRole): ContractPerson => {
        const personIds = memberships.filter(m => m.roles.includes(role)).map(m => m.personId);
        if (personIds.length === 0)
            return defaultPerson();

        const person = persons.find(p => p._id === personIds[0]);
        if (!person)
            return defaultPerson();

        return {
            _id: person._id,
            name: person.firstName + ' ' + person.lastName,
            email: person.email,
            bsn: person.bsn,
            telephone: person.phoneNumber,
            function: person.function,
            poNumber: ''
        }
    }, [persons]);

    useEffect(() => {
        if (hasFetchedRefs.current === true)
            return;

        divisionsApi.list().then(setDivisions);
        invoiceRefsApi.list().then(r => r.map(defaultReference))
            .then(invoiceRefs => {
                contract.invoiceDetails.customerInvoiceDetails.references = invoiceRefs;
                setContract(c => ({
                    ...c,
                    invoiceDetails: c.invoiceDetails
                }));
            })
            .finally(() => hasFetchedRefs.current = true);
    }, [contract.invoiceDetails.customerInvoiceDetails]);

    useEffect(() => {
        if (!user) return;


        setContract(c => ({
            ...c,
            contractManager: user.email,
        }));
    }, [user]);

    useEffect(() => {
        if (contractType !== Contracts.Tao || caoList.length > 0)
            return;

        caoApi.getCAOData().then(setCaoList);
    }, [contractType, caoList.length]);

    useEffect(() => {
        defaultValuesApi.get(contractType.toString()).then(defaults => setContract(c => {
            return {
                ...defaults,
                _id: '',
                contractManager: c.contractManager,
                invoiceDetails: c.invoiceDetails
            }
        }));
    }, [contractType]);

    useEffect(() => {
        if (!contract?.supplier._id || !contract?.supplier.kvk)
            return;


        membershipsApi
            .listCompanyMemberships(contract.supplier._id)
            .then(memberships => {
                setSupplierMemberships(memberships);
                setContract(c => ({
                    ...c!,
                    authorizedSignatoriesSupplier: [findDefaultPersonByRole(memberships, MembershipRole.AuthorizedSignatory)],
                    professional: defaultPerson()
                }));
            });

    }, [contract?.supplier._id, contract?.supplier.kvk, findDefaultPersonByRole]);

    useEffect(() => {
        if (!contract?.hirer._id || !contract?.hirer.kvk)
            return;


        membershipsApi
            .listCompanyMemberships(contract.hirer._id)
            .then(memberships => {
                setHirerMemberships(memberships);
                setContract(c => ({
                    ...c!,
                    authorizedSignatoriesHirer: [findDefaultPersonByRole(memberships, MembershipRole.AuthorizedSignatory)],
                    timesheetApprovers: [findDefaultPersonByRole(memberships, MembershipRole.TimesheetApprover)]
                }));
            });

    }, [contract?.hirer._id, contract?.hirer.kvk, findDefaultPersonByRole]);

    useEffect(() => {
        if (step !== 2)
            return;

        const addressParsed = parseAddress(contract.endCustomer.address);
        const endCustomerOrg = companies.find(c => c._id === contract.endCustomer._id);

        setContract(c => ({
            ...c!,
            assignment: {
                ...c?.assignment,
                location: addressParsed.city || '',
            },
            invoiceDetails: {
                ...c?.invoiceDetails,
                customerInvoiceDetails: {
                    ...c?.invoiceDetails.customerInvoiceDetails,
                    address: addressParsed.street + ' ' + addressParsed.houseNumber,
                    city: addressParsed.city || '',
                    country: 'Nederland',
                    zipCode: addressParsed.zipcode || '',
                    email: endCustomerOrg?.billingAddress || '',
                    phone: endCustomerOrg?.phone || '',
                    name: endCustomerOrg?.naam ?? contract.endCustomer.traderName ?? ''
                }
            }
        }));

    }, [step, contract.endCustomer.address, contract.endCustomer._id, companies, contract.endCustomer.traderName]);

    const handleSetContractingType = (value: Contracts) => {
        if (value === contract?.contractKind)
            return setStep(step + 1);

        setContractType(value);

        switch (value) {
            case Contracts.Zzp:
                setContract(c => ({
                    ...defaultZzpContract(),
                    contractManager: c.contractManager,
                    invoiceDetails: contract.invoiceDetails
                }));
                break;
            case Contracts.Tao:
                setContract(c => ({
                    ...defaultTaoContract(),
                    contractManager: c.contractManager,
                    invoiceDetails: contract.invoiceDetails
                }));
                break;
            case Contracts.Inhuur:
                setContract(c => ({
                    ...defaultInhuurContract(),
                    contractManager: c.contractManager,
                    invoiceDetails: contract.invoiceDetails
                }));
                break;
        }

        setStep(step + 1);
    }

    const onCreate = async () => {
        const result = await dispatch(createContract({ contract, selectedDocuments: selectedDocuments.map(d => d._id) }));
        if (result.meta.requestStatus === 'fulfilled') {
            nav(contractenRoutes.list);
        }
    }

    const onSelectDocuments = (docs: {
        _id: string,
        target: ListItemUsedFor
    }[]) => {
        setSelectedDocuments(selectedDocuments.concat(docs.filter(d => !selectedDocuments.map(sd => sd._id).includes(d._id))))
    }

    const onDeselectDocuments = (ids: string[]) => {
        setSelectedDocuments(selectedDocuments.filter(d => !ids.includes(d._id)));
    }

    const previewSupplierDocument = (target: ListItemUsedFor) => async () => {
        setFetchingPreview(true);
        const documentIds = selectedDocuments.filter(d => d.target === target).map(d => d._id);
        const pdfData = await api.previewMergeResult({
            documentRefs: documentIds,
            fields: generateContractMergeFields(contract),
            signers: [],
            item: {
                _id: '',
                description: '',
                state: DocumentState.Requested,
                awaitingSignatureFrom: '',
                title: 'Overeenkomst Opdracht: ' + contract.contractnumber,
                type: DocumentType.DocuSign,
                managedBy: {
                    module: DocumentModule.Company,
                    targetId: ''
                },
                linkedTo: {
                    module: DocumentModule.Contract,
                    targetId: ''
                },
                updatedAt: Date.now()
            }
        });

        previewDocumentInBrowser(pdfData, contract.contractnumber + '.pdf');

        setFetchingPreview(false);
    }

    const steps: StepProps[] = [
        {
            label: 'Kies de vorm van het contract',
            content: <ContractTypeEditForm
                value={contractType}
                onChange={handleSetContractingType}
            />
        },
        {
            label: 'Professional en Organisatie(s)',
            content: <ContractDetailsEditForm
                hirerMembers={persons.filter(p => hirerMemberships.some(x => x.personId === p._id))}
                contract={contract}
                divisions={divisions}
                professionals={persons.filter(p => supplierMemberships.some(x => x.personId === p._id))}
                authorizedSignatoriesSupplier={persons.filter(p => supplierMemberships.some(x => x.personId === p._id))}
                authorizedSignatoriesHirer={persons.filter(p => hirerMemberships.some(x => x.personId === p._id))}
                timesheetApprovers={persons.filter(p => hirerMemberships.some(x => x.personId === p._id))}
                companies={companies}
                onChange={setContract}
            />
        },
        {
            label: 'Werkzaamheden',
            content: <ContractAssignmentEditForm
                value={contract.assignment}
                onChange={assignment => setContract(c => ({ ...c!, assignment }))}
            />
        },
        {
            label: 'Financiele afspraken',
            content: <ContractFinancialEditForm
                caoList={caoList}
                organizations={companies.map(c => ({ label: c.naam, id: c._id }))}
                financial={contract.financial}
                onChange={financial => {
                    setContract(c => ({ ...c, financial }))
                }}
            />
        },
        {
            label: 'Factuur gegevens',
            content: <ContractInvoiceEditForm
                hideSupplier={contractType === Contracts.Tao}
                invoiceDetails={contract.invoiceDetails}
                onChange={invoiceDetails => setContract(c => ({ ...c, invoiceDetails }))}
            />
        },
        {
            label: 'Benodigde Documenten',
            content: <RequestedDocumentPicker
                listType={ListUsedFor.Contract}
                selected={selectedDocuments.map(d => d._id)}
                onSelect={onSelectDocuments}
                onDeselect={onDeselectDocuments}
            />
        }
    ];

    return <FormStepper
        maxWidth="xl"
        steps={steps}
        activeStep={step}
        onStepClicked={setStep}
    >
        <PrevNextButtons
            variant="text"
            onPrev={() => setStep(step - 1)}
            onNext={() => setStep(step + 1)}
            showNext={step < steps.length - 1}
            showPrev={step > 0}
        />

        {
            step === steps.length - 1 && <Box>
                <LoadingButton disabled={!selectedDocuments.some(d => d.target === ListItemUsedFor.Supplier)} loading={fetchingPreview} onClick={previewSupplierDocument(ListItemUsedFor.Supplier)} variant="outlined"><RemoveRedEyeTwoTone sx={{ mr: 1 }} /> Leverancier document (Preview)</LoadingButton><br />
                <LoadingButton disabled={!selectedDocuments.some(d => d.target === ListItemUsedFor.Hirer)} loading={fetchingPreview} onClick={previewSupplierDocument(ListItemUsedFor.Hirer)} sx={{ mt: 1 }} variant="outlined"><RemoveRedEyeTwoTone sx={{ mr: 1 }} />Inlener document (Preview)</LoadingButton>< br />

                <ContainedButton sx={{ mt: 2 }} onClick={onCreate} loading={contractsState.fetchingCreate === 'fetching'}>
                    Maak contract aan
                </ContainedButton>
            </Box>
        }
    </FormStepper>
}
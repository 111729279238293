import { styled } from "@mui/system";
import { Box, Button, SvgIconTypeMap, SxProps, Theme, Typography } from "@mui/material";
import { IconLabel } from "../IconLabel";
import { useEffect, useRef, useState } from "react";
import { FieldValidation } from "../../validations";
import { ContentCopyTwoTone } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

const Container = styled(Box)({
    background: '#eee',
    borderRadius: 4,
    display: 'flex'
});

const Input = styled('input')(({ theme }) => ({
    background: '#eee',
    boxSizing: 'border-box',
    flex: 1,
    borderRadius: 4,
    borderWidth: 0,
    paddingLeft: 8,
    paddingRight: 8,
    height: 30,
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.primary.secondary,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
}));

const Area = styled('textarea')(({ theme }) => ({
    background: '#eee',
    boxSizing: 'border-box',
    flex: 1,
    borderRadius: 4,
    borderWidth: 0,
    padding: 8,
    minHeight: 30,
    height: 30,
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.primary.secondary,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    resize: 'none',
    overflow: 'hidden'
}));

export type TextInputProps = {
    onRightClick?: (ev: React.MouseEvent) => void;
    label?: string,
    placeholder?: string,
    autoFocus?: boolean,
    value?: any,
    icon?: any,
    type?: React.HTMLInputTypeAttribute | 'double',
    onChange?: (value: any) => void,
    onSubmit?: () => void,
    sx?: SxProps<Theme>;
    inputSx?: SxProps<Theme>;
    tooltip?: string;
    required?: boolean;
    optional?: boolean;
    subtitle?: string;
    onFocusLost?: () => void;
    rightNeighbour?: React.ReactNode;
    leftNeighbour?: React.ReactNode;
    validator?: FieldValidation;
    disabled?: boolean;
    withCopy?: boolean;
}

export function TextInput(props: TextInputProps) {
    const { label } = props;
    const [exitedFocus, setExitedFocus] = useState(false);
    const error = exitedFocus && props.validator && props.validator(props.value as any);
    const handleFocusLost = () => {
        props.onFocusLost?.();
        let newValue = typeof (props.value) == 'string' ? props.value.replace(/^0+/, '') : props.value;

        if (typeof (newValue) === 'number')
            newValue = `${newValue}`;

        if (props.type === 'double' && props.onChange) {
            if (!newValue?.length)
                return props.onChange('0.00');

            if (newValue.indexOf(".") === -1)
                return props.onChange(newValue);

            const parts = newValue.split('.');
            if (parts[1].length === 1)
                props.onChange(newValue + '0');

            if (parts[1].length === 0)
                props.onChange(newValue + '00');
        }

        if (!props.validator)
            return;

        setExitedFocus(true);
    }

    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!props.onChange)
            return;

        if (props.type === 'double')
            return handleDoubleChange(ev);

        props.onChange(
            props.type === 'number'
                ? parseInt(ev.currentTarget.value)
                : ev.currentTarget.value)
    }

    const handleDoubleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!props.onChange) return;

        let value = ev.currentTarget.value
            .replaceAll(',', '.')
            .replace(/[^0-9.-]/g, '');

        const decimalParts = value.split('.');
        if (decimalParts.length > 2)
            return;

        if (decimalParts.length === 2 && decimalParts[1].length > 2)
            return;

        if (value.indexOf('-') > 0) {
            value = value.replace(/-/g, '');
            value = '-' + value;
        } else if (value.lastIndexOf('-') > 0)
            value = value.replace(/-/g, '');

        if (value === '')
            return props.onChange(value);

        const parsedValue = parseFloat(value);

        if (!isNaN(parsedValue))
            props.onChange(value);
    }

    const handleKeyDown = (key: React.KeyboardEvent<HTMLInputElement>) => {
        if (key.key !== 'Enter' || !props.onSubmit || !props.value)
            return;

        props.onSubmit();
    }

    const copyToClipboard = () => {
        if (!props.value)
            return;

        navigator.clipboard.writeText(props.value);
    }

    return <Box sx={{ ...props.sx }}>
        {label && <IconLabel optional={props.optional} required={props.required} label={label} icon={props.icon} tooltip={props.tooltip} />}
        <Container sx={{ position: 'relative' }}>
            {props.leftNeighbour}
            <Input
                onClick={props.onRightClick}
                placeholder={props.placeholder}
                sx={props.inputSx as any}
                disabled={props.disabled || !props.onChange}
                autoFocus={props.autoFocus}
                onBlur={handleFocusLost}
                onKeyDown={handleKeyDown}
                type={props.type ?? 'text'}
                value={props.value ?? ''}
                onChange={handleChange} />
            {props.rightNeighbour}
            {
                props.withCopy && <Button sx={{ position: 'absolute', right: 0, minWidth: 0 }}>
                    <ContentCopyTwoTone onClick={copyToClipboard} sx={{ fontSize: 18 }} />
                </Button>
            }
        </Container>
        {
            error && <Typography color={'error'} fontSize={'.7rem'} variant="subtitle2">{error}</Typography>
        }
        {
            props.subtitle && !error && <Typography fontSize={'.7rem'} variant="subtitle2" color={'#7f8c8d'}>
                {props.subtitle}
            </Typography>
        }
    </Box>
}

export function TextArea(props: {
    label?: string,
    value?: string,
    icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
    },
    onChange?: (value: string) => void,
    sx?: SxProps<Theme>;
    required?: boolean;
    validator?: FieldValidation;
    inputSx?: SxProps;
}) {
    const { label } = props;
    const areaRef = useRef<HTMLTextAreaElement>(null);
    const [exitedFocus, setExitedFocus] = useState(false);
    const error = exitedFocus && props.validator && props.validator(props.value as any);
    const handleFocusLost = () => {
        if (!props.validator)
            return;

        setExitedFocus(true);
    }

    useEffect(() => {
        if (!areaRef.current)
            return;

        areaRef.current.style.height = '0px';
        areaRef.current.style.height = (areaRef.current.scrollHeight + 20) + 'px';
    }, [props.value]);


    return <Box sx={props.sx}>
        {label && <IconLabel label={label} icon={props.icon} required={props.required} />}
        <Container>
            <Area disabled={!props.onChange} sx={props.inputSx} onBlur={handleFocusLost} ref={areaRef} value={props.value} onChange={ev => {
                if (!props.onChange)
                    return;

                props.onChange(ev.currentTarget.value)

            }} />
        </Container>
        {
            error && <Typography color={'error'} fontSize={'.7rem'} variant="subtitle2">{error}</Typography>
        }
    </Box>
}
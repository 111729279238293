import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { Box, Chip, Fade, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { ReactNode, useEffect, useState } from "react";
import { retrieveDisabledColumns, retrievePageSize, storeDisabledColumns, storePageSize } from "../../../utils/cache";

export type RowValueGetter<T> = (row: { data: T }) => any;
export type ColumnProps<T> = {
    field?: string;
    headerName?: string;
    flex?: number;
    width?: number;
    minWidth?: number;
    suppressSizeToFit?: boolean;
    cellRenderer?: ({ data }: T & any) => ReactNode;
    valueGetter?: RowValueGetter<T>;
    sort?: 'asc' | 'desc';
    filter?: 'agTextColumnFilter',
    floatingFilter?: boolean,
};

export function Table<T>(props: {
    id: string;
    paging?: boolean,
    slim?: boolean;
    onRowClicked?: (e: T) => void;
    columns: ColumnProps<T>[];
    data: T[];
    shrink?: boolean;
    autoSizeColumns?: boolean;
    rowHeight?: number;
    hideFilters?: boolean;
}) {
    const [showFilters, setShowFilters] = useState(false);
    const [columns, setColumns] = useState(props.columns.map(c => c.headerName));

    useEffect(() => {
        if (props.hideFilters)
            return;

        const disabledColumns = retrieveDisabledColumns(props.id);
        const allowedColumns = props.columns.map(c => c.headerName);
        const filteredColumns = allowedColumns.filter(c => !disabledColumns.includes(c ?? ''));

        setColumns(filteredColumns);
    }, [props.id, props.columns, props.hideFilters]);

    const onColumnClicked = (name: string) => () => {
        const column = columns.find(c => c === name);
        let visibleColumns: string[] = [];

        if (!column)
            visibleColumns = [...(columns as string[]), name];
        else
            visibleColumns = columns.filter(c => c !== name) as string[];

        if (visibleColumns.length === 0)
            return;

        setColumns(visibleColumns);

        const disabledColumns = props.columns.map(c => c.headerName).filter(c => c && !visibleColumns.includes(c ?? ''));
        storeDisabledColumns(props.id, disabledColumns as string[]);
    }

    const initialPageSize = retrievePageSize();

    const columnEnabled = (col: ColumnProps<T>) => columns.includes(col.headerName);
    const columnDefs = props.columns.filter(c => columnEnabled(c));
    const shouldAppendFlex = columnDefs.filter(c => c.flex && c.flex > 0).length === 0;

    if (shouldAppendFlex)
        columnDefs[columnDefs.length - 1].flex = 1;

    return (
        <>
            {props.columns.length > 2 && !props.hideFilters && <div style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer', width: 'auto', marginTop: 8 }} onClick={() => setShowFilters(!showFilters)}>
                <Typography variant="overline" color={'secondary'}>Weergave aanpassen</Typography>
                {
                    showFilters
                        ? <ExpandMore color={'primary'} />
                        : <ChevronRight color={'primary'} />
                }
            </div>}
            <Fade in={showFilters}>
                <Box sx={{ py: showFilters ? 1 : 0, display: 'flex', flexWrap: 'wrap' }}>
                    {
                        showFilters && props.columns.filter(c => c.headerName).map(c => <div key={c.headerName} onClick={onColumnClicked(c.headerName!)}>
                            <Chip label={c.headerName} style={columnEnabled(c) ? undefined : { backgroundColor: '#e74c3c52' }} />
                        </div>
                        )
                    }
                </Box>
            </Fade>
            <Box key={columnDefs.length} className="ag-theme-material" style={{ height: props.shrink !== true ? '100%' : 'unset', borderWidth: 0 }}>
                <AgGridReact
                    pagination={props.paging}
                    paginationPageSize={initialPageSize}
                    onPaginationChanged={e => storePageSize(e.api.paginationGetPageSize())}
                    className={props.slim ? 'slim' : ''}
                    suppressCellFocus={true}
                    noRowsOverlayComponent={'Geen resultaten'}
                    overlayNoRowsTemplate="Geen resultaten"
                    rowData={props.data}
                    rowHeight={props.rowHeight}
                    headerHeight={props.rowHeight}
                    autoSizeStrategy={props.autoSizeColumns ? {
                        type: 'fitCellContents'
                    } : undefined}
                    domLayout="autoHeight"
                    onRowClicked={e => e.data && props.onRowClicked?.(e.data)}
                    columnDefs={columnDefs as any} />
            </Box>
        </>
    );
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FetchingState } from '../../types/fetchingState';
import { showToast } from './toastSlice';
import { BaseContract, InhuurContract, TaoContract, ZzpContract } from '../../types/contracts';
import { RootState } from '..';

import contractsApi from '../../api/contracts';
import documentListsApi from '../../api/documents/lists';
import documentsApi from '../../api/documents/requested';
import { generateContractMergeFields } from '../../utils/contractMergeFields';
import { RequestSignaturesPayload } from '../../types/documents/RequestSignaturesPayload';
import { DocumentState, DocumentType, DocumentModule } from '../../types/documents/BaseDocument';
import { FileUploadRequest, ListItemUsedFor } from '../../types/documents';

export interface ContractsState {
    contracts: BaseContract[];
    fetchingList: FetchingState;
    fetchingCreate: FetchingState;
}

const intialState: ContractsState = {
    contracts: [],
    fetchingList: "init",
    fetchingCreate: "init"
}

export const listContracts = createAsyncThunk(
    'contracts/list',
    async (_, thunkApi) => {
        try {
            const contracts = await contractsApi.list();
            return contracts;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

const createRequestedDocuments = async (ids: string[], c: BaseContract) => {
    const lists = await documentListsApi.list();
    const documents = lists.flatMap(l => l.items);

    const fileUploadRequests: FileUploadRequest[] = [];
    const mergeFields = generateContractMergeFields(c);

    const supplierMergeRequest: RequestSignaturesPayload = {
        documentRefs: [],
        signers: [],
        fields: mergeFields,
        item: {
            _id: '',
            title: 'Overeenkomst Opdracht: ' + c.contractnumber,
            awaitingSignatureFrom: '',
            description: '',
            state: DocumentState.Requested,
            type: DocumentType.DocuSign,
            managedBy: {
                module: DocumentModule.Company,
                targetId: c.supplier._id
            },
            linkedTo: {
                module: DocumentModule.Contract,
                targetId: c._id
            },
            updatedAt: Date.now()
        }
    }

    const hirerMergeRequest: RequestSignaturesPayload = {
        documentRefs: [],
        signers: [],
        fields: mergeFields,
        item: {
            _id: '',
            title: 'Overeenkomst Opdracht: ' + c.contractnumber,
            awaitingSignatureFrom: '',
            description: '',
            state: DocumentState.Requested,
            type: DocumentType.DocuSign,
            managedBy: {
                module: DocumentModule.Company,
                targetId: c.hirer._id
            },
            linkedTo: {
                module: DocumentModule.Contract,
                targetId: c._id
            },
            updatedAt: Date.now()
        }
    }

    ids.forEach(documentId => {
        const item = documents.find(d => (d._id === documentId));
        if (!item)
            return;

        let targetId = '';

        switch (item.usedFor) {
            case ListItemUsedFor.Supplier:
                targetId = c.supplier._id;
                break;
            case ListItemUsedFor.Hirer:
                targetId = c.hirer._id;
                break;
            case ListItemUsedFor.Person:
                targetId = c.professional._id;
                break;
        }

        if (item.type === DocumentType.DocuSign) {
            if (item.usedFor === ListItemUsedFor.Supplier)
                supplierMergeRequest.documentRefs.push(item.documentReferenceId!);

            if (item.usedFor === ListItemUsedFor.Hirer)
                hirerMergeRequest.documentRefs.push(item.documentReferenceId!);
        }

        if (item.type === DocumentType.FileUpload) {
            fileUploadRequests.push({
                ...item,
                _id: '',
                managedBy: {
                    module: DocumentModule.Company,
                    targetId: targetId
                },
                linkedTo: {
                    module: DocumentModule.Contract,
                    targetId: c._id
                },
                state: DocumentState.Requested,
                updatedAt: Date.now()
            })
        }
    });

    if (supplierMergeRequest.documentRefs.length > 0)
        await documentsApi.requestSignatures({
            ...supplierMergeRequest,
            signers: [
                ...c.authorizedSignatoriesSupplier.map(s => ({ email: s.email, name: s.name })),
                { email: "dev@compliancefactory.nl", name: "R&D CF" }
            ]
        });

    if (hirerMergeRequest.documentRefs.length > 0)
        await documentsApi.requestSignatures({
            ...hirerMergeRequest,
            signers: [
                ...c.authorizedSignatoriesHirer.map(s => ({ email: s.email, name: s.name })),
                { email: "dev@compliancefactory.nl", name: "R&D CF" }
            ]
        });

    if (fileUploadRequests.length > 0)
        await Promise.all(fileUploadRequests.map(r => documentsApi.requestFileUpload(r)));
}

export const createContract = createAsyncThunk(
    'contracts/create',
    async (
        args: {
            contract: ZzpContract | TaoContract | InhuurContract,
            selectedDocuments: string[]
        },
        thunkApi
    ) => {
        try {
            const contract = await contractsApi.create(args.contract);
            await createRequestedDocuments(args.selectedDocuments, contract);

            thunkApi.dispatch(showToast({ message: "Contract aangemaakt", type: "success" }));

            return contract;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));

            throw e;
        }
    }
)

export const contractsSlice = createSlice({
    name: 'contracts',
    initialState: intialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(listContracts.pending, (state) => { state.fetchingList = "fetching"; })
            .addCase(listContracts.rejected, (state) => { state.fetchingList = "error"; })
            .addCase(listContracts.fulfilled, (state, action: any) => {
                state.contracts = [...action.payload];
                state.fetchingList = "completed";
            });

        builder
            .addCase(createContract.pending, (state) => { state.fetchingCreate = "fetching"; })
            .addCase(createContract.rejected, (state) => { state.fetchingCreate = "error"; })
            .addCase(createContract.fulfilled, (state, action: any) => {
                state.contracts = [...state.contracts, action.payload];
                state.fetchingCreate = "completed";
            });
    }
})

export const contractsSelector = (state: RootState) => state.contracts;
export default contractsSlice.reducer;
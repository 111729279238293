import { Permissions } from "../types/user";

export const recruiterRoutes = {
    root: '/recruiter',
    settings: '/recruiter/instellingen',
    followUp: (id: string) => '/recruiter/instellingen/follow-up/' + id,
    followUpCreate: '/recruiter/instellingen/follow-up/aanmaken',

    vacatures: {
        list: '/recruiter/vacatures',
        create: '/recruiter/vacatures/aanmaken',
        details: (id: string) => '/recruiter/vacatures/' + id
    },

    kandidaten: {
        list: '/recruiter/kandidaten',
        create: '/recruiter/kandidaten/aanmaken',
        details: (id: string) => '/recruiter/kandidaten/' + id
    },

    progress: {
        appliances: '/recruiter/voortgang',
    },

    inschrijven: (code: string) => '/inschrijven/' + code
}

export const organizationRoutes = {
    root: '/bedrijven',
    customers: '/bedrijven/klanten',
    all: '/bedrijven/alle',
    suppliers: '/bedrijven/leveranciers',
    create: '/bedrijven/aanmaken',
    details: (id: string) => '/bedrijven/' + id
}

export const personRoutes = {
    root: '/personen',
    list: '/personen/alle',
    self: '/mijn-gegevens',
    professionals: '/personen/professionals',
    tekenbevoegden: '/personen/tekenbevoegden',
    contactpersonen: '/personen/contactpersonen',
    create: '/personen/aanmaken',
    personInvite: '/:environment/invite/:code',
    credentialsInvite: '/:environment/credentials/:code',
    registered: '/registered',
    details: (id: string) => '/personen/' + id
}

export const contractenRoutes = {
    root: '/contracten',
    list: '/contracten/alle',
    create: '/contracten/aanmaken',
    details: (id: string) => '/contracten/' + id
}

export const timeRegistrationRoutes = {
    root: '/urenregistraties',
    list: '/urenregistraties'
}

export const genericRoutes = {
    login: '/inloggen',
    resetPassword: '/reset-password/:code',
    dashboard: '/dashboard',
}

export const settingRoutes = {
    root: '/instellingen',
    users: '/instellingen/beheerders',
    emailTemplates: '/instellingen/email-berichten',
    emailTemplate: (id: string) => '/instellingen/email-templates/' + id,
    owner: '/instellingen/eigenaar',

    documents: '/instellingen/documenten',

    documentLists: '/instellingen/document-lijsten',
    documentList: (id: string) => '/instellingen/document-lijsten/' + id,

    forms: '/instellingen/formulieren',
    form: (id: string) => '/instellingen/formulieren/' + id,

    pdfs: '/instellingen/pdf',
    pdf: (id: string) => '/instellingen/pdf/' + id,

    documentStore: '/instellingen/documenten-winkel',
    documentStoreItem: (id: string, type: string) => `/instellingen/documenten-winkel/${type}/${id}`,

    categories: '/instellingen/categorieen',
    category: (id: string) => '/instellingen/categorieen/' + id,


    contracts: '/instellingen/contracten',
    contractReferences: '/instellingen/contracten/referenties',
    contractDefaultValuesSelector: '/instellingen/contracten/standaard-waardes',
    contractDefaultValues: (type: string) => '/instellingen/contracten/standaard-waardes/' + type,

}

export const pathPermissions = {
    [genericRoutes.dashboard]: [Permissions.DashboardViewer],
    //Settings
    [settingRoutes.owner]: [Permissions.WhitelabelOwnerSettingsAdmin],
    [settingRoutes.users]: [Permissions.WhitelabelPermissionsAdmin],
    [settingRoutes.documents]: [Permissions.DocumentManagementAdmin],
    [settingRoutes.documentLists]: [Permissions.DocumentManagementAdmin],
    [settingRoutes.documentList(":id")]: [Permissions.DocumentManagementAdmin],
    [settingRoutes.forms]: [Permissions.DocumentManagementAdmin],
    [settingRoutes.form(":id")]: [Permissions.DocumentManagementAdmin],
    [settingRoutes.pdfs]: [Permissions.DocumentManagementAdmin],
    [settingRoutes.pdf(":id")]: [Permissions.DocumentManagementAdmin],
    [settingRoutes.documentStore]: [Permissions.DocumentManagementAdmin],
    [settingRoutes.documentStoreItem(":id", ":type")]: [Permissions.DocumentManagementAdmin],
    [settingRoutes.categories]: [Permissions.CompanyReader],
    [settingRoutes.category(":id")]: [Permissions.CompanyReader],
    [settingRoutes.emailTemplates]: [Permissions.EmailTemplatesAdmin],
    [settingRoutes.emailTemplate(":id")]: [Permissions.EmailTemplatesAdmin],
    [settingRoutes.contracts]: [Permissions.ContractSettingsAdmin],
    [settingRoutes.contractReferences]: [Permissions.ContractSettingsAdmin],
    [settingRoutes.contractDefaultValuesSelector]: [Permissions.ContractSettingsAdmin],
    [settingRoutes.contractDefaultValues(":type")]: [Permissions.ContractSettingsAdmin],

    //Recruiter
    [recruiterRoutes.vacatures.list]: [Permissions.VacatureReader, Permissions.VacaturePublisher],
    [recruiterRoutes.vacatures.create]: [Permissions.VacatureWriter],
    [recruiterRoutes.vacatures.details(":id")]: [Permissions.VacatureWriter],
    [recruiterRoutes.kandidaten.list]: [Permissions.KandidaatReader],
    [recruiterRoutes.kandidaten.details(":id")]: [Permissions.KandidaatReader],
    [recruiterRoutes.kandidaten.create]: [Permissions.KandidaatWriter],
    [recruiterRoutes.progress.appliances]: [Permissions.VacatureReader],
    [recruiterRoutes.settings]: [Permissions.KandidaatWriter, Permissions.VacatureWriter],

    //Organizations
    [organizationRoutes.customers]: [Permissions.CompanyReader],
    [organizationRoutes.suppliers]: [Permissions.CompanyReader],
    [organizationRoutes.all]: [Permissions.CompanyReader],
    [organizationRoutes.details(":id")]: [Permissions.CompanyReader],
    [organizationRoutes.create]: [Permissions.CompanyWriter],

    //Persons
    [personRoutes.list]: [Permissions.PersonReader],
    [personRoutes.contactpersonen]: [Permissions.PersonReader],
    [personRoutes.professionals]: [Permissions.PersonReader],
    [personRoutes.tekenbevoegden]: [Permissions.PersonReader],
    [personRoutes.details(":id")]: [Permissions.PersonReader],
    [personRoutes.create]: [Permissions.PersonWriter],
    [personRoutes.self]: [Permissions.SelfReader],

    //Contracts
    [contractenRoutes.list]: [Permissions.ContractReader],
    [contractenRoutes.details(":id")]: [Permissions.ContractReader, Permissions.TimesheetReader, Permissions.TimesheetApprover],
    [contractenRoutes.create]: [Permissions.ContractWriter],
    [timeRegistrationRoutes.list]: [Permissions.TimesheetReader, Permissions.TimesheetApprover]
}
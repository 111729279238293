import { configureStore } from '@reduxjs/toolkit'

import auth from './slices/authSlice'

import professionals from './slices/recruiter/professionalsSlice'
import relations from './slices/recruiter/relationsSlice'
import vacancies from './slices/recruiter/vacanciesSlice'
import relationStates from './slices/recruiter/relationStatesSlice'
import followUpTemplates from './slices/recruiter/followUpTemplatesSlice'

import { combineReducers } from 'redux'
import persons from './slices/personsSlice'

import toast from './slices/toastSlice'
import companies from './slices/companiesSlice'
import tags from './slices/tagsSlice'
import heroTutorial from './slices/heroTutorialSlice'
import contracts from './slices/contractsSlice'

export const store = configureStore({
    reducer: {
        auth,
        recruiter: combineReducers({
            vacancies,
            professionals,
            relations,
            relationStates,
            followUpTemplates
        }),
        persons,
        toast,
        companies,
        tags,
        heroTutorial,
        contracts
    }
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
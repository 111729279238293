import ReactDOM from 'react-dom/client';
import { AppRouter } from './router';
import { Provider } from 'react-redux'
import { ApplicationThemeProvider } from './ui/theme';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { store } from './store';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import dayjs from 'dayjs';
import "dayjs/locale/nl";
import 'moment/locale/nl';
import "ag-grid-community/styles/ag-grid.min.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-material.min.css"; // Optional Theme applied to the grid

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
dayjs.locale('nl');

root.render(
  <ApplicationThemeProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='nl'>
      <DndProvider backend={HTML5Backend}>
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </DndProvider>
    </LocalizationProvider>
  </ApplicationThemeProvider>
);
import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { requiredValidation } from "../validations";
import { CardDialog } from "./Dialog";
import { TextInput } from "./inputs/TextInput";
import { StoreItemType } from "../../types/documents/Store";
import { SelectMolecule } from "./MultiSelect";
import { IconLabel } from "./IconLabel";
import { CompanyGroupType } from "../../types/company/companyGroup";
import { AbcTwoTone, FormatListBulleted, PanToolAltTwoTone, QuestionMarkTwoTone, SearchTwoTone, UploadTwoTone, VisibilityTwoTone } from "@mui/icons-material";

import { BaseItemTable } from "./tables/documents";
import { RowCentered } from "./Row";
import { ContainedButton } from "./Buttons";
import { generateGuid, onlyAllowUnderscores } from "../../utils/strings";

import pdfsApi from '../../api/documents/pdfs';
import formsApi from '../../api/documents/forms';
import { DocumentListItem, ListItemUsedFor, ListUsedFor } from "../../types/documents";
import { DocumentType, DocumentModule } from "../../types/documents/BaseDocument";
import { documentModuleIcon, documentModuleString, documentTypeIcon } from "../../utils/documents";

export function AddItemDialog(props: {
    itemName: string,
    onAddItem: (title: string, description: string) => void
    onDismiss: () => void,
    fetching?: boolean
}) {
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const onComplete = () => props.onAddItem(title, description);

    return <CardDialog title={`${props.itemName} Toevoegen`} onDismiss={props.onDismiss} visible={true}>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', width: 600 }}>
            <TextInput label={`Titel`} required validator={requiredValidation} sx={{ mb: 2 }} value={title} onChange={setTitle} />
            <TextInput label="Omschrijving" sx={{ mb: 2 }} value={description} onChange={setDescription} />

            <LoadingButton loading={props.fetching} sx={{ mt: 3 }} onClick={onComplete}>Toevoegen</LoadingButton>
        </Box>
    </CardDialog>
}

export const AddInvoiceReferenceDialog: FC<{
    onAdd: (name: string, ref: string) => void
    onDismiss: () => void,
    fetching?: boolean
}> = ({ onAdd, onDismiss, fetching }) => {
    const [name, setName] = useState<string>("");
    const [fieldRef, setFieldRef] = useState<string>("");

    const onComplete = () => onAdd(name, `[${fieldRef}]`);
    const onChangeName = (e: string) => {
        setName(e);
        setFieldRef(onlyAllowUnderscores(e.replaceAll(" ", "_").toUpperCase()))
    };

    return <CardDialog title={`Referentie Toevoegen`} onDismiss={onDismiss} visible={true}>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', width: 600 }}>
            <RowCentered>
                <TextInput autoFocus sx={{ flex: 1 }} label={`Naam`} required validator={requiredValidation} value={name} onChange={onChangeName} />
                <TextInput
                    leftNeighbour={<Box sx={{ alignItems: 'center', display: 'flex', background: '#eee', px: 1 }}>[</Box>}
                    rightNeighbour={<Box sx={{ alignItems: 'center', display: 'flex', background: '#eee', px: 1 }}>]</Box>}
                    required
                    label="Referentie"
                    inputSx={{ mx: .5 }}
                    sx={{ flex: 1, pl: 1 }}
                    value={fieldRef}
                    disabled
                />
            </RowCentered>
            <ContainedButton loading={fetching} sx={{ mt: 2 }} onClick={onComplete}>Toevoegen</ContainedButton>
        </Box>
    </CardDialog>
}

export function AddDocumentListDialog(props: {
    itemName: string,
    onAddItem: (title: string, description: string, target: ListUsedFor) => void
    onDismiss: () => void,
    fetching?: boolean
}) {
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [target, setTarget] = useState<ListUsedFor>(ListUsedFor.Onboarding);

    const onComplete = () => props.onAddItem(title, description, target);

    return <CardDialog title={`${props.itemName} Toevoegen`} onDismiss={props.onDismiss} visible={true}>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', width: 600 }}>
            <TextInput
                icon={AbcTwoTone}
                label={`Titel`}
                required validator={requiredValidation} sx={{ mb: 2 }} value={title} onChange={setTitle} />

            <TextInput
                icon={FormatListBulleted}
                label="Omschrijving"
                sx={{ mb: 2 }}
                value={description} onChange={setDescription} />

            <IconLabel label="Word gebruikt voor" icon={QuestionMarkTwoTone} />
            <SelectMolecule
                selected={[target]}
                items={[{
                    label: "Onboarding",
                    value: ListUsedFor.Onboarding,
                    flex: 1
                }, {
                    label: "Contracten",
                    value: ListUsedFor.Contract,
                    flex: 1
                }]}
                onClick={setTarget}
            />

            <LoadingButton loading={props.fetching} sx={{ mt: 3 }} onClick={onComplete}>Toevoegen</LoadingButton>
        </Box>
    </CardDialog>
}

export function AddStoreItemDialog(props: {
    itemName: string,
    onAddItem: (title: string, description: string, type: StoreItemType) => void
    onDismiss: () => void,
    fetching?: boolean
}) {
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [type, setType] = useState<StoreItemType>(StoreItemType.Form);
    const onComplete = () => props.onAddItem(title, description, type);

    return <CardDialog title={`${props.itemName} Toevoegen`} onDismiss={props.onDismiss} visible={true}>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', width: 600 }}>
            <TextInput label={`Titel`} required validator={requiredValidation} sx={{ mb: 2 }} value={title} onChange={setTitle} />
            <TextInput label="Omschrijving" sx={{ mb: 2 }} value={description} onChange={setDescription} />

            <IconLabel label="Soort" />
            <SelectMolecule
                selected={[type]}
                items={[{
                    label: "Formulier",
                    value: StoreItemType.Form
                }, {
                    label: "PDF",
                    value: StoreItemType.PDF
                }]}
                onClick={setType}
            />

            <LoadingButton loading={props.fetching} sx={{ mt: 3 }} onClick={onComplete}>Toevoegen</LoadingButton>
        </Box>
    </CardDialog>
}

export function AddCategoryDialog(props: {
    itemName: string,
    onAddItem: (title: string, type: CompanyGroupType) => void
    onDismiss: () => void,
    fetching?: boolean
}) {
    const [title, setTitle] = useState<string>("");
    const [type, setType] = useState<CompanyGroupType>(CompanyGroupType.Static);
    const onComplete = () => props.onAddItem(title, type);

    return <CardDialog title={`${props.itemName} Toevoegen`} onDismiss={props.onDismiss} visible={true}>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', width: 600 }}>


            <TextInput icon={AbcTwoTone} label={`Titel`} required validator={requiredValidation} sx={{ mb: 2 }} value={title} onChange={setTitle} />

            <IconLabel label="Soort" icon={FormatListBulleted} />
            <SelectMolecule
                selected={[type]}
                onClick={setType}
                items={[{
                    label: "Statisch",
                    value: CompanyGroupType.Static,
                    flex: 1
                }, {
                    label: "Tags",
                    value: CompanyGroupType.Smart,
                    flex: 1
                }]}
            />

            <LoadingButton loading={props.fetching} sx={{ mt: 3 }} onClick={onComplete}>Toevoegen</LoadingButton>
        </Box>
    </CardDialog>
}

export function AddDocumentDialog(props: {
    onAddDocument: (item: DocumentListItem) => void
    onDismiss: () => void,
    listType: ListUsedFor
}) {
    const [item, setItem] = useState<DocumentListItem>({
        _id: generateGuid(),
        description: "",
        title: "",
        documentReferenceId: "",
        type: DocumentType.FileUpload,
        linkedTo: DocumentModule.Company,
        managedBy: DocumentModule.Company,
        usedFor: ListItemUsedFor.Supplier
    });

    const { onAddDocument } = props;
    const [documents, setDocuments] = useState<{
        _id: string;
        title: string;
        description: string;
    }[]>([]);

    const [fetching, setFetching] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const filterDocuments = (doc: { title: string, description: string }) => {
        if (search === "")
            return true;

        return doc.title.toLowerCase().includes(search.toLowerCase()) || doc.description.toLowerCase().includes(search.toLowerCase());
    }

    useEffect(() => {
        if (item.type === DocumentType.FileUpload)
            return;

        setFetching(true);

        if (item.type === DocumentType.Form)
            formsApi.list().then(setDocuments).finally(() => setFetching(false));

        if (item.type === DocumentType.DocuSign)
            pdfsApi.list().then(setDocuments).finally(() => setFetching(false));

    }, [item.type]);

    const onDocumentClicked = (id: string) => {
        const doc = documents.find(d => d._id === id);
        if (!doc)
            return;

        props.onAddDocument({
            ...item,
            title: doc.title,
            description: doc.description,
            _id: generateGuid(),
            documentReferenceId: doc._id
        });
    };

    return <CardDialog title="Document Toevoegen" onDismiss={props.onDismiss} visible={true}>
        <Box sx={{
            p: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', width: 600
        }}>

            <IconLabel label="Soort document" icon={QuestionMarkTwoTone} />
            <SelectMolecule selected={[item.type]} onClick={(e) => setItem({ ...item, type: e })} items={
                props.listType === ListUsedFor.Onboarding ?
                    [
                        { label: "Bestand Upload", value: DocumentType.FileUpload, flex: 1, icon: documentTypeIcon(DocumentType.FileUpload) },
                        { label: "Web formulier", value: DocumentType.Form, flex: 1, icon: documentTypeIcon(DocumentType.Form) },
                    ] :
                    [
                        { label: "Bestand Upload", value: DocumentType.FileUpload, flex: 1, icon: documentTypeIcon(DocumentType.FileUpload) },
                        { label: "Web formulier", value: DocumentType.Form, flex: 1, icon: documentTypeIcon(DocumentType.Form) },
                        { label: "PDF / DocuSign", value: DocumentType.DocuSign, flex: 1, icon: documentTypeIcon(DocumentType.DocuSign) },
                    ]} />

            {item.type === DocumentType.FileUpload && <RowCentered sx={{ mt: 2 }}>
                <TextInput
                    sx={{ flex: 1, pr: 1 }}
                    icon={AbcTwoTone}
                    label="Titel"
                    required validator={requiredValidation}
                    value={item.title}
                    onChange={(e) => setItem({ ...item, title: e })}
                />

                <TextInput
                    sx={{ flex: 2 }}
                    icon={FormatListBulleted}
                    label="Omschrijving"
                    value={item.description} onChange={(e) => setItem({ ...item, description: e })}
                />
            </RowCentered>}

            {
                item.type !== DocumentType.DocuSign && <>
                    <IconLabel label="Aangeleverd door" icon={UploadTwoTone} sx={{ mt: 2 }} />
                    <SelectMolecule selected={[item.managedBy]} onClick={(managedBy) => setItem({ ...item, managedBy })} items={[
                        { label: documentModuleString(DocumentModule.Company), value: DocumentModule.Company, flex: 1, icon: documentModuleIcon(DocumentModule.Company) },
                        { label: documentModuleString(DocumentModule.Person), value: DocumentModule.Person, flex: 1, icon: documentModuleIcon(DocumentModule.Person) },
                    ]}
                    />
                    <IconLabel label="Gekoppeld aan" icon={VisibilityTwoTone} sx={{ mt: 2 }} />
                    <SelectMolecule selected={[item.linkedTo]} onClick={(linkedTo) => setItem({ ...item, linkedTo })} items={[
                        { label: documentModuleString(DocumentModule.Company), value: DocumentModule.Company, flex: 1, icon: documentModuleIcon(DocumentModule.Company) },
                        { label: documentModuleString(DocumentModule.Person), value: DocumentModule.Person, flex: 1, icon: documentModuleIcon(DocumentModule.Person) },
                    ]} />
                </>
            }

            {
                item.type === DocumentType.DocuSign && <>
                    <IconLabel label="Bedoeld voor" icon={PanToolAltTwoTone} sx={{ mt: 2 }} />
                    <SelectMolecule selected={[item.usedFor]} onClick={(usedFor) => setItem({ ...item, usedFor })} items={
                        [
                            { label: "Inlener", value: ListItemUsedFor.Hirer, flex: 1 },
                            { label: "Leverancier", value: ListItemUsedFor.Supplier, flex: 1 }
                        ]} />

                </>
            }

            {
                item.type !== DocumentType.FileUpload &&
                <Box height={300}>
                    {
                        !fetching && <>
                            <Typography mt={2} mb={.5}>Beschikbare {item.type === DocumentType.Form ? "Formulieren" : "PDF's"}</Typography>
                            <TextInput
                                icon={SearchTwoTone}
                                label="Zoeken" sx={{ mb: .5 }} value={search} onChange={setSearch} />
                            <BaseItemTable
                                onClick={onDocumentClicked}
                                items={documents.filter(filterDocuments)}
                            />
                        </>
                    }
                    {
                        fetching && <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                            <CircularProgress />
                        </Box>
                    }
                </Box>
            }
            {
                item.type === DocumentType.FileUpload && <Button sx={{ mt: 3 }} onClick={() => onAddDocument(item)}>
                    Opslaan
                </Button>
            }
        </Box>
    </CardDialog>
}
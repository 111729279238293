export enum BankAccountType {
    Company = 1,
    G = 2
}

export interface BankAccount {
    _id: string;
    iban: string;
    bic: string;
    type: BankAccountType;
    division?: number;
}

export class EmptyBankAccount implements BankAccount {
    _id: string = '';
    iban: string = '';
    bic: string = '';
    type: BankAccountType = BankAccountType.Company;
}

export enum BankAccountRelationTargetType {
    Company = 1,
    Person = 2
}

export interface BankAccountRelation {
    _id: string;
    bankAccountId: string;
    targetId: string;
    targetType: BankAccountRelationTargetType;
}
export enum DocumentType {
    FileUpload = 2,
    Form = 3,
    DocuSign = 4,
}

export enum DocumentState {
    Requested = 1,
    Pending = 2,
    Rejected = 3,
    Completed = 4,
}

export enum DocumentModule {
    Company = 1,
    Person = 2,
    Contract = 3,
}

export interface DocumentIdentity {
    module: DocumentModule;
    targetId: string;
}

export interface IBaseDocument {
    type: DocumentType;
    _id: string;
    title: string;
    description: string;
}
import { FC, useState } from "react";
import { VacancyRelationState } from "../../types/recruiter";
import { RowCentered } from "./Row";
import { TextInput } from "./inputs";
import { Box, Button, SxProps } from "@mui/material";
import { ArrowDownward, ArrowUpward, DeleteTwoTone } from "@mui/icons-material";
import { errorColor, infoColor } from "../theme";
import { ColorPickerContextMenu } from "./ContextMenu";
import { ContainedButton } from "./Buttons";

export const RelationStateEditor: FC<{
    initialState: VacancyRelationState,
    onChange: (state: VacancyRelationState) => void,
    onMoveUp?: () => void,
    onMoveDown?: () => void,
    onDelete?: () => void,
    onSave?: () => void,
    sx?: SxProps,
}> = ({
    initialState,
    onChange,
    onMoveUp,
    onDelete,
    onMoveDown,
    onSave,
    sx
}) => {
        const [state, setState] = useState(initialState);

        const onChangeColor = (color: string) => setState({ ...state, color });
        const onChangeName = (name: string) => setState({ ...state, name });
        const update = () => {
            if (state.name === initialState.name && state.color === initialState.color) return;

            onChange(state)
        };

        return <RowCentered sx={{ alignItems: 'flex-end !important', ...sx }}>
            <ColorPickerContextMenu color={state.color} onChange={onChangeColor} onClose={update}>
                <Box width={50} height={30} sx={{ background: state.color, borderRadius: 1, mr: 1, cursor: 'pointer' }} />
            </ColorPickerContextMenu>

            <TextInput value={state.name} sx={{ minWidth: 300 }} onChange={onChangeName} onFocusLost={update} />

            {onMoveUp && <Button sx={{ width: 60, height: 30, minWidth: 0, ml: 1, background: infoColor(.1) }} onClick={onMoveUp}>
                <ArrowUpward fontSize="small" />
            </Button>}

            {onMoveDown && <Button sx={{ width: 60, height: 30, minWidth: 0, ml: 1, background: infoColor(.1) }} onClick={onMoveDown}>
                <ArrowDownward fontSize="small" color="secondary" />
            </Button>}

            {onDelete && <Button sx={{ width: 80, height: 30, minWidth: 0, ml: 1, background: errorColor(.1) }} onClick={onDelete} color="error">
                <DeleteTwoTone fontSize="small" />
            </Button>}

            {onSave && <ContainedButton sx={{ width: 130, height: 30, minWidth: 0, ml: 1 }} onClick={onSave}>
                Opslaan
            </ContainedButton>}
        </RowCentered>
    }
import { Select, MenuItem, Typography } from "@mui/material";

export function Dropdown(props: {
    label?: string,
    value: any,
    onChange: (value: any) => void,
    options: {
        value: any,
        label: string
    }[]
}) {
    return <Select variant="outlined" sx={{ height: 30, fontSize: '.8rem', px: 1, py: 0, minWidth: 150, width: '100%' }} label={props.label} value={props.value} onChange={ev => props.onChange(ev.target.value)}>
        {
            props.options.map(option => <MenuItem key={option.value} value={option.value}><Typography variant="caption">{option.label}</Typography></MenuItem>)
        }
    </Select>
}
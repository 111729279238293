import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Typography, Collapse, SxProps } from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { RowCentered } from "./Row";

export const CollapsableTitle: FC<{
    sx?: SxProps,
    title: string, children: ReactNode,
    defaultOpen?: boolean,
    variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline"
}> = ({ title, children, variant, sx, defaultOpen }) => {
    const [open, setOpen] = useState<boolean>(defaultOpen ?? false);
    return <Box sx={sx}>
        <RowCentered onClick={() => setOpen(!open)} sx={{ cursor: 'pointer' }}>
            <Typography variant={variant ?? 'h6'} color={'secondary'}>{title}</Typography>
            {open ? <ExpandLess sx={{ ml: .5 }} color='primary' /> : <ExpandMore color='primary' sx={{ ml: .5 }} />}
        </RowCentered>
        <Collapse in={open}>
            {children}
        </Collapse>
    </Box>
}
export enum RecruiterDocumentType {
    FILE = 1,
    EMAIL = 2
}

export type RecruiterDocument = RecruiterFileDocument | RecruiterEmailDocument;

export interface BaseRecruiterDocument {
    _id: string;
    type: RecruiterDocumentType;
    professionalId: string;
    name: string;
    description: string;
    createdAt: number;
}

export interface RecruiterFileDocument extends BaseRecruiterDocument {
    type: RecruiterDocumentType.FILE;
    filePath: string;
}

export interface recruiterEmailMessage {
    from: string;
    to: string;
    message: string;
    sentAt?: number;
}

export interface RecruiterEmailDocument extends BaseRecruiterDocument {
    type: RecruiterDocumentType.EMAIL;
    subject: string;
    messages: recruiterEmailMessage[];
}
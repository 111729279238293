import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import { SearchTwoTone } from "@mui/icons-material";
import { filterBySearch } from "../../../../filters/professionalFilters";
import { recruiterRoutes } from "../../../../router/paths";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { professionalsSelector } from "../../../../store/slices/recruiter/professionalsSlice";
import { Professional } from "../../../../types/recruiter/professional";
import { VacancyRelation } from "../../../../types/recruiter/relations";
import { TextInput } from "../../../molecules";
import { ApplyProfessionalTable } from "../../../molecules/tables/recruiter/ApplyProfessional";
import { MatchScore } from "../../../../types/recruiter/matchScore";
import api from "../../../../api/recruiter/relations";
import { createVacancyRelation, deleteVacancyRelation, relationsSelector } from "../../../../store/slices/recruiter/relationsSlice";
import { Checkbox } from "../../../molecules/inputs/CheckboxInput";
import { getScoreMappings } from "../../../../utils/scoreMappings";
import { useMissingScoresHook } from "../../../hooks/fetchMissingScores";

export function ApplyProfessionalOrganism(props: {
    vacancyId: string;
}) {
    const nav = useNavigate();
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState<string>("");
    const [scores, setScores] = useState<MatchScore[]>([]);
    const [excludeNotAvailable, setExcludeNotAvailable] = useState<boolean>(true);
    const professionals = useAppSelector(professionalsSelector).professionals;
    const relationsState = useAppSelector(relationsSelector);


    const fetchProfessionalScores = useCallback(async () => {

        const mappings = getScoreMappings([props.vacancyId], professionals.map(x => x._id));

        if (mappings.length === 0) return;

        try {
            const result = await api.getScores(mappings);
            setScores(result);
        } catch (error) {
            console.error("Error fetching professional scores:", error);
        }
    }, [professionals, props.vacancyId]);

    const fetchMissingScores = useMissingScoresHook(scores, setScores);

    useEffect(() => {
        fetchProfessionalScores()
    }, [fetchProfessionalScores]);

    useEffect(() => {
        fetchMissingScores()
    }, [fetchMissingScores]);

    const addRelation = (professional: Professional) => {
        dispatch(createVacancyRelation({
            professionalId: professional._id,
            targetId: professional._id,
            vacancyId: props.vacancyId
        }));
    };

    const removeRelation = (relation: VacancyRelation) => {
        dispatch(deleteVacancyRelation({ relation, targetId: relation.professionalId }));
    };

    return <Box>
        <TextInput icon={SearchTwoTone} label="Zoeken" sx={{ my: 2 }} value={search} onChange={v => setSearch(v as string)} />
        <Checkbox
            checked={excludeNotAvailable}
            onChange={() => setExcludeNotAvailable(!excludeNotAvailable)}
            title="Alleen beschikbare kandidaten"
        />
        <ApplyProfessionalTable
            scores={scores}
            processing={relationsState.fetchingProfessionalRelations}
            professionals={filterBySearch(professionals, search, excludeNotAvailable)}
            relations={relationsState.relations.filter(x => x.vacancyId === props.vacancyId)}
            onApply={addRelation}
            onRemove={removeRelation}
            onProfessionalClick={(professional) => nav(recruiterRoutes.kandidaten.details(professional._id))}
        />
    </Box>
}
import { BaseContract, CompanyFinancial, Contracts, MergeField, TAOFinancial } from "../types/contracts";
import { tryGetIndex } from "./array";
import { contractFaseString, periodsString, salaryDateString } from "./contractEnumStrings";
import { parseAddress } from "./strings";

export const contractMergeFields = [
    '[CONTRACT_NUMMER]',
    '[CONTRACT_MANAGER]',
    '[RECRUITER]'
];

export const endCustomerMergeFields = [
    '[EINDKLANT_NAAM]',
    '[EINDKLANT_KVK]',
    '[EINDKLANT_VESTIGING_NUMMER]',
    '[EINDKLANT_ADRES_STRAAT]',
    '[EINDKLANT_ADRES_HUISNUMMER]',
    '[EINDKLANT_ADRES_POSTCODE]',
    '[EINDKLANT_ADRES_PLAATS]'
];

export const hirerMergeFields = [
    '[INLENER_NAAM]',
    '[INLENER_KVK]',
    '[INLENER_VESTIGING_NUMMER]',
    '[INLENER_ADRES_STRAAT]',
    '[INLENER_ADRES_HUISNUMMER]',
    '[INLENER_ADRES_POSTCODE]',
    '[INLENER_ADRES_PLAATS]',
    '[INLENER_TEKENBEVOEGDE_NAAM]',
    '[INLENER_TEKENBEVOEGDE_EMAIL]',
    '[INLENER_URENVERANTWOORDELIJKE_NAAM]',
    '[INLENER_URENVERANTWOORDELIJKE_EMAIL]'
];

export const supplierMergeFields = [
    '[LEVERANCIER_NAAM]',
    '[LEVERANCIER_KVK]',
    '[LEVERANCIER_VESTIGING_NUMMER]',
    '[LEVERANCIER_ADRES_STRAAT]',
    '[LEVERANCIER_ADRES_HUISNUMMER]',
    '[LEVERANCIER_ADRES_POSTCODE]',
    '[LEVERANCIER_ADRES_PLAATS]',
    '[LEVERANCIER_TEKENBEVOEGDE_NAAM]',
    '[LEVERANCIER_TEKENBEVOEGDE_EMAIL]'
];

export const professionalMergeFields = [
    '[PROFESSIONAL_NAAM]',
    '[PROFESSIONAL_EMAIL]',
    '[PROFESSIONAL_TELEFOON]',
    '[PROFESSIONAL_BSN]',
    '[PROFESSIONAL_FUNCTIE]',
    '[PROFESSIONAL_PO_NUMMER]'
];

export const taoFinancialMergeFields = [
    '[TAO_CAO]',
    '[TAO_BETAALDAG]',
    '[TAO_BRUTO_MAAND_SALARIS]',
    '[TAO_INKOOP_TARIEF]',
    '[TAO_ALL_IN_UUR_TARIEF]',
    '[TAO_PENSIOEN]',
    '[TAO_13E_MAAND_PERCENTAGE]',
    '[TAO_HEEFT_VAKANTIEGELD]',
    '[TAO_CONTRACT_FASE]',
    '[TAO_AUTO_VAN_DE_ZAAK]',
    '[TAO_NETTO_ONKOSTEN_VERGOEDING]',
    '[TAO_VAKANTIE_DAGEN]',
    '[TAO_ADV_ATV_DAGEN]',
    '[TAO_PREMIES_WERKGEVER]',
    '[TAO_PREMIES_WERKNEMER]',
    '[TAO_TOEPASSEN_LOONHEFFING]',
    '[TAO_BIJDRAGE_OVERHEAD]',
    '[TAO_BIJDRAGE_ZW]',
    '[TAO_CAO_SCHAAL]',
    '[TAO_CAO_SCHAAL_TREDE]'
]

export const professionalFinancialMergeFields = [
    '[LEVERANCIER_PROF_TARIEF_PRIJS]',
    '[LEVERANCIER_PROF_TARIEF_PERIODICITEIT]',
    '[LEVERANCIER_PROF_BETAAL_TERMIJN]',
    '[LEVERANCIER_PROF_MAX_AANSPRAKELIJKHEID]',
    '[LEVERANCIER_PROF_MAX_DEKKING_VERZ]'
]

export const finesMergeFields = [
    '[BOETE_1_DIRECT_SOM]',
    '[BOETE_1_PER_DAG_SOM]',
    '[BOETE_2_DIRECT_SOM]',
    '[BOETE_2_PER_DAG_SOM]',
    '[BOETE_3_DIRECT_SOM]',
    '[BOETE_3_PER_DAG_SOM]'
]

export const declarationMergeFields = [
    '[DECLARATIES_EIND_DATUM]',
]

export const allMergeFields = [
    ...contractMergeFields,
    ...hirerMergeFields,
    ...supplierMergeFields,
    ...professionalMergeFields,
    ...taoFinancialMergeFields,
    ...professionalFinancialMergeFields,
    ...finesMergeFields,
    ...declarationMergeFields,
    ...endCustomerMergeFields
]

export const generateContractMergeFields = (contract: BaseContract): MergeField[] => {

    const mergeFields: MergeField[] = [
        { field: '[CONTRACT_NUMMER]', value: contract.contractnumber },
        { field: '[CONTRACT_MANAGER]', value: contract.contractManager },
        { field: '[RECRUITER]', value: contract.recruiter },

        { field: '[EINDKLANT_NAAM]', value: contract.endCustomer.traderName },
        { field: '[EINDKLANT_KVK]', value: contract.endCustomer.kvk },
        { field: '[EINDKLANT_VESTIGING_NUMMER]', value: contract.endCustomer.locationNumber },
        { field: '[EINDKLANT_ADRES_STRAAT]', value: parseAddress(contract.endCustomer.address).street },
        { field: '[EINDKLANT_ADRES_HUISNUMMER]', value: parseAddress(contract.endCustomer.address).houseNumber },
        { field: '[EINDKLANT_ADRES_POSTCODE]', value: parseAddress(contract.endCustomer.address).zipcode },
        { field: '[EINDKLANT_ADRES_PLAATS]', value: parseAddress(contract.endCustomer.address).city },

        { field: '[INLENER_NAAM]', value: contract.hirer.traderName },
        { field: '[INLENER_KVK]', value: contract.hirer.kvk },
        { field: '[INLENER_VESTIGING_NUMMER]', value: contract.hirer.locationNumber },
        { field: '[INLENER_ADRES_STRAAT]', value: parseAddress(contract.hirer.address).street },
        { field: '[INLENER_ADRES_HUISNUMMER]', value: parseAddress(contract.hirer.address).houseNumber },
        { field: '[INLENER_ADRES_POSTCODE]', value: parseAddress(contract.hirer.address).zipcode },
        { field: '[INLENER_ADRES_PLAATS]', value: parseAddress(contract.hirer.address).city },
        { field: '[INLENER_TEKENBEVOEGDE_NAAM]', value: contract.authorizedSignatoriesHirer[0].name },
        { field: '[INLENER_TEKENBEVOEGDE_EMAIL]', value: contract.authorizedSignatoriesHirer[0].email },
        { field: '[INLENER_URENVERANTWOORDELIJKE_NAAM]', value: contract.timesheetApprovers.find(_ => true)?.name },
        { field: '[INLENER_URENVERANTWOORDELIJKE_EMAIL]', value: contract.timesheetApprovers.find(_ => true)?.email },

        { field: '[LEVERANCIER_NAAM]', value: contract.supplier.traderName },
        { field: '[LEVERANCIER_KVK]', value: contract.supplier.kvk },
        { field: '[LEVERANCIER_VESTIGING_NUMMER]', value: contract.supplier.locationNumber },
        { field: '[LEVERANCIER_ADRES_STRAAT]', value: parseAddress(contract.supplier.address).street },
        { field: '[LEVERANCIER_ADRES_HUISNUMMER]', value: parseAddress(contract.supplier.address).houseNumber },
        { field: '[LEVERANCIER_ADRES_POSTCODE]', value: parseAddress(contract.supplier.address).zipcode },
        { field: '[LEVERANCIER_ADRES_PLAATS]', value: parseAddress(contract.supplier.address).city },
        { field: '[LEVERANCIER_TEKENBEVOEGDE_NAAM]', value: contract.authorizedSignatoriesSupplier[0].name },
        { field: '[LEVERANCIER_TEKENBEVOEGDE_EMAIL]', value: contract.authorizedSignatoriesSupplier[0].email },

        { field: '[PROFESSIONAL_NAAM]', value: contract.professional.name },
        { field: '[PROFESSIONAL_EMAIL]', value: contract.professional.email },
        { field: '[PROFESSIONAL_TELEFOON]', value: contract.professional.telephone },
        { field: '[PROFESSIONAL_BSN]', value: contract.professional.bsn },
        { field: '[PROFESSIONAL_FUNCTIE]', value: contract.professional.function },
        { field: '[PROFESSIONAL_PO_NUMMER]', value: contract.professional.poNumber },

        { field: '[BOETE_1_DIRECT_SOM]', value: tryGetIndex(contract.financial.fines, 0)?.sum.toString() },
        { field: '[BOETE_1_PER_DAG_SOM]', value: tryGetIndex(contract.financial.fines, 1)?.sum.toString() },
        { field: '[BOETE_2_DIRECT_SOM]', value: tryGetIndex(contract.financial.fines, 2)?.sum.toString() },
        { field: '[BOETE_2_PER_DAG_SOM]', value: tryGetIndex(contract.financial.fines, 3)?.sum.toString() },
        { field: '[BOETE_3_DIRECT_SOM]', value: tryGetIndex(contract.financial.fines, 4)?.sum.toString() },
        { field: '[BOETE_3_PER_DAG_SOM]', value: tryGetIndex(contract.financial.fines, 5)?.sum.toString() },
        { field: '[DECLARATIES_EIND_DATUM]', value: new Date(contract.financial.endDateDeclarations).toLocaleDateString() },

        ...contract.invoiceDetails.customerInvoiceDetails.references.map(r => ({ field: r.ref, value: r.value }))
    ];

    if (contract.contractKind === Contracts.Tao)
        mergeFields.push(...generateTaoFinancialMergeFields(contract.financial as TAOFinancial));
    else
        mergeFields.push(...generateSupplierProfessionalMergeFields(contract.financial as CompanyFinancial));

    return mergeFields;
}

const generateSupplierProfessionalMergeFields = (financial: CompanyFinancial): MergeField[] => {
    return [
        { field: '[LEVERANCIER_PROF_TARIEF_PRIJS]', value: financial.rateProfessional.sum.toString() },
        { field: '[LEVERANCIER_PROF_TARIEF_PERIODICITEIT]', value: periodsString(financial.rateProfessional.period) },
        { field: '[LEVERANCIER_PROF_BETAAL_TERMIJN]', value: financial.paymentTermSupplier.toString() + "dag(en)" },
        { field: '[LEVERANCIER_PROF_MAX_AANSPRAKELIJKHEID]', value: financial.maximumSum.toString() },
        { field: '[LEVERANCIER_PROF_MAX_DEKKING_VERZ]', value: financial.maximumCoverage.toString() }
    ];
}

const generateTaoFinancialMergeFields = (financial: TAOFinancial): MergeField[] => {
    return [
        { field: '[TAO_CAO]', value: financial.cao },
        { field: '[TAO_BETAALDAG]', value: salaryDateString(financial.salaryDate) },
        { field: '[TAO_BRUTO_MAAND_SALARIS]', value: financial.brutoMonthlySalary.toString() },
        { field: '[TAO_INKOOP_TARIEF]', value: financial.hourlyRate.toString() },
        { field: '[TAO_ALL_IN_UUR_TARIEF]', value: financial.allInHourlyRate.toString() },
        { field: '[TAO_PENSIOEN]', value: financial.pension.toString() },
        { field: '[TAO_13E_MAAND_PERCENTAGE]', value: financial.percentage13thMonth.toString() },
        { field: '[TAO_HEEFT_VAKANTIEGELD]', value: financial.hasHolidayPay ? "Ja" : "Nee" },
        { field: '[TAO_CONTRACT_FASE]', value: contractFaseString(financial.contractFase) },
        { field: '[TAO_AUTO_VAN_DE_ZAAK]', value: financial.companyVehicle ? "Ja" : "Nee" },
        { field: '[TAO_NETTO_ONKOSTEN_VERGOEDING]', value: financial.netExpenseReimbursment.toString() },
        { field: '[TAO_VAKANTIE_DAGEN]', value: financial.vacationDays.toString() },
        { field: '[TAO_ADV_ATV_DAGEN]', value: financial.advDays.toString() },
        { field: '[TAO_PREMIES_WERKGEVER]', value: financial.premiumsEmployer.toString() },
        { field: '[TAO_PREMIES_WERKNEMER]', value: financial.premiumsEmployee.toString() },
        { field: '[TAO_TOEPASSEN_LOONHEFFING]', value: financial.applyPayrollTax ? "wel" : "niet" },
        { field: '[TAO_BIJDRAGE_OVERHEAD]', value: financial.contributionOverhead.toString() },
        { field: '[TAO_BIJDRAGE_ZW]', value: financial.contributionZW.toString() },
        { field: '[TAO_CAO_SCHAAL]', value: financial.caoScale.toString() },
        { field: '[TAO_CAO_SCHAAL_TREDE]', value: financial.caoScaleStep.toString() }
    ];
};
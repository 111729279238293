import { recruiterRoutes } from "../paths";
import { PlatformUser } from "../../types/user";
import { ProtectedRoutes } from "../ProtectedRoutes";
import { lazy } from "react";

export const ProfessionalsListPage = lazy(() => import("../../ui/pages/recruiter/professionals/List"));
export const ProfessionalCreatePage = lazy(() => import("../../ui/pages/recruiter/professionals/Create"));
export const ProfessionalDetailsPage = lazy(() => import("../../ui/pages/recruiter/professionals/Details"));
export const VacanciesListPage = lazy(() => import("../../ui/pages/recruiter/vacatures/List"));
export const VacancyCreatePage = lazy(() => import("../../ui/pages/recruiter/vacatures/Create"));
export const VacancyDetailsPage = lazy(() => import("../../ui/pages/recruiter/vacatures/Details"));
export const VacancyViewerPage = lazy(() => import("../../ui/pages/recruiter/public/Viewer"));
export const AppliancesPage = lazy(() => import("../../ui/pages/recruiter/progress/AppliancesOverview"));
export const RecruiterSettingsPage = lazy(() => import("../../ui/pages/recruiter/Settings"));
export const FollowUpTemplateEditor = lazy(() => import("../../ui/pages/recruiter/FollowUpTemplateEditor"));

export function RecruiterRoutes(user: PlatformUser) {
    const pathsAndComponentsMap = {
        [recruiterRoutes.vacatures.list]: VacanciesListPage,
        [recruiterRoutes.vacatures.create]: VacancyCreatePage,
        [recruiterRoutes.vacatures.details(":id")]: VacancyDetailsPage,
        [recruiterRoutes.kandidaten.list]: ProfessionalsListPage,
        [recruiterRoutes.kandidaten.create]: ProfessionalCreatePage,
        [recruiterRoutes.kandidaten.details(":id")]: ProfessionalDetailsPage,
        [recruiterRoutes.inschrijven(":code")]: VacancyViewerPage,
        [recruiterRoutes.progress.appliances]: AppliancesPage,
        [recruiterRoutes.settings]: RecruiterSettingsPage,
        [recruiterRoutes.followUp(":id")]: FollowUpTemplateEditor,
    }

    return <>
        {
            ProtectedRoutes({
                user,
                pathsAndComponentsMap
            })
        }
    </>
}



import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../..';
import { FetchingState } from '../../../types/fetchingState';
import { VacancyRelation, VacancyRelationState } from '../../../types/recruiter';
import { showToast } from '../toastSlice';

import relationsApi from '../../../api/recruiter/relations';

export interface RelationsState {
    relations: VacancyRelation[];
    fetchingRelations: FetchingState;
    fetchingProfessionalRelations: string[];
}

const initialState: RelationsState = {
    relations: [],
    fetchingRelations: 'init',
    fetchingProfessionalRelations: [],
};

export const listVacanciesRelations = createAsyncThunk('relations/listRelations', async (_, thunkApi) => {
    try {
        const ids = (thunkApi.getState() as RootState).recruiter.vacancies.vacancies.map((v) => v._id);
        if (ids.length === 0) return [];

        const relations = await relationsApi.getByVacancies(ids);
        return relations;
    } catch (e: any) {
        thunkApi.dispatch(showToast({ message: e.message, type: 'error' }));
        throw e;
    }
});

export const createVacancyRelation = createAsyncThunk(
    'relations/createRelation',
    async (args: { vacancyId: string; professionalId: string; targetId: string }, thunkApi) => {
        try {
            var states = (thunkApi.getState() as RootState).recruiter.relationStates.relationStates;
            var state = [...states].sort((a, b) => a.index - b.index)[0];

            thunkApi.dispatch(addRelationTarget(args.targetId));

            const relation = await relationsApi.create({
                _id: '',
                comment: '',
                vacancyId: args.vacancyId,
                professionalId: args.professionalId,
                status: state,
            });

            return relation;
        } catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: 'error' }));
            throw e;
        } finally {
            thunkApi.dispatch(removeRelationTarget(args.targetId));
        }
    }
);

export const updateRelationStatus = createAsyncThunk(
    'relations/updateRelationStatus',
    async (args: { id: string; status: VacancyRelationState }, thunkApi) => {
        relationsApi.updateStatus(args.id, args.status).catch((e) => thunkApi.dispatch(showToast({ message: e.message, type: 'error' })));
        return args;
    }
);

export const updateRelationComment = createAsyncThunk(
    'relations/updateRelationComment',
    async (args: { id: string; comment: string }, thunkApi) => {
        relationsApi.updateComment(args.id, args.comment).catch((e) => thunkApi.dispatch(showToast({ message: e.message, type: 'error' })));
        return args;
    }
);

export const deleteVacancyRelation = createAsyncThunk(
    'relations/deleteRelation',
    async (args: { relation: VacancyRelation; targetId: string }, thunkApi) => {
        try {
            thunkApi.dispatch(addRelationTarget(args.targetId));
            await relationsApi.delete(args.relation._id).catch((e) => thunkApi.dispatch(showToast({ message: e.message, type: 'error' })));

            return args;
        } finally {
            thunkApi.dispatch(removeRelationTarget(args.targetId));
        }
    }
);

export const relationsSlice = createSlice({
    name: 'relations',
    initialState,
    reducers: {
        addRelationTarget: (state, action: PayloadAction<string>) => {
            state.fetchingProfessionalRelations.push(action.payload);
        },
        removeRelationTarget: (state, action: PayloadAction<string>) => {
            state.fetchingProfessionalRelations = state.fetchingProfessionalRelations.filter((x) => x !== action.payload);
        },
    },
    extraReducers: (builder) => {
        // List Relations
        builder
            .addCase(listVacanciesRelations.pending, (state) => {
                state.fetchingRelations = 'fetching';
            })
            .addCase(listVacanciesRelations.rejected, (state) => {
                state.fetchingRelations = 'error';
            })
            .addCase(listVacanciesRelations.fulfilled, (state, action: PayloadAction<VacancyRelation[]>) => {
                state.fetchingRelations = 'completed';
                state.relations = action.payload;
            });

        // Create Relation
        builder
            .addCase(createVacancyRelation.pending, (state) => {
                state.fetchingRelations = 'fetching';
            })
            .addCase(createVacancyRelation.rejected, (state) => {
                state.fetchingRelations = 'error';
            })
            .addCase(createVacancyRelation.fulfilled, (state, action: PayloadAction<VacancyRelation>) => {
                state.fetchingRelations = 'completed';
                state.relations = [action.payload, ...state.relations];
            });

        // Update Relation Status
        builder
            .addCase(updateRelationStatus.pending, (state) => {
                state.fetchingRelations = 'fetching';
            })
            .addCase(updateRelationStatus.rejected, (state) => {
                state.fetchingRelations = 'error';
            })
            .addCase(updateRelationStatus.fulfilled, (state, action: PayloadAction<{ id: string; status: VacancyRelationState }>) => {
                state.fetchingRelations = 'completed';
                state.relations = state.relations.map((r) => {
                    if (r._id === action.payload.id) return { ...r, status: action.payload.status };
                    return r;
                });
            });

        // Update Relation Comment
        builder.addCase(updateRelationComment.fulfilled, (state, action) => {
            state.fetchingRelations = 'completed';
            state.relations = state.relations.map((r) => {
                if (r._id === action.payload.id) return { ...r, comment: action.payload.comment };
                return r;
            });
        });

        // Delete Relation
        builder
            .addCase(deleteVacancyRelation.pending, (state) => {
                state.fetchingRelations = 'fetching';
            })
            .addCase(deleteVacancyRelation.rejected, (state) => {
                state.fetchingRelations = 'error';
            })
            .addCase(deleteVacancyRelation.fulfilled, (state, action) => {
                state.fetchingRelations = 'completed';
                state.relations = state.relations.filter((r) => r._id !== action.payload.relation._id);
            });
    },
});

export const { addRelationTarget, removeRelationTarget } = relationsSlice.actions;
export const relationsSelector = (state: RootState) => state.recruiter.relations;
export default relationsSlice.reducer;
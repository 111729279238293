
import { ColumnProps, Table } from "../Table";
import { RelationButton } from "../../Buttons";
import { Tag } from "../../../../types/company/Tag";

type RowData = {
    tag: Tag;
    isMember: boolean;
}

export function CategoryTagsTable({
    tags: companies, members, processing, onApply, onRemove
}: {
    tags: Tag[];
    members: string[];
    processing: string[];

    onApply: (id: string) => void;
    onRemove: (id: string) => void;
}) {
    const data: RowData[] = companies.map(tag => ({
        tag,
        isMember: members.some(member => member === tag._id)
    }))

    const columns: ColumnProps<RowData>[] = [
        {
            valueGetter: (params) => params.data.isMember ? 1 : 0,
            headerName: "Koppelen",
            sort: 'desc',
            width: 200,
            cellRenderer: (row: { data: RowData }) => <RelationButton
                loading={processing.some(x => x === row.data.tag._id)}
                onAdd={() => onApply(row.data.tag._id)}
                onRemove={() => onRemove(row.data.tag._id)}
                relation={row.data.isMember}
            />
        },
        {
            headerName: "Naam",
            valueGetter: (params) => params.data.tag.name
        }
    ]

    return <Table id="CategoryTagsTable" data={data} columns={columns} />
}
import { useState, useEffect, FC } from "react";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { showToast } from "../../../store/slices/toastSlice";
import { RequestedDocument, DocumentState } from "../../../types/documents";
import { previewDocumentInBrowser } from "../../../utils/downloadFile";
import { RequestedDocumentsTable } from "../../molecules/tables/documents";

import documentApi from "../../../api/documents/requested";
import { contractsSelector } from "../../../store/slices/contractsSlice";

export const ContractDocumentsOrganism: FC = () => {
    const { id } = useParams();
    const [items, setItems] = useState<RequestedDocument[]>([]);
    const [fetching, setFetching] = useState<boolean>(false);
    const [itemsInProgress, setItemsInProgess] = useState<string[]>([]);
    const contract = useAppSelector(contractsSelector).contracts.find(c => c.contractnumber === id);
    const dispatch = useAppDispatch();

    const onApprove = async (item: RequestedDocument) => {
        setItemsInProgess([...itemsInProgress, item._id]);
        await documentApi.approve(item._id)
            .then(() => {
                setItems(items.map(i => i._id === item._id ? { ...item, state: DocumentState.Completed } : i));
                setItemsInProgess(itemsInProgress.filter(i => i !== item._id));
            })
            .catch((e) => {
                dispatch(showToast({ message: e.message, type: 'error' }));
                setItemsInProgess(itemsInProgress.filter(i => i !== item._id));
            });
    }

    const onDeny = async (item: RequestedDocument) => {
        setItemsInProgess([...itemsInProgress, item._id]);
        await documentApi.reject(item._id)
            .then(() => {
                setItems(items.map(i => i._id === item._id ? { ...item, state: DocumentState.Rejected } : i));
                setItemsInProgess(itemsInProgress.filter(i => i !== item._id));
            })
            .catch((e) => {
                dispatch(showToast({
                    message: e.message,
                    type: 'error'
                }))
                setItemsInProgess(itemsInProgress.filter(i => i !== item._id));
            });
    }

    useEffect(() => {
        if (!contract)
            return;

        setFetching(true);

        documentApi.listByLinkedTo(contract._id)
            .then(setItems)
            .catch(() => setItems([]))
            .finally(() => setFetching(false));

    }, [contract]);

    if (fetching)
        return <CircularProgress />

    const onDownload = async (item: RequestedDocument) => {
        var blob = await documentApi.download(item._id);
        previewDocumentInBrowser(blob, item.title);
    };

    return <RequestedDocumentsTable itemsInProgress={itemsInProgress} onApprove={onApprove} onDeny={onDeny} items={items} onDownload={onDownload} />
}